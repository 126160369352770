<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1437.23 201.47">
        <g>
            <g>
                <path
                    class="fill-secondary dark:fill-white"
                    d="m344.7,75.01v71.87h-29.79v-65.42c0-15-5.63-23.33-21.46-23.33-16.88,0-25.83,9.79-25.83,28.54v60.21h-29.79V32.3h29.79v15.63c8.33-11.66,19.79-18.13,35.63-18.13,25.83,0,41.46,17.08,41.46,45.21" />
                <path
                    class="fill-secondary dark:fill-white"
                    d="m484.07,89.59c0,34.58-26.25,59.79-59.79,59.79s-59.59-25.42-59.59-59.79,26.67-59.79,59.59-59.79,59.79,25.21,59.79,59.79m-89.37,0c0,18.96,12.92,31.46,29.58,31.46s29.58-12.5,29.58-31.46-12.92-31.46-29.58-31.46-29.58,12.71-29.58,31.46" />
                <polygon
                    class="fill-secondary dark:fill-white"
                    points="604.71 32.3 565.33 146.88 529.7 146.88 490.33 32.3 522 32.3 547.42 114.59 573.04 32.3 604.71 32.3" />
                <path
                    class="fill-secondary dark:fill-white"
                    d="m730.54,89.59c0,34.58-26.25,59.79-59.79,59.79s-59.59-25.42-59.59-59.79,26.67-59.79,59.59-59.79,59.79,25.21,59.79,59.79m-89.37,0c0,18.96,12.92,31.46,29.58,31.46s29.58-12.5,29.58-31.46-12.92-31.46-29.58-31.46-29.58,12.71-29.58,31.46" />
                <path
                    class="fill-secondary dark:fill-white"
                    d="m866.79,32.3v113.13c0,33.96-22.08,56.04-56.67,56.04-27.71,0-53.75-13.96-58.12-43.96h30.83c3.33,12.92,14.17,17.5,26.46,17.5,18.12,0,27.71-9.38,27.71-28.75v-16.46c-8.13,10.83-20,17.92-36.04,17.92-30,0-52.29-24.58-52.29-58.96s22.71-58.96,52.29-58.96c15.83,0,28.13,7.29,36.04,18.33v-15.84h29.79Zm-29.79,56.46c0-17.92-12.71-30.62-29.38-30.62s-28.96,12.71-28.96,30.62,12.5,30.62,28.96,30.62,29.38-12.5,29.38-30.62" />
                <path
                    class="fill-secondary dark:fill-white"
                    d="m1005.97,32.3l-.2,114.58h-29.8v-15.41c-7.91,10.83-19.79,17.91-35.83,17.91-30,0-52.29-24.58-52.29-59.79s22.71-59.79,52.09-59.79c16.25,0,28.33,7.5,36.25,18.54v-16.04h29.79Zm-29.79,57.29c0-18.75-12.71-31.46-29.38-31.46s-28.96,12.71-28.96,31.46,12.5,31.46,28.96,31.46,29.38-12.5,29.38-31.46" />
                <path
                    class="fill-secondary dark:fill-white"
                    d="m1206.39,74.38v72.5h-29.79v-66.04c0-14.58-5.42-22.71-19.79-22.71-15.63,0-23.75,10.42-23.75,28.54v60.21h-29.79v-66.04c0-14.58-5.42-22.71-19.79-22.71-15.63,0-23.96,10.42-23.96,28.54l.21,60.21h-30V32.3h30v15.21c8.13-11.46,18.96-17.71,33.96-17.71,16.46,0,28.54,7.71,34.79,21.67,8.33-13.54,21.46-21.67,38.33-21.67,24.37,0,39.58,16.88,39.58,44.59" />
                <path
                    class="fill-secondary dark:fill-white"
                    d="m1344.73,97.3h-87.92c1.87,18.33,14.38,27.5,29.79,27.5,11.46,0,21.46-5.21,25.41-14.38h30.83c-8.54,27.08-31.46,38.96-56.04,38.96-35.83,0-60.42-25.42-60.42-59.58s24.79-60,59.79-60c37.91,0,61.46,29.79,58.54,67.5m-86.46-21.88h54.79c-3.33-12.5-12.71-21.04-27.08-21.04s-23.96,8.75-27.71,21.04" />
                <path
                    class="fill-secondary dark:fill-white"
                    d="m1437.23,30.21v30.62c-2.29-.2-3.96-.42-7.3-.42-18.96,0-35.21,8.34-35.21,35.42v51.04h-29.79V32.3h29.79v20c7.08-15,19.58-22.5,35.83-22.5,2.7,0,4.79.21,6.67.42" />
                <g>
                    <path
                        class="fill-pink dark:fill-white"
                        d="m47.38,102.12l48.34,27.62L13.54,175.51c-6.07,3.38-13.54-1.01-13.54-7.96V59.29c0-6.06,3.28-11.65,8.58-14.6l36.84-20.51c5.11-2.85,11.33-2.81,16.41.09l39.65,22.65-53.95,30.04c-9.81,5.46-9.89,19.56-.13,25.14Z" />
                    <path
                        class="fill-secondary dark:fill-white/50"
                        d="m149.69,99.7l-53.96,30.04-48.34-27.62c-9.76-5.58-9.68-19.68.13-25.14l53.95-30.04,48.35,27.63c9.76,5.57,9.68,19.67-.13,25.13Z" />
                    <path
                        class="fill-primary dark:fill-white"
                        d="m197.2,9.13v108.27c0,6.06-3.28,11.64-8.58,14.58l-36.85,20.51c-5.1,2.85-11.32,2.81-16.39-.08l-39.66-22.67,53.96-30.04c9.81-5.46,9.89-19.56.13-25.13l-48.35-27.63L183.66,1.16c6.07-3.38,13.54,1.01,13.54,7.96Z" />
                </g>
            </g>
        </g>
    </svg>
</template>
