<script setup>
import Button from '@/Components/UI/Button.vue'
import patternWhite from '~/app/patterns/white.png'
import Pagination from '@/Components/UI/Pagination.vue'
import ProfileInfo from '@/Components/User/ProfileInfo.vue'
import ArticleGrid from '@/Components/Article/ArticleGrid.vue'
import PaginationSimple from '@/Components/UI/PaginationSimple.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import { router } from '@inertiajs/vue3'
import { nextTick, onMounted, ref } from 'vue'
import { useRoute } from '@/Composables/useRoute.ts'
import { useMakeRequest } from '@/Composables/useMakeRequest.js'
import { useImageOptimise } from '@/Composables/useImageOptimise.js'

const props = defineProps({
    user: Object,
    badges: {
        type: Array,
        default: () => []
    },
    isFollowing: Boolean,
    isPrivate: Boolean,
    articleLimit: Number,
    followersCount: {
        type: Number,
        default: 0
    },
    articleCount: {
        type: Number,
        default: 0
    },
    hasArticles: Boolean,
    latestArticles: Object,
    latestArticlesPagination: Object,
    taggedArticles: Object
})

const latestArticlesContainer = ref(null)
</script>

<template>
    <div class="mb-12 w-full md:mt-4">
        <div class="relative overflow-hidden hero-scrim border-primary/10 md:mx-4 md:rounded-xl md:border">
            <div
                id="userCover"
                class="relative flex w-full bg-center aspect-[2/1] min-h-80 bg-secondary md:aspect-[4/1]">
                <img
                    v-if="user.cover"
                    v-lazy="useImageOptimise(user.cover?.full)"
                    :alt="`Cover image for ${user.name}`"
                    :src="user.cover?.small"
                    class="h-full w-full object-cover object-center">
                <div
                    v-else
                    v-lazy:background="patternWhite"
                    class="absolute top-0 left-0 h-full w-full opacity-5 bg-[length:200px]" />

                <div class="bottom-0 left-0 hidden w-full py-8 md:absolute md:flex md:py-12">
                    <div class="container relative z-20 mx-auto text-white">
                        <ProfileInfo
                            :is-private="isPrivate"
                            :initial-followers-count="followersCount"
                            :initial-article-count="articleCount"
                            :is-following="isFollowing"
                            :user="user" />
                    </div>
                </div>
            </div>
        </div>

        <div class="-mt-12 flex w-full xs:mt-8 md:hidden md:py-12">
            <div class="container relative z-20 mx-auto text-slate-950">
                <ProfileInfo
                    :is-private="isPrivate"
                    :initial-followers-count="followersCount"
                    :initial-article-count="articleCount"
                    :is-following="isFollowing"
                    :user="user" />
            </div>
        </div>

        <div ref="latestArticlesContainer">
            <div
                v-if="!hasArticles"
                class="pt-24 pb-12">
                <div class="container mx-auto text-center">
                    <SectionDescription>
                        {{ user.name }} has not published any articles yet.
                    </SectionDescription>
                </div>
            </div>
            <ArticleGrid
                v-if="hasArticles"
                :skeleton-count="articleLimit"
                :articles="latestArticles.data"
                class="mt-12"
                title="Latest gaming articles" />
            <SectionDescription
                v-if="latestArticlesPagination.hasPages && latestArticles.data.length < 1"
                class="text-center my-8">
                There's nothing here
            </SectionDescription>
            <Pagination
                v-if="latestArticlesPagination.hasPages"
                :show-count="true"
                class="px-4"
                :links="latestArticlesPagination"
                preserve-scroll
            />
        </div>

        <div
            v-for="data in taggedArticles"
            :key="data.tag.id">
            <div>
                <ArticleGrid
                    :articles="data.articles.data"
                    :slider-on-mobile="false"
                    :title="data.tag.name + ' articles'"
                    class="mt-12" />
                <SectionDescription
                    v-if="data.pagination.hasPages && data.articles.data.length < 1"
                    class="text-center my-8">
                    There's nothing here
                </SectionDescription>
                <Pagination
                    class="px-4"
                    :show-count="true"
                    :links="data.pagination"
                    preserve-scroll
                />
            </div>
        </div>
    </div>
</template>
