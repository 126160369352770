<script setup lang="ts">
import TagLink from '@/Components/Tag/TagLink.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import type { Tag } from '@/Types/Models/Tag'
import type { Component, PropType } from 'vue'

defineProps({
    tags: Array as PropType<Tag[]>,
    title: String,
    description: String,
    icon: [Object as PropType<Component>, Function as PropType<Component>],
    center: {
        type: Boolean,
        default: true
    },
    small: {
        type: Boolean,
        default: false
    },
    limit: {
        type: Number,
        default: 12
    }
})

function randomInt (min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min
}

function limitTags (tags: Tag[], limit: number): Tag[] {
    return tags.slice(0, limit)
}
</script>

<template>
    <div
        class="relative flex w-full items-center justify-center">
        <div
            class="mx-auto w-full"
            :class="center ? 'text-center' : ''">
            <SectionTitle
                v-if="title"
                font-size="text-xl"
                class="flex items-center justify-between">
                {{ title }}

                <div
                    v-if="icon"
                    class="aspect-square rounded-full bg-gradient-to-t p-1.5 bg-primary from-primary to-pink">
                    <component
                        :is="icon"
                        class="w-4 text-white" />
                </div>
            </SectionTitle>
            <SectionDescription v-if="description">
                {{ description }}
            </SectionDescription>
            <div
                :class="[title ? 'mt-2' : '', center ? 'items-center justify-center' : '']"
                class="-mx-1 flex w-full flex-wrap">
                <template v-if="tags && tags.length > 0">
                    <div
                        v-for="tag in limitTags(tags, limit)"
                        :key="tag.slug"
                        :class="small ? 'p-1' : 'p-1'"
                        class="flex">
                        <TagLink
                            :small="small"
                            :tag="tag" />
                    </div>
                </template>
                <template v-else>
                    <div
                        v-for="i in 6"
                        :key="i"
                        :class="small ? 'p-1' : 'p-1'">
                        <div
                            :class="small ? 'h-[28px]' : 'h-[33px]'"
                            :style="`width:${randomInt(50, 150)}px;`"
                            class="animate-pulse rounded-full bg-primary/10 dark:bg-slate-800 dark:hover:bg-slate-950" />
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
