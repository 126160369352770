<script setup lang="ts">
import GameCard from '@/Components/Game/GameCard.vue'
import Pagination from '@/Components/UI/Pagination.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import AdContainer from '@/Components/Global/AdContainer.vue'
import GameSkeletonGrid from '@/Components/Game/GameSkeletonGrid.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import { Link } from '@inertiajs/vue3'
import { computed, type PropType, useSlots } from 'vue'
import type { PaginationMeta } from '@/Types/PaginatedItems'
import { ChevronRightIcon, ArrowRightIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
    url: String,
    title: String,
    description: String,
    games: [Object, Array] as PropType<any>,
    icon: Object,
    pagination: [Object, null] as PropType<PaginationMeta>,
    buttonText: {
        type: String,
        default: 'See more'
    },
    skeletonCount: {
        type: Number,
        default: 6
    },
    allowAd: {
        type: Boolean,
        default: true
    },
    gameWidth: {
        type: String,
        default: 'w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4'
    },
    gameTitleSize: {
        type: String
    },
    displayReleaseDate: {
        type: Boolean,
        default: true
    },
    displayDescription: {
        type: Boolean,
        default: true
    }
})

const allGames = computed(() => props.games ? props.games : [])

const containerClasses = computed(() => {
    return 'md:no-container'
})

const gameClasses = computed(() => {
    return props.gameWidth
})

const slots = useSlots()
</script>

<template>
    <div>
        <GameSkeletonGrid
            v-if="!games && skeletonCount > 0"
            :game-width="gameWidth"
            :class="containerClasses"
            :has-description="(description?.length > 0)"
            :has-title="((title && title.length) || (url && url.length)) > 0"
            :number="skeletonCount" />
        <div v-else>
            <div
                v-if="title || url || slots.title"
                class="relative mb-2 flex w-full items-start md:items-center">
                <div class="flex w-full flex-col">
                    <SectionTitle>
                        <div class="flex items-center">
                            <div
                                v-if="slots.icon"
                                class="mr-2">
                                <slot name="icon" />
                            </div>
                            {{ title }}
                        </div>
                    </SectionTitle>
                    <div
                        v-if="description"
                        class="mt-1 w-full max-w-lg">
                        <SectionDescription>
                            {{ description }}
                        </SectionDescription>
                    </div>
                </div>
                <div
                    v-if="url"
                    class="flex w-5/12 justify-end">
                    <Link
                        :href="url"
                        class="absolute top-1 left-0 flex h-9 w-full opacity-0 md:hidden">
                        <span class="sr-only">
                            Link to {{ title ?? "more" }}
                        </span>
                    </Link>

                    <Link
                        v-if="url"
                        :href="url"
                        class="hidden text-sm font-bold transition-all hover:text-primary md:flex">
                        {{ buttonText }}
                        <span class="sr-only">{{ title ?? "games" }}</span>
                        <ArrowRightIcon class="ml-2 w-5" />
                    </Link>

                    <ChevronRightIcon class="mt-1 -mr-4 flex w-8 text-slate-900 md:hidden" />
                </div>
            </div>

            <div
                v-if="allGames.length"
                :key="pagination ? pagination.current_page : null"
                :class="[containerClasses]">
                <div class="mt-0 flex flex-wrap items-stretch -mx-2.5">
                    <template
                        v-for="(game, index) in allGames"
                        :key="game.identifier">
                        <div
                            v-if="allowAd && (index === 4 || index === 12)"
                            class="mx-2 my-4 w-full">
                            <AdContainer
                                ad-slot="inGamesGridHorizontal" />
                        </div>
                        <div
                            :class="gameClasses"
                            class="p-2.5">
                            <GameCard
                                :game-title-size="gameTitleSize"
                                :display-description="displayDescription"
                                :display-release-date="displayReleaseDate"
                                :game="game"
                            />
                        </div>
                    </template>
                </div>
            </div>
            <slot
                v-if="allGames.length === 0"
                name="empty" />

            <Pagination
                v-if="pagination"
                :show-count="true"
                :links="pagination" />
        </div>
    </div>
</template>
