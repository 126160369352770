import type { Toast } from '@/Types/Toast'
import { ref } from 'vue'

export class ToastNotificationService {
    toasts = ref<Toast[]>([])

    addToast (toast: Toast) {
        toast.id = this.generateId()
        toast.show = false

        const defaultDuration: number = 3000
        toast.options.duration = (toast.options.duration || defaultDuration) + 200

        this.toasts.value.push(toast)

        this.showToast(toast.id)

        if (toast.options.duration) {
            setTimeout(() => this.removeToast(toast.id), toast.options.duration)
        }
    }

    showToast (id: string) {
        setTimeout(() => {
            this.toasts.value = this.toasts.value.map(t => t.id === id ? { ...t, show: true } : t)
        }, 200)
    }

    removeToast (id: string) {
        this.toasts.value = this.toasts.value.map(t => t.id === id ? { ...t, show: false } : t)
        setTimeout(() => {
            this.toasts.value = this.toasts.value.filter(t => t.id !== id)
        }, 500)
    }

    private generateId (): string {
        return Math.random().toString(36).substr(2, 9)
    }
}
