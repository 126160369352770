<script setup>
import { Link } from '@inertiajs/vue3'
import { computed, shallowRef } from 'vue'

const emit = defineEmits(['click', 'close'])

const props = defineProps({
    item: Object,
    active: Boolean
})

const hasIcon = typeof props.item.icon !== 'undefined' && props.item.icon !== null && props.item.icon.default !== null
const iconIsString = typeof props.item.icon === 'string'
const iconComponent = iconIsString ? props.item.icon : shallowRef(props.item.icon)
const iconComponentLight = iconIsString ? (props.item.icon_light ? props.item.icon_light : props.item.icon) : shallowRef(props.item.icon)

const url = computed(() => {
    return 'url' in props.item ? props.item.url : ''
})

const notEmpty = (value) => {
    return value !== undefined && value !== null && value !== ''
}

const show = computed(() => {
    let canShow = (notEmpty(props.item.if) ? props.item.if : true)

    if (typeof props.item.permission !== 'undefined') {
        canShow = props.item.permission === true
    }

    return canShow
})

const target = computed(() => {
    return props.item.target ? props.item.target : ''
})

const linkComponent = computed(() => {
    if (typeof props.item.component !== 'undefined') {
        return props.item.component
    }

    if (!!props.item.target && props.item.target === '_blank') {
        return 'a'
    }

    return Link
})

const elementType = computed(() => {
    if (
        props.item.method?.toLowerCase() === 'post' ||
        props.item.method?.toLowerCase() === 'put' ||
        props.item?.action ||
        props.item.method?.toLowerCase() === 'delete') {
        return 'button'
    }

    return 'a'
})

function handleClick () {
    if (props.item.action) {
        props.item.action()
    }

    emit('click')
}
</script>

<template>
    <component
        :is="linkComponent"
        v-if="show"
        :as="elementType"
        :class="['dropdown-item', item.badge ? 'relative justify-between' : '', active ? 'bg-primary text-white' : '']"
        :href="url"
        :method="item.method"
        :target="target"
        class="group/inner group"
        @click="handleClick">
        <div
            v-if="item.header"
            class="flex w-full flex-col">
            <div
                class="w-full truncate text-base font-bold font-heading"
                v-html="item.header ? item.header : ''" />
            <span
                :class="active ? 'text-white/75' : 'text-slate-900/40 dark:text-white/50'"
                class="flex w-full text-xs"
                v-html="item.title" />
        </div>
        <div
            v-else
            class="flex w-full items-center">
            <template v-if="hasIcon">
                <template v-if="iconIsString">
                    <img
                        :alt="`Icon for ${item.title}`"
                        :src="iconComponent"
                        class="mr-2 block group-hover/inner:hidden w-4 dark:hidden"
                        height="16"
                        width="16">
                    <img
                        :alt="`Dark Icon for ${item.title}`"
                        :src="iconComponentLight"
                        class="mr-2 group-hover/inner:block hidden w-4 dark:block"
                        height="16"
                        width="16">
                </template>
                <component
                    :is="item.icon"
                    v-else
                    :class="active ? 'text-white' : 'text-slate-900/40 dark:text-white/60'"
                    class="mr-4 h-4 w-4 bg-transparent group-hover:text-white dark:group-hover:text-white" />
            </template>
            <span
                class="flex"
                v-html="item.title" />
            <span
                v-if="item.badge"
                class="ml-auto rounded-full py-1 text-xs text-white bg-primary px-[10px]"
                v-html="item.badge" />
        </div>
    </component>
</template>
