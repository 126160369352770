<script setup>
import Button from '@/Components/UI/Button.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import { computed, ref } from 'vue'
import { useForm } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { usePageProps } from '@/Composables/usePageProps.ts'

function getEmailDomain (email) {
    const parts = email.split('@')
    if (parts.length === 2) { // Ensure there's only one '@' symbol
        return parts[1] // The part after '@'
    } else {
        return null // Invalid email format
    }
}

const form = useForm({})

const props = defineProps({
    email: String
})

const domain = computed(() => `https://${getEmailDomain(props.email)}`)

const submit = () => {
    form.post(useRoute('verification.send'), {
        onSuccess: response => {
            verificationLinkSent.value = true
        }
    })
}

const verificationLinkSent = ref(false)

// defineOptions({ layout: MinimalAuthLayout })
</script>

<template>
    <div class="my-24">
        <div class="mx-auto max-w-xl text-center">
            <SectionTitle>
                Check your inbox.
            </SectionTitle>
            <SectionDescription class="mt-2">
                You will need to verify your email.
            </SectionDescription>

            <a
                :href="domain"
                target="_blank">
                ICON HERE
            </a>

            <div
                v-if="verificationLinkSent"
                class="mb-4 text-sm font-medium text-green-600">
                A new verification link has been sent
            </div>

            <p class="my-2 text-sm text-slate-900/75">
                Click the link we just sent to <strong>{{ email }}</strong> to complete your account setup and gain full
                access to {{ usePageProps().app.name }}.
            </p>

            <form @submit.prevent="submit">
                <div class="mt-8 flex items-center justify-center gap-4">
                    <Button
                        :disabled="form.processing"
                        type="submit">
                        Resend Email
                    </Button>

                    <Button
                        variant="secondary"
                        :href="useRoute('contact')">
                        Contact us
                    </Button>
                </div>
            </form>
        </div>
    </div>
</template>
