<script setup>
import { onMounted, ref } from 'vue'
import { useTitle } from '@vueuse/core'
import { Head, router } from '@inertiajs/vue3'
import { usePageProps } from '@/Composables/usePageProps.ts'

const meta = ref(usePageProps().meta)
const key = ref(new Date().toDateString())

function formatTitle (title) {
    if (title === null) {
        title = 'untitled'
    }
    const notificationsCount = usePageProps().auth.notifications
    if (notificationsCount > 0) {
        return `(${notificationsCount}) ${title}`
    }

    return title
}

function applyMeta (event = null) {
    meta.value = usePageProps().meta
    key.value = event ? (event.timeStamp + event.detail.page.component) : new Date().toDateString()

    useTitle(formatTitle(meta.value?.title))
    document.querySelector('meta[name="description"]').setAttribute('content', meta.value?.description ?? '')
}

router.on('success', (event) => applyMeta(event))
onMounted(() => applyMeta())
</script>

<template>
    <div :key="key">
        <Head
            :description="meta?.description"
            :title="formatTitle(meta?.title)" />
    </div>
</template>
