<script lang="ts" setup>
import Card from '@/Components/UI/Card.vue'
import Icon from '@/Components/Icon/index.js'
import Header from '@/Components/UI/Header.vue'
import GameGrid from '@/Components/Game/GameGrid.vue'
import InputText from '@/Components/Input/InputText.vue'
import GameFilter from '@/Components/Game/GameFilter.vue'
import EmptyState from '@/Components/Global/EmptyState.vue'
import AdContainer from '@/Components/Global/AdContainer.vue'
import GameFilterList from '@/Components/Game/GameFilterList.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import AccordionTransition from '@/Components/Transition/AccordionTransition.vue'
import { watchDebounced } from '@vueuse/core'
import { Link, router } from '@inertiajs/vue3'
import type { Game } from '@/Types/Models/Game'
import type { GameType } from '@/Types/GameType'
import type { Genre } from '@/Types/Models/Genre'
import type { PageHeader } from '@/Types/PageHeader'
import { useRoute } from '@/Composables/useRoute.ts'
import type { Platform } from '@/Types/Models/Platform'
import type { PaginationMeta } from '@/Types/PaginatedItems'
import { nextTick, onMounted, type PropType, ref } from 'vue'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/20/solid'

type filterType = 'genres' | 'platforms' | 'types' | 'modes'
type additionalDataType = {
    name: string
    logo?: string
    description?: string
    release_date?: string
}

const props = defineProps({
    data: Object as Object as PropType<{
        games: Game[],
        pagination: PaginationMeta,
    }>,
    selectedGenres: Array as PropType<Genre['id'][]>,
    selectedPlatforms: Array as PropType<Platform['id'][]>,
    selectedTypes: Array as PropType<string[]>,
    selectedModes: Array as PropType<string[]>,
    modes: Array as PropType<string[]>,
    genres: Array as PropType<Genre[]>,
    types: Array as PropType<GameType[]>,
    additionalData: Object as PropType<additionalDataType>,
    disabledFilter: [String, null] as PropType<filterType>,
    platforms: Array as PropType<Platform[]>,
    header: Object as PropType<PageHeader>,
    search: String,
    page: [Number, String],
    minCount: {
        type: Number,
        default: 10
    }
})

const loading = ref<boolean>(false)
const allGames = ref<Game[]>(props.data?.games)
const searchQuery = ref<string>(props.search)

const allSelectedGenres = ref<number[]>(props.selectedGenres)
const allSelectedPlatforms = ref<number[]>(props.selectedPlatforms)
const allSelectedTypes = ref<string[]>(props.selectedTypes)
const allSelectedModes = ref<string[]>(props.selectedModes)

const showOrder = ref<boolean>(true)

const fields = ['data', 'page', 'selectedGenres', 'search', 'selectedPlatforms', 'selectedModes', 'selectedTypes']

const lazyload = () => {
    router.reload({
        only: fields,
        onSuccess: () => {
            loading.value = false
            allGames.value = props.data.games
        }
    })
}

const handleSelection = (newValue: any, oldValue: any, ref: any) => {
    if (newValue.length > 1) {
        if (newValue.includes(0) && !oldValue.includes(0)) {
            newValue = [0]
            ref.value = newValue
        }

        if (newValue.includes('all') && !oldValue.includes('all')) {
            newValue = ['all']
            ref.value = newValue
        }

        // If "All" is already selected, and then you select another genre, remove "All"
        if ((oldValue.includes(0) || oldValue.includes('all'))) {
            newValue = newValue.filter(id => id !== 0 && id !== 'all')
            ref.value = newValue
        }
    }
}

const watchSelections = (ref: any, name: string) => {
    watchDebounced(ref, (newValue, oldValue) => {
        const isNotString = typeof ref.value !== 'string'
        if (isNotString) {
            handleSelection(newValue, oldValue, ref)
        }
        loading.value = true
        router.reload({
            only: fields,
            data: { page: 1, [name]: isNotString ? newValue.join(',') : newValue },
            onSuccess: () => {
                loading.value = false
                allGames.value = props.data.games
            }
        })
    }, { debounce: 500, maxWait: 1000 })
}

watchSelections(allSelectedTypes, 'types')
watchSelections(allSelectedGenres, 'genres')
watchSelections(allSelectedPlatforms, 'platforms')
watchSelections(allSelectedModes, 'modes')
watchSelections(searchQuery, 'q')

onMounted(() => nextTick(lazyload))

router.on('navigate', () => {
    loading.value = true
    lazyload()
})
</script>

<template>
    <div class="pb-12">
        <Header :header="header" />

        <div class="mt-4 md:container">
            <div class="flex w-full flex-col items-start gap-8 md:flex-row md:gap-4">
                <div class="relative w-full p-4 md:w-1/4 md:p-0">
                    <Card>
                        <div
                            class="flex flex-col gap-5 md:flex md:gap-8">
                            <div class="flex w-full flex-col">
                                <button
                                    class="flex w-full items-center justify-between text-left text-lg font-semibold font-heading"
                                    @click="showOrder = !showOrder">
                                    Order
                                    <ChevronUpIcon
                                        v-if="showOrder"
                                        class="w-6" />
                                    <ChevronDownIcon
                                        v-else
                                        class="w-6" />
                                </button>
                                <AccordionTransition>
                                    <div
                                        v-show="showOrder"
                                        class="mt-2 flex w-full flex-col gap-4">
                                        <GameFilter
                                            :genres="genres"
                                            :platforms="platforms"
                                            :data="header.orderData"
                                            :route-name="header.orderRoute" />
                                        <InputText
                                            v-model="searchQuery"
                                            placeholder="Search games..."
                                            :clearable="true" />
                                    </div>
                                </AccordionTransition>
                                <div
                                    v-if="!showOrder"
                                    class="h-px w-full translate-y-2 bg-slate-900/10 md:translate-y-4" />
                            </div>

                            <div v-if="disabledFilter !== 'genres'">
                                <GameFilterList
                                    v-model="allSelectedGenres"
                                    :items="genres"
                                    title="Genres" />
                            </div>

                            <div v-if="disabledFilter !== 'platforms'">
                                <GameFilterList
                                    v-model="allSelectedPlatforms"
                                    :items="platforms"
                                    title="Platforms" />
                            </div>

                            <div v-if="disabledFilter !== 'types'">
                                <GameFilterList
                                    v-model="allSelectedTypes"
                                    :items="types"
                                    item-key="value"
                                    all-value="all"
                                    item-label="label"
                                    title="Types" />
                            </div>

                            <div v-if="disabledFilter !== 'modes'">
                                <GameFilterList
                                    v-model="allSelectedModes"
                                    :items="modes"
                                    item-key="value"
                                    all-value="all"
                                    item-label="label"
                                    title="Modes" />
                            </div>
                        </div>
                    </Card>

                    <AdContainer
                        class="mx-2 mt-4"
                        ad-slot="gameGridSidebar" />
                </div>
                <div class="relative w-full md:w-3/4">
                    <GameGrid
                        :key="'games-' + allGames?.length"
                        :pagination="data?.pagination"
                        :skeleton-count="minCount"
                        class="mt-5 px-5 md:-mt-1.5"
                        :games="allGames">
                        <template #empty>
                            <EmptyState
                                title="No games could be found"
                                :icon="Icon.GamePad">
                                <template #description>
                                    <div class="mt-2 mb-0 prose prose-sm">
                                        <p>
                                            Try changing your filters or search query.
                                            <br>
                                            Believe this is a mistake?
                                            <Link
                                                :href="useRoute('contact')"
                                                class="hover:underline">
                                                Let us know
                                            </Link>
                                        </p>
                                    </div>
                                </template>
                            </EmptyState>
                        </template>
                    </GameGrid>
                    <SectionDescription class="mt-8 px-4">
                        Games are being imported constantly from various sources. If you believe a game is missing, please
                        <Link
                            class="text-primary hover:underline"
                            :href="useRoute('contact')">
                            contact us
                        </Link>.
                    </SectionDescription>
                </div>
            </div>
        </div>
    </div>
</template>
