import { ref, onMounted, onBeforeUnmount } from 'vue'

export function useNavbarScroll () {
    const isVisible = ref(true)
    const lastScrollPosition = ref(0)

    const handleScroll = () => {
        if (window.innerWidth <= 1025) {
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
            isVisible.value = currentScrollPosition < lastScrollPosition.value
            lastScrollPosition.value = currentScrollPosition <= 0 ? 0 : currentScrollPosition
        }
    }

    onMounted(() => {
        window.addEventListener('scroll', handleScroll)
    })

    onBeforeUnmount(() => {
        window.removeEventListener('scroll', handleScroll)
    })

    return { isVisible }
}
