<script setup>

import Header from '@/Components/UI/Header.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import { computed } from 'vue'

const props = defineProps({
    title: [String, null],
    identifier: [String, null],
    fullHeader: Boolean,
    headerColour: [String, null],
    date: [String, null],
    description: [String, null],
    content: [String, null]
})

const header = computed(() => {
    return props.fullHeader
        ? {
            title: props.title,
            description: props.description,
            colour: props.headerColour
        }
        : null
})
</script>

<template>
    <Header
        v-if="fullHeader"
        :header="header" />
    <div
        v-else
        class="mx-auto mt-16 w-full max-w-4xl px-4">
        <SectionTitle
            class="mb-2 text-center"
            v-html="title" />
        <SectionDescription
            v-if="date"
            class="text-center"
            v-html="`Updated: ${date}`" />
        <SectionDescription
            v-if="description"
            class="text-center"
            v-html="description" />
    </div>
    <div
        class="mx-auto w-full max-w-4xl px-4"
        :class="fullHeader ? 'my-12' : 'mb-12'">
        <div
            class="my-8 max-w-none prose generic-content"
            :class="'content-' + identifier"
            v-html="content" />
    </div>
</template>
