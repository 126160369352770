<script setup>
import VerifiedBadge from '@/Components/User/VerifiedBadge.vue'
import { useMemoize } from '@vueuse/core'
import { useRoute } from '@/Composables/useRoute.ts'
import { useImageOptimise } from '@/Composables/useImageOptimise.js'

const props = defineProps({
    user: Object || null,
    showVerified: {
        type: Boolean,
        default: false
    },
    realImage: {
        type: Boolean,
        default: false
    },
    roundedClass: {
        type: String,
        default: 'rounded-full'
    },
    size: {
        type: String,
        default: 'medium'
    },
    type: {
        type: String,
        default: 'webp'
    },
    imageSize: {
        type: Number,
        default: 32
    }
})

function getTimestamp () {
    const now = new Date()
    const minutes = now.getMinutes()
    const roundedMinutes = minutes - (minutes % 2)

    now.setMinutes(roundedMinutes, 0, 0)
    return now.getTime()
}

const getImage = useMemoize(() => {
    if (props.realImage) {
        const avatar = props.user.avatars[props.size + '_' + props.type]
        if (avatar === undefined) {
            return useRoute('user.avatar', { user: props.user, size: props.size, type: props.type, t: getTimestamp() })
        }
    }

    return useRoute('user.avatar', { user: props.user, size: props.size, type: props.type, t: getTimestamp() })
})
</script>

<template>
    <div class="relative">
        <VerifiedBadge
            v-if="showVerified"
            :user="user"
            class="absolute top-0 right-0 w-8" />
        <div
            class="aspect-square overflow-hidden bg-pink-light"
            :class="roundedClass">
            <img
                v-if="user"
                :alt="`Avatar for user: ${user.name}`"
                :height="imageSize"
                itemprop="image"
                :src="useImageOptimise(getImage())"
                :width="imageSize"
                class="h-full w-full object-cover object-center"
                loading="lazy">
        </div>
    </div>
</template>
