<script setup>

import Spinner from '@/Components/UI/Spinner.vue'
import { Link } from '@inertiajs/vue3'

const props = defineProps({
    component: {
        type: String,
        default: 'button'
    },
    padding: {
        type: String,
        default: 'px-4 py-1.5'
    },
    innerClass: {
        type: String,
        default: ''
    },
    target: {
        type: String,
        default: null
    },
    href: {
        type: String,
        default: null
    },
    rounded: {
        type: String,
        default: 'after:rounded-full rounded-full'
    },
    variant: {
        type: String,
        default: 'primary'
    },
    loading: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['click'])
let componentTag = props.component

if (props.href) {
    componentTag = Link
}

let buttonColours = ''
switch (props.variant) {
case 'primary':
    buttonColours = 'button-primary'
    break
case 'primary-light':
    buttonColours = 'button-primary-light'
    break
case 'secondary':
    buttonColours = 'button-secondary'
    break
case 'danger':
    buttonColours = 'button-danger'
    break
case 'danger-light':
    buttonColours = 'button-danger-light'
    break
case 'white':
    buttonColours = 'button-white'
    break
case 'white-adaptable':
    buttonColours = 'button-white-adaptable'
    break
case 'white-ghost':
    buttonColours = 'button-white-ghost'
    break
}

let defaultClasses = 'button'
if (props.variant === 'white') {
    defaultClasses += ' hover:after:opacity-5'
} else {
    defaultClasses += ' hover:after:opacity-10'
}

function handleClick () {
    if (!props.href) {
        emit('click')
    }
}
</script>

<template>
    <component
        :is="target !== null ? 'a' : componentTag"
        :target="target"
        :class="[buttonColours, padding, rounded, defaultClasses, loading ? 'pointer-events-none opacity-75' : '']"
        :disabled="loading"
        :href="href"
        @click="handleClick">
        <span
            :class="!loading ? 'opacity-0 scale-0' : 'opacity-100 scale-100'"
            class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition-all">
            <Spinner
                class="h-4 w-4 text-black/90"
                spinner="fill-white" />
        </span>
        <span
            :class="[loading ? 'scale-0 opacity-0' : 'scale-100 opacity-100', innerClass]"
            class="flex items-center justify-center text-center">
            <slot />
        </span>
    </component>
</template>
