<template>
    <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.6665 14.5V13.1667C11.6665 12.4594 11.3856 11.7811 10.8855 11.281C10.3854 10.781 9.70708 10.5 8.99984 10.5H4.33317C3.62593 10.5 2.94765 10.781 2.44755 11.281C1.94746 11.7811 1.6665 12.4594 1.6665 13.1667V14.5"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M14.3335 5.83325V9.83325"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M16.3335 7.83325H12.3335"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M6.66667 7.83333C8.13943 7.83333 9.33333 6.63943 9.33333 5.16667C9.33333 3.69391 8.13943 2.5 6.66667 2.5C5.19391 2.5 4 3.69391 4 5.16667C4 6.63943 5.19391 7.83333 6.66667 7.83333Z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
    </svg>
</template>
