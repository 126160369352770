<script setup>
import * as Status from '@/Enums/Status.js'
import Card from '@/Components/UI/Card.vue'
import Button from '@/Components/UI/Button.vue'
import TabLinks from '@/Components/UI/TabLinks.vue'
import Pagination from '@/Components/UI/Pagination.vue'
import InputText from '@/Components/Input/InputText.vue'
import DashboardLayout from '@/Layouts/DashboardLayout.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import InputSelect from '@/Components/Input/InputSelect.vue'
import DashboardTitle from '@/Components/Dashboard/DashboardTitle.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import ArticleManageCard from '@/Components/Article/ArticleManageCard.vue'
import ArticleCreateButton from '@/Components/Article/ArticleCreateButton.vue'
import ArticleManageActionsModal from '@/Components/Article/ArticleManageActionsModal.vue'
import { ref, watch } from 'vue'
import { router } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { PencilIcon } from '@heroicons/vue/24/solid/index.js'
import { useCurrentUrl } from '@/Composables/useCurrentUrl.js'
import { MagnifyingGlassIcon, BarsArrowDownIcon, BarsArrowUpIcon } from '@heroicons/vue/20/solid'
import { breakpointsTailwind, useBreakpoints, useUrlSearchParams, watchDebounced } from '@vueuse/core'

const props = defineProps({
    articles: Object,
    statuses: Object,
    hasArticles: Boolean,
    initialStatus: String,
    direction: String
})

const orderOptions = [
    {
        key: 'created_at',
        title: 'Created'
    },
    {
        key: 'updated_at',
        title: 'Last updated'
    },
    {
        key: 'title',
        title: 'Title'
    },
    {
        key: 'views',
        title: 'Views'
    }
]

const params = useUrlSearchParams('history')
const search = ref(params.search)
const order = ref(params.order ? orderOptions.find(option => option.key === params.order) : orderOptions[0])
const selectedDirection = ref(props.direction ? props.direction : 'desc')
const status = ref(props.statuses.find(option => option.key === props.initialStatus))

const linkedStatuses = props.statuses.filter(status => {
    return ['all', Status.PUBLISHED, Status.DRAFTS].includes(status.key)
})
const dropdownStatuses = props.statuses

const breakpoints = useBreakpoints(breakpointsTailwind)
const mobileOnly = breakpoints.smallerOrEqual('md')

const selectedStatus = ref((mobileOnly ? props.statuses : dropdownStatuses).find(option => option.key === props.initialStatus))

watchDebounced(
    search, () => {
        handleSearch(search.value)
    },
    {
        debounce: 500,
        maxWait: 1000
    }
)

function handleSearch (value) {
    router.get(
        useCurrentUrl(),
        { search: value },
        {
            preserveState: true,
            replace: true
        }
    )
}

function filterArticles () {
    let statusValue = (selectedStatus.value ? selectedStatus.value : status.value).key
    if (statusValue === 'all') {
        statusValue = null
    }

    router.get(useRoute('dashboard.articles', {
        search: search.value,
        status: statusValue,
        order: order.value ? order.value.key : null,
        direction: selectedDirection.value ? selectedDirection.value : null
    }))
}

watch([order, selectedStatus, selectedDirection], () => {
    filterArticles()
})

defineOptions({
    layout: (h, page) => h(DashboardLayout, {}, () => [page]),
    inheritAttrs: false
})
</script>

<template>
    <div>
        <div class="relative flex w-full flex-col md:flex-row">
            <DashboardTitle
                class="w-full"
                title="My Articles"
                description="Manage and create your articles here." />
        </div>

        <div
            class="mb-4 flex flex-col gap-x-4 gap-y-2 md:space-y-0 md:gap-y-4 xl:flex-row xl:items-center xl:justify-between">
            <div class="flex w-full flex-col items-center gap-2 sm:flex-row md:gap-4 xl:w-5/12">
                <div class="w-full md:max-w-56">
                    <InputText
                        v-model="search"
                        icon-classes="w-4 text-slate-900/75 dark:text-white/75 dark:group-focus-within:text-white group-focus-within:text-slate-900"
                        :clearable="true"
                        input-type="search"
                        placeholder="Search...">
                        <template #icon>
                            <MagnifyingGlassIcon />
                        </template>
                    </InputText>
                </div>
                <div
                    class="w-full md:max-w-48">
                    <InputSelect
                        v-model="selectedStatus"
                        class="w-full"
                        :clearable="false"
                        :options="mobileOnly ? statuses : dropdownStatuses"
                        display-label="title"
                        placeholder="View..." />
                </div>
            </div>
            <div class="flex w-full items-center gap-2 md:gap-4 xl:w-7/12 xl:justify-end">
                <div class="hidden w-full md:flex md:w-auto">
                    <TabLinks
                        :tabs="linkedStatuses"
                        :current="status.key" />
                </div>
                <InputSelect
                    v-model="order"
                    class="w-full md:max-w-40"
                    :clearable="false"
                    :options="orderOptions"
                    display-label="title"
                    placeholder="Order..." />
                <Button
                    v-tooltip="`Order direction`"
                    variant="white-adaptable"
                    rounded="rounded-lg after:rounded-lg"
                    @click="selectedDirection === 'desc' ? selectedDirection = 'asc' : selectedDirection = 'desc'">
                    <span class="sr-only">Change order of list</span>
                    <BarsArrowDownIcon
                        v-if="selectedDirection === 'asc'"
                        class="w-5" />
                    <BarsArrowUpIcon
                        v-if="selectedDirection === 'desc'"
                        class="w-5" />
                </Button>
            </div>
        </div>
        <div
            v-if="!hasArticles"
            class="mt-24 flex h-full w-full flex-col items-center justify-center rounded-2xl bg-transparent p-8 text-center lg:p-0">
            <SectionTitle>
                <template v-if="[Status.DRAFTS, Status.PUBLISHED, 'all'].includes(selectedStatus.key)">
                    You haven't written any articles yet.
                </template>
                <template
                    v-if="[Status.REJECTED, Status.APPROVED, Status.SUBMITTED, Status.IN_REVIEW].includes(selectedStatus.key)">
                    You haven't submitted any articles yet.
                </template>
            </SectionTitle>
            <SectionDescription>
                <template v-if="[Status.DRAFTS, Status.PUBLISHED, 'all'].includes(selectedStatus.key)">
                    Start writing your first article and share it with the world.
                </template>
                <template
                    v-if="[Status.REJECTED, Status.APPROVED, Status.SUBMITTED, Status.IN_REVIEW].includes(selectedStatus.key)">
                    Submit an article and share it with the world.
                </template>
            </SectionDescription>
            <div class="mt-6">
                <Button
                    variant="secondary"
                    class="flex"
                    :href="useRoute('dashboard.article.create')">
                    <div class="flex">
                        <PencilIcon class="mr-2 w-4" />
                        Get started
                    </div>
                </Button>
            </div>
        </div>

        <div
            v-else
            class="pb-28">
            <Card
                :padding="null"
                class="overflow-hidden"
                rounded="rounded-xl">
                <div class="min-w-full table-fixed text-left divide-y divide-primary/10">
                    <div class="hidden text-sm md:flex">
                        <div class="w-28 shrink-0 px-4 py-4 text-left text-sm font-semibold text-slate-900 dark:text-white xl:w-36">
                            Image
                        </div>
                        <div class="w-6/12 px-4 py-4 pr-12 text-left text-sm font-semibold text-slate-900 dark:text-white xl:mr-20">
                            Title
                        </div>
                        <div class="ml-auto flex w-5/12 items-center justify-end gap-12">
                            <div class="w-5/12 py-4 text-left text-sm font-semibold text-slate-900 dark:text-white">
                                Stats
                            </div>
                            <div class="flex w-32 justify-end py-2 pr-4 text-sm font-semibold text-slate-900 dark:text-white">
                                Actions
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="articles.data.length > 0"
                        class="divide-y divide-primary/10">
                        <ArticleManageCard
                            v-for="(article, i) in articles.data"
                            :key="article.uuid"
                            :index="i"
                            :article="article" />
                    </div>
                    <div
                        v-else
                        class="py-8 text-center text-sm">
                        No articles match this query
                    </div>
                </div>
            </Card>
            <div class="mt-8 border-y border-secondary/5 dark:border-slate-800">
                <Pagination
                    :links="articles.meta.links"
                    :on-page-change="filterArticles" />
            </div>
            <div
                v-if="articles.data.length && articles.data.length < 16"
                class="flex w-full justify-end pt-4">
                <ArticleCreateButton />
            </div>
        </div>

        <ArticleManageActionsModal />
    </div>
</template>
