<script setup>
import Turnstile from 'cfturnstile-vue3'
import Button from '@/Components/UI/Button.vue'
import ErrorText from '@/Components/UI/ErrorText.vue'
import Separator from '@/Components/UI/Separator.vue'
import InputText from '@/Components/Input/InputText.vue'
import SocialAuth from '@/Components/Auth/SocialAuth.vue'
import { useForm } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { usePageProps } from '@/Composables/usePageProps.ts'

const props = defineProps({
    displaySocials: {
        type: Boolean,
        default: true
    },
    data: {
        type: Object,
        default () {
            return {
                name: null,
                username: null,
                email: null,
                remember: true,
                oauth: false
            }
        }
    }
})

const form = useForm({
    name: props.data.name,
    username: props.data.username,
    email: props.data.email,
    oauth: props.data.oauth,
    password: null,
    password_confirmation: null,
    newsletter: false,
    cf_response: null,
    remember: props.data.remember
})

// const emit = defineEmits(['success'])

function verifyTurnstile (token) {
    form.cf_response = token
}

function submit () {
    form.post(useRoute('register'), {
        preserveScroll: true,
        onSuccess: () => {
            // emit('success')
            // router.reload()
            window.location.reload()
        }
    })
}
</script>

<template>
    <div class="w-full">
        <ErrorText
            v-if="usePageProps().errors?.oauth"
            :error="usePageProps().errors?.oauth" />
        <form @submit.prevent="submit">
            <div class="flex flex-col gap-4">
                <InputText
                    v-model="form.email"
                    :error="form.errors.email"
                    autocomplete="email"
                    label="Email"
                    @input="form.clearErrors('email')" />
                <div
                    class="flex w-full gap-4">
                    <InputText
                        v-model="form.name"
                        :error="form.errors.name"
                        autocomplete="name"
                        class="w-full"
                        label="Full Name"
                        @input="form.clearErrors('name')" />
                    <InputText
                        v-model="form.username"
                        class="w-full"
                        :error="form.errors.username"
                        autocomplete="username"
                        label="Username" />
                </div>
                <div
                    class="flex gap-4">
                    <InputText
                        v-model="form.password"
                        :error="form.errors.password"
                        autocomplete="new-password"
                        class="w-full"
                        input-type="password"
                        label="Password"
                        @input="form.clearErrors('password')" />
                    <InputText
                        v-model="form.password_confirmation"
                        :error="form.errors.password_confirmation"
                        class="w-full"
                        autocomplete="new-password"
                        input-type="password"
                        label="Confirm password"
                        @input="form.clearErrors('password_confirmation')" />
                </div>
            </div>
            <!--            <div class="mt-4">-->
            <!--                <InputCheckbox-->
            <!--                    v-model="form.newsletter"-->
            <!--                    :label="`Join the ${usePageProps().app.name} newsletter`" />-->
            <!--            </div>-->

            <div
                v-if="usePageProps().config.turnstile.enabled"
                class="mt-4">
                <Turnstile
                    :sitekey="usePageProps().config.turnstile.key"
                    class="mb-1"
                    @verify="verifyTurnstile" />
                <ErrorText
                    v-if="form.errors.cf_response"
                    :error="form.errors.cf_response" />
            </div>

            <p class="mt-4 text-left text-xs text-slate-900/75 dark:text-white/75">
                By creating an account you agree to our <a
                    :href="useRoute('terms')"
                    class="font-semibold text-primary hover:underline"
                    target="_blank">Terms & Conditions</a>
            </p>
            <Button
                :disabled="form.processing"
                class="mt-6 w-full"
                type="submit"
                padding="px-4 py-2"
                variant="primary">
                Create an account
            </Button>
        </form>
        <Separator v-if="displaySocials">
            or
        </Separator>
        <SocialAuth v-if="displaySocials" />
    </div>
</template>
