import { onMounted, type Ref, ref } from 'vue'
import { useCountArticles } from '@/Composables/useCountArticles.js'
import type { User } from '@/Types/Models/User'

export function useArticleCount (user: User, initialCount: number | null = null): Ref<number | null> {
    const articleCount = ref<number | null>(initialCount)

    onMounted(() => {
        useCountArticles(user).then(function (result: number) {
            articleCount.value = result
        })
    })

    return articleCount
}
