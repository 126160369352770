<script setup>
import Modal from '@/Components/UI/Modal.vue'
import Button from '@/Components/UI/Button.vue'
import ShowArticle from '@/Pages/Article/Show.vue'
import UserAvatar from '@/Components/User/UserAvatar.vue'
import InputTextarea from '@/Components/Input/InputTextarea.vue'
import ArticleThumbnail from '@/Components/Article/ArticleThumbnail.vue'
import { ref } from 'vue'
import { Link, useForm } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { usePlural } from '@/Composables/usePlural.js'
import { ChevronUpIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
    article: Object,
    game: Object,
    user: Object,
    publish_at: String | null,
    user_joined_at: String,
    user_last_article: Object | null,
    user_article_count: Number,
    article_submitted_at: String,
    article_length: Number,
    article_image_count: Number,
    article_tag_count: Number
})

const rejectModalOpen = ref(false)
const approveModalOpen = ref(false)

const infoClicked = ref(false)

const rejectForm = useForm({
    reason: null
})

const approveForm = useForm({})

function openRejectModal () {
    rejectModalOpen.value = true
}

function closeRejectModal () {
    rejectModalOpen.value = false
    rejectForm.reset()
}

function openApproveModal () {
    approveModalOpen.value = true
}

function closeApproveModal () {
    approveModalOpen.value = false
}

function submitRejection () {
    rejectForm.post(useRoute('article.review.reject', props.article))
}

function submitApproval () {
    approveForm.post(useRoute('article.review.approve', props.article))
}
</script>

<template>
    <div class="relative">
        <Modal
            :closeable="true"
            :show="rejectModalOpen"
            max-width="lg"
            @close="closeRejectModal">
            <template #title>
                Reject article
            </template>
            <template #content>
                <div>
                    <p class="mb-6">
                        Article will be sent back to the drafts for {{ article.user.name }}. Rejection reason must be
                        reasonable and descriptive, at <strong>least</strong> 10 characters.
                    </p>
                    <InputTextarea
                        v-model="rejectForm.reason"
                        :autoheight="true"
                        :error="rejectForm.errors.reason"
                        placeholder="Reason for rejection" />
                </div>
            </template>
            <template #footer>
                <div class="flex w-full items-center justify-between">
                    <Button
                        type="button"
                        variant="white"
                        @click="closeRejectModal">
                        Cancel
                    </Button>

                    <Button
                        :disabled="rejectForm.processing || (rejectForm.reason === '' || rejectForm.reason === null)"
                        type="button"
                        variant="primary"
                        @click="submitRejection">
                        Reject
                    </Button>
                </div>
            </template>
        </Modal>

        <Modal
            :closeable="true"
            :show="approveModalOpen"
            max-width="lg"
            @close="closeApproveModal">
            <template #title>
                Approve article
            </template>
            <template #content>
                <div>
                    <p>
                        Article will be published {{ publish_at ? 'at ' + publish_at : 'instantly' }}
                    </p>
                </div>
            </template>
            <template #footer>
                <div class="flex w-full items-center justify-between">
                    <Button
                        type="button"
                        variant="white"
                        @click="closeRejectModal">
                        Cancel
                    </Button>

                    <Button
                        :disabled="approveForm.processing"
                        type="button"
                        variant="primary"
                        @click="submitApproval">
                        Approve
                    </Button>
                </div>
            </template>
        </Modal>

        <div class="fixed right-5 bottom-4 z-50 w-full max-w-sm space-y-4 md:right-12 md:bottom-12">
            <div
                :class="infoClicked ? '' : 'translate-y-full opacity-0 pointer-events-none'"
                class="z-50 rounded-lg bg-white p-4 shadow transition-all delay-100 md:pointer-events-auto md:translate-y-0 md:opacity-100">
                <ul>
                    <li>
                        <div class="flex items-center text-sm space-x-2.5 md:flex-row">
                            <a
                                :href="useRoute('user.show', article.user)"
                                target="_blank">
                                <UserAvatar
                                    :user="article.user"
                                    class="w-10 mt-[-2px]"
                                    size="xsmall" />
                            </a>
                            <div class="flex flex-col text-sm">
                                <div class="flex items-center space-x-4">
                                    <a
                                        :href="useRoute('user.show', article.user)"
                                        class="flex items-center space-x-1 hover:text-primary"
                                        target="_blank">
                                        <span>{{ article.user.name }}</span>
                                    </a>
                                    <span
                                        class="flex items-center text-slate-900/75 space-x-2">{{ user_article_count
                                    }} {{ usePlural('article', user_article_count)
                                    }}</span>
                                </div>
                                <div class="flex flex-col">
                                    <span class="text-slate-900/75">Joined {{ user_joined_at }}</span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="user_article_count > 0"
                        class="mt-4">
                        <div class="block select-none text-left text-sm text-slate-900/75 mb-1.5">
                            Last article
                        </div>
                        <a
                            :href="user_last_article.permalink"
                            class="flex space-x-4"
                            target="_blank">
                            <div class="w-1/3">
                                <ArticleThumbnail
                                    :actions="false"
                                    :article="article"
                                    :show-category="false" />
                            </div>
                            <div class="w-2/3">
                                <h2 class="flex items-start justify-between text-base font-semibold line-clamp-1 font-heading group-hover:text-primary">
                                    {{ article.title }}
                                </h2>
                                <p class="-mt-1 text-sm text-slate-900/75 line-clamp-2">
                                    {{ article.excerpt }}
                                </p>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
            <div
                :class="infoClicked ? '' : 'translate-y-full opacity-0 pointer-events-none'"
                class="z-50 rounded-lg bg-white p-4 shadow transition-all md:pointer-events-auto md:translate-y-0 md:opacity-100">
                <ul>
                    <li>
                        <div class="block select-none text-left text-sm text-slate-900/75">
                            Submitted
                        </div>
                        <div class="text-sm font-semibold text-slate-900/75">
                            {{ article_submitted_at }}
                        </div>
                    </li>
                </ul>
                <ul class="mt-6 mb-4 flex items-center justify-around">
                    <li class="flex flex-col items-center justify-center">
                        <h3 class="text-2xl font-bold text-primary">
                            {{ article_length }}
                        </h3>
                        <p class="text-sm text-slate-900/75">
                            Words
                        </p>
                    </li>
                    <li class="flex flex-col items-center justify-center">
                        <h3 class="text-2xl font-bold text-primary">
                            {{ article_image_count }}
                        </h3>
                        <p class="text-sm text-slate-900/75">
                            {{ usePlural('Image', article_image_count) }}
                        </p>
                    </li>
                    <li class="flex flex-col items-center justify-center">
                        <h3 class="text-2xl font-bold text-primary">
                            {{ article_tag_count }}
                        </h3>
                        <p class="text-sm text-slate-900/75">
                            {{ usePlural('Tag', article_tag_count) }}
                        </p>
                    </li>
                </ul>
            </div>
            <div class="z-50 rounded-lg bg-white px-4 pt-2 pb-4 shadow transition-all md:pt-4">
                <div class="flex justify-between pb-2 md:hidden">
                    <Link
                        :href="useRoute('dashboard.moderate')"
                        class="flex items-center text-sm text-slate-900/75 space-x-4">
                        <ChevronUpIcon class="w-4 -rotate-90" />
                        Go back
                    </Link>
                    <button
                        class="flex items-center text-sm text-slate-900/75 space-x-4"
                        @click="infoClicked = !infoClicked">
                        Info
                        <ChevronUpIcon
                            :class="infoClicked ? 'rotate-180' : ''"
                            class="w-4 transition-all" />
                    </button>
                </div>
                <div class="flex w-full items-center gap-4">
                    <Button
                        class="w-full"
                        variant="danger-light"
                        @click="openRejectModal">
                        Reject
                    </Button>
                    <Button
                        class="w-full"
                        variant="primary"
                        @click="openApproveModal">
                        Approve
                    </Button>
                </div>
            </div>
        </div>
        <ShowArticle
            :actions-enabled="false"
            :related-enabled="false"
            :game="game"
            :article="article"
            :comments-enabled="false" />
    </div>
</template>
