<script setup lang="ts">
import Button from '@/Components/UI/Button.vue'
import useEmitter from '@/Composables/useEmitter.js'
import { useRoute } from '@/Composables/useRoute.ts'
import { PencilIcon } from '@heroicons/vue/24/solid'
import { useIsAuthed } from '@/Composables/useIsAuthed.js'
import { useAnalyticsEvent } from '@/Composables/useAnalyticsEvent.js'

defineProps({
    variant: {
        type: String,
        default: 'primary'
    },
    padding: {
        type: String,
        default: 'px-4 py-2'
    },
    append: [Object, null],
    textDesktopOnly: {
        type: Boolean,
        default: false
    },
    text: {
        type: String,
        default: 'Write a new article'
    }
})

function handleClick () {
    useAnalyticsEvent('Click from ArticleCreateButton')
    if (!useIsAuthed()) {
        useEmitter.emit('openAuthModal')
    }
}
</script>

<template>
    <div class="flex">
        <Button
            :variant="variant"
            class="flex"
            :padding="padding"
            :href="useIsAuthed() ? useRoute('dashboard.article.create', append) : null"
            @click="handleClick()">
            <div class="flex">
                <PencilIcon
                    class="w-4"
                    :class="textDesktopOnly ? 'lg:mr-2' : (text ? 'mr-2' : '')" />
                <span :class="textDesktopOnly ? 'hidden lg:flex' : ''">{{ text }}</span>
            </div>
        </Button>
    </div>
</template>
