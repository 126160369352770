<script setup>
import Button from '@/Components/UI/Button.vue'
import { computed } from 'vue'
import { useRoute } from '@/Composables/useRoute.ts'
import { FireIcon, InformationCircleIcon, MagnifyingGlassIcon, ChevronRightIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
    status: Number
})

const title = computed(() => {
    return {
        503: 'Under maintenance',
        500: 'Something went wrong',
        404: 'Page not found',
        403: 'Page not found'
    }[props.status]
})

const message = computed(() => {
    return {
        503:
            'We\'re running some routine maintenance. We\'ll be right back',
        500:
            'Try refreshing, or contact us if the issue persists.',
        404: 'Sorry, we can\'t find the page you\'re looking for',
        403: 'Sorry, we can\'t find the page you\'re looking for'
    }[props.status]
})

const links = [
    {
        name: 'Search',
        href: useRoute('search.index'),
        description: 'Try and find what you\'re looking for here.',
        icon: MagnifyingGlassIcon
    },
    {
        name: 'Latest articles',
        href: useRoute('article.index'),
        description: 'Check out our latest and greatest.',
        icon: FireIcon
    },
    {
        name: 'About us',
        href: useRoute('about-us'),
        description: 'See what we\'re all about.',
        icon: InformationCircleIcon
    }
]
</script>

<template>
    <div class="mx-auto w-full max-w-7xl px-6 pt-10 pb-16 sm:pb-24 lg:px-8">
        <div class="mx-auto mt-8 max-w-2xl text-center sm:mt-20">
            <p class="text-base font-semibold leading-8 text-primary dark:text-white">
                {{ status }}
            </p>
            <h1 class="mt-4 text-3xl font-semibold font-heading sm:text-5xl">
                {{ title }}
            </h1>
            <p class="mt-4 text-base leading-7 text-slate-600 dark:text-slate-400 sm:mt-6 sm:text-lg sm:leading-8">
                {{ message }}
            </p>
        </div>
        <div class="mx-auto mt-16 flow-root max-w-lg sm:mt-20">
            <h2 class="sr-only">
                Useful links
            </h2>
            <ul
                role="list"
                class="-mt-6 border-b border-gray-900/5 divide-y divide-gray-900/5">
                <li
                    v-for="(link, linkIdx) in links"
                    :key="linkIdx"
                    class="relative flex items-center gap-x-6 py-6">
                    <div
                        class="flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-white shadow-sm ring-1 ring-slate-800/10 dark:ring-slate-800">
                        <component
                            :is="link.icon"
                            class="h-6 w-6 text-primary dark:text-primary-light"
                            aria-hidden="true" />
                    </div>
                    <div class="flex-auto">
                        <h3 class="text-sm font-semibold leading-6 text-slate-900 dark:text-white">
                            <a :href="link.href">
                                <span
                                    class="absolute inset-0"
                                    aria-hidden="true" />
                                {{ link.name }}
                            </a>
                        </h3>
                        <p class="text-sm leading-6 text-slate-600 dark:text-slate-500">
                            {{ link.description }}
                        </p>
                    </div>
                    <div class="flex-none self-center">
                        <ChevronRightIcon
                            class="h-5 w-5 text-slate-400"
                            aria-hidden="true" />
                    </div>
                </li>
            </ul>
            <div class="mt-10 flex justify-center">
                <Button
                    variant="primary"
                    :href="useRoute('home')">
                    <div class="flex items-center gap-2">
                        <span aria-hidden="true">&larr;</span>
                        <span>
                            Back to home
                        </span>
                    </div>
                </Button>
            </div>
        </div>
    </div>
</template>
