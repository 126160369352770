<script setup lang="ts">
import Icon from '@/Components/Icon/index.js'
import Button from '@/Components/UI/Button.vue'
import TabLinks from '@/Components/UI/TabLinks.vue'
import Pagination from '@/Components/UI/Pagination.vue'
import InputText from '@/Components/Input/InputText.vue'
import DashboardLayout from '@/Layouts/DashboardLayout.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import InputToggle from '@/Components/Input/InputToggle.vue'
import InputSelect from '@/Components/Input/InputSelect.vue'
import GameThumbnail from '@/Components/Game/GameThumbnail.vue'
import CommentSingle from '@/Components/Comment/CommentSingle.vue'
import DashboardTitle from '@/Components/Dashboard/DashboardTitle.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import ArticleThumbnail from '@/Components/Article/ArticleThumbnail.vue'
import { Link, router } from '@inertiajs/vue3'
import { type PropType, ref, watch } from 'vue'
import { FireIcon } from '@heroicons/vue/24/solid'
import { useRoute } from '@/Composables/useRoute.ts'
import type { Comment } from '@/Types/Models/Comment'
import { useModelMap } from '@/Composables/useModelMap.ts'
import type { PaginatedItems } from '@/Types/PaginatedItems'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { DocumentTextIcon } from '@heroicons/vue/24/outline'
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
    counts: Object as PropType<{ all: number, articles: number, games: number }>,
    initialType: String,
    initialSearch: String,
    initialIncludeReplies: Boolean,
    comments: Object as PropType<PaginatedItems<Comment>[]>
})

const search = ref(props.initialSearch)
const includeReplies = ref(props.initialIncludeReplies)
const tabs = ref([
    {
        title: 'All',
        key: 'all',
        link: useRoute('dashboard.comments', {
            s: search.value,
            replies: includeReplies.value
        }),
        count: props.counts.all
    },
    {
        title: 'Articles',
        key: 'articles',
        link: useRoute('dashboard.comments', {
            type: 'articles',
            s: search.value,
            replies: includeReplies.value
        }),
        count: props.counts.articles
    },
    {
        title: 'Games',
        key: 'games',
        link: useRoute('dashboard.comments', {
            type: 'games',
            s: search.value,
            replies: includeReplies.value
        }),
        count: props.counts.games
    }
])
const selectedType = ref(tabs.value.find(tab => tab.key === props.initialType) || tabs.value[0])

watch([search, includeReplies, selectedType], () => {
    router.reload({
        data: {
            s: search.value,
            type: selectedType.value.key,
            replies: includeReplies.value
        },
        only: ['comments', 'counts'],
        onSuccess: (data) => {
            tabs.value = tabs.value.map(tab => {
                tab.count = props.counts[tab.key]
                return tab
            })
        }
    })
})

function commentTypeIs (type: string, comment: Comment) {
    return useModelMap(comment.commentable.type) === type
}

defineOptions({
    layout: (h, page) => h(DashboardLayout, {}, () => [page]),
    inheritAttrs: false
})
</script>

<template>
    <div>
        <div class="relative flex w-full flex-col md:flex-row">
            <DashboardTitle
                class="w-full"
                title="Comments"
                :description="usePageProps().meta.description" />
        </div>

        <div
            v-if="comments.meta.total > 0"
            class="flex flex-col justify-between gap-4 lg:flex-row lg:gap-0 xl:items-center">
            <div class="flex w-full flex-col gap-2 lg:w-8/12 lg:flex-col-reverse xl:flex-row xl:items-center">
                <div class="w-full xl:w-1/4">
                    <InputText
                        v-model="search"
                        input-type="search"
                        :clearable="true"
                        icon-classes="w-4 text-slate-900/75 dark:text-white/75 group-focus-within:text-slate-900 dark:group-focus-within:text-white"
                        placeholder="Search...">
                        <template #icon>
                            <MagnifyingGlassIcon />
                        </template>
                    </InputText>
                </div>
                <TabLinks
                    class="hidden md:flex"
                    :tabs="tabs"
                    :current="selectedType.key" />

                <div class="flex w-full md:hidden">
                    <InputSelect
                        v-model="selectedType"
                        class="w-full"
                        :clearable="false"
                        :options="tabs" />
                </div>
            </div>
            <div>
                <InputToggle
                    v-model="includeReplies"
                    label="Include replies" />
            </div>
        </div>

        <div class="mt-8 flex w-full flex-col gap-6 pb-8">
            <div
                v-if="comments.meta.total < 1"
                class="mt-16 flex h-full w-full flex-col items-center justify-center rounded-2xl bg-transparent p-8 text-center lg:p-0">
                <SectionTitle>
                    You haven't made any comments yet.
                </SectionTitle>
                <SectionDescription>
                    Discover articles and engage in discussions.
                </SectionDescription>
                <div class="mt-6">
                    <Button
                        variant="secondary"
                        class="flex"
                        :href="useRoute('article.index')">
                        <div class="flex">
                            <FireIcon class="mr-2 w-4" />
                            Discover more
                        </div>
                    </Button>
                </div>
            </div>

            <div
                v-for="comment in comments.data"
                :key="comment.id">
                <div>
                    <div class="rounded-2xl border bg-white p-3 border-primary/10 dark:bg-slate-800 md:p-5">
                        <div class="flex">
                            <div class="flex flex-col">
                                <Link
                                    class="flex items-start gap-2 text-lg text-slate-900 font-heading hover:text-primary focus:text-primary dark:text-white md:items-center md:text-xl dark:hover:text-primary-light dark:focus:text-primary-light"
                                    :href="comment.link">
                                    <component
                                        :is="commentTypeIs('Article', comment) ? DocumentTextIcon : Icon.GamePad"
                                        class="w-6 shrink-0 mt-0.5 md:mt-0" />
                                    {{ comment.commentable.title ? comment.commentable.title : comment.commentable.name }}
                                </Link>
                                <div class="mt-4">
                                    <CommentSingle
                                        :enable-replies="false"
                                        :actions="false"
                                        :comment="comment" />
                                </div>
                            </div>
                            <div class="ml-auto hidden w-32 md:block">
                                <Link :href="comment.commentable.link">
                                    <ArticleThumbnail
                                        v-if="commentTypeIs('Article', comment)"
                                        :actions="false"
                                        :show-category="false"
                                        class="outline outline-primary/10"
                                        :article="comment.commentable" />
                                    <GameThumbnail
                                        v-if="commentTypeIs('Game', comment)"
                                        :game="comment.commentable"
                                        size="medium" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Pagination
                v-if="comments"
                :links="comments.meta.links" />
        </div>
    </div>
</template>
