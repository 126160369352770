// @ts-ignore
import { Ziggy } from '@/routes.js'
// @ts-ignore
import { useRoute as useZiggyRoute } from 'ziggy-js'

export function useRoute (name: String, params: Object = {}, absolute: boolean = true) {
    const route = useZiggyRoute(Ziggy)

    return route(name, params, absolute)
}
