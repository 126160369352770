import { useIsAuthed } from '@/Composables/useIsAuthed.js'
import { usePageProps } from '@/Composables/usePageProps.ts'

export function useGetSetting (setting) {
    if (!useIsAuthed()) {
        return null
    }

    return usePageProps().auth?.settings[setting]
}
