<script setup>
import Button from '@/Components/UI/Button.vue'
import useEmitter from '@/Composables/useEmitter'
import TabLinks from '@/Components/UI/TabLinks.vue'
import Pagination from '@/Components/UI/Pagination.vue'
import InputText from '@/Components/Input/InputText.vue'
import DashboardLayout from '@/Layouts/DashboardLayout.vue'
import ConfirmModal from '@/Components/Global/ConfirmModal.vue'
import DashboardTitle from '@/Components/Dashboard/DashboardTitle.vue'
import NotificationSingle from '@/Components/Notification/NotificationSingle.vue'
import { ref } from 'vue'
import { router } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
import { useCurrentUrl } from '@/Composables/useCurrentUrl.js'
import { useMakeRequest } from '@/Composables/useMakeRequest.js'
import { useUrlSearchParams, watchDebounced } from '@vueuse/core'
import { useAnalyticsEvent } from '@/Composables/useAnalyticsEvent.js'

const props = defineProps({
    notifications: Object,
    status: [String, null],
    unreadCount: Number,
    readCount: Number
})

const params = useUrlSearchParams('history')
const search = ref(params.q)
const unreadCountNumber = ref(props.unreadCount)
const readCountNumber = ref(props.readCount)
// let displayedDates = []

const tabs = ref([
    {
        title: 'All',
        key: null,
        link: useRoute('dashboard.user.notifications')
    },
    {
        title: 'Unread',
        count: unreadCountNumber.value,
        key: 'unread',
        link: useRoute('dashboard.user.notifications', { status: 'unread' })
    },
    {
        title: 'Read',
        count: readCountNumber.value,
        key: 'read',
        link: useRoute('dashboard.user.notifications', { status: 'read' })
    }
])

watchDebounced(
    search, () => {
        handleSearch(search.value)
    },
    {
        debounce: 500,
        maxWait: 1000
    }
)

function markAllAsRead () {
    router.post(useRoute('api.notifications.read'), {}, {
        preserveScroll: true,
        preserveState: true,
        onSuccess: () => {
            router.reload()
            reloadCount()
            // displayedDates = []

            useAnalyticsEvent('Mark all notifications as read')
        }
    })
}

function markAllAsUnread () {
    router.post(useRoute('api.notifications.unread'), {}, {
        preserveScroll: true,
        preserveState: true,
        onSuccess: () => {
            router.reload()
            reloadCount()
            // displayedDates = []

            useAnalyticsEvent('Mark all notifications as unread')
        }
    })
}

function handleSearch (value) {
    router.get(
        useCurrentUrl(),
        { q: value },
        {
            preserveState: true,
            replace: true,
            onSuccess: () => reloadCount()
        }
    )
}

// function displayDate (notification) {
//     const date = useDateFormat(notification.created_at, 'DD  MMMM YYYY').value
//     if (!displayedDates.includes(date)) {
//         displayedDates.push(date)
//         return date
//     }
// }

function reloadCount () {
    useMakeRequest(useRoute('api.notifications.count'), 'GET').then(response => {
        const count = response.unreadCount
        const unreadTab = tabs.value.filter(tab => tab.key === 'unread')[0]
        unreadCountNumber.value = count
        unreadTab.count = count
    })
    useMakeRequest(useRoute('api.notifications.count'), 'GET').then(response => {
        const count = response.readCount
        const readTab = tabs.value.filter(tab => tab.key === 'read')[0]
        readCountNumber.value = count
        readTab.count = count
    })

    useEmitter.emit('clearNotifications')
}

function deleteAll () {
    router.delete(useRoute('api.notifications.destroy'), {}, {
        preserveScroll: true,
        preserveState: true,
        onSuccess: () => {
            router.reload()
            reloadCount()
            useAnalyticsEvent('Delete all notifications')
        }
    })
}

defineOptions({
    layout: DashboardLayout
})
</script>

<template>
    <div>
        <div class="relative flex w-full flex-col md:flex-row">
            <DashboardTitle
                class="w-full"
                title="Notifications" />
            <div
                v-if="unreadCount > 0 || readCount > 0"
                class="absolute top-3 right-0 mx-auto -translate-y-1/2">
                <Button
                    v-if="unreadCount > 0"
                    variant="primary"
                    @click="markAllAsRead">
                    Mark all as read
                </Button>
                <Button
                    v-else
                    variant="primary"
                    @click="markAllAsUnread">
                    Mark all as unread
                </Button>

                <ConfirmModal @confirmed="deleteAll">
                    <Button
                        class="ml-4"
                        variant="danger-light">
                        Delete all
                    </Button>
                </ConfirmModal>
            </div>
        </div>
        <div class="mt-8 pb-12">
            <div
                v-if="unreadCount > 0 || readCount > 0"
                class="flex flex-col items-center justify-between gap-y-4 md:flex-row">
                <TabLinks
                    :border="false"
                    :current="status"
                    :tabs="tabs" />
                <div class="w-full md:w-3/12">
                    <InputText
                        v-model="search"
                        input-type="search"
                        :clearable="true"
                        icon-classes="w-4 text-slate-900/75 dark:text-white/75 group-focus-within:text-slate-900 dark:group-focus-within:text-white"
                        placeholder="Search...">
                        <template #icon>
                            <MagnifyingGlassIcon />
                        </template>
                    </InputText>
                </div>
            </div>
            <div
                v-if="notifications.total"
                class="mt-8">
                <div
                    v-for="notification in notifications.data"
                    :key="`${notification.id}${notification.read_at}`">
                    <NotificationSingle
                        :notification="notification"
                        @unread="reloadCount"
                        @read="reloadCount" />
                </div>

                <Pagination
                    v-if="notifications"
                    class="mt-8"
                    :links="notifications.links" />
            </div>
            <div
                v-else
                class="mt-8">
                <div class="my-32 text-center text-sm">
                    You don't have any notifications yet.
                </div>
            </div>
        </div>
    </div>
</template>
