<script setup>

import ErrorText from '@/Components/UI/ErrorText.vue'
import { ref } from 'vue'

defineProps({
    modelValue: String,
    label: String,
    error: {
        type: [String, null],
        default: null
    },
    hasAutofocus: {
        type: Boolean,
        default: false
    },
    placeholder: {
        type: String,
        default: ''
    },
    inputClass: {
        type: String,
        default: ''
    },
    inputType: {
        type: String,
        default: 'text'
    },
    clearable: {
        type: Boolean,
        default: true
    }
})
const emit = defineEmits(['update:modelValue', 'enter', 'focus', 'blur', 'paste'])

const id = ref('article-subtitle')
const textarea = ref(null)
const textareaHeight = ref(32)

function updateValue (event) {
    emit('update:modelValue', event.target.value)

    textarea.value.style.height = textareaHeight.value + 'px'
    textarea.value.style.height = textarea.value.scrollHeight + 'px'
}
</script>

<template>
    <div class="relative">
        <label
            v-if="!modelValue"
            :for="id"
            class="pointer-events-none absolute top-0 left-0 select-none text-xl font-semibold text-slate-900 opacity-50 dark:text-white md:text-2xl">
            {{ label }}
        </label>
        <textarea
            :id="id"
            ref="textarea"
            :class="inputClass"
            :placeholder="placeholder"
            :style="`height:${textareaHeight}px;`"
            :value="modelValue"
            class="h-full w-full resize-none bg-transparent p-0 text-2xl font-semibold leading-tighter focus:outline-none md:text-balance"
            @blur="emit('blur')"
            @focus="emit('focus')"
            @input="updateValue"
            @paste="emit('paste', $event)" />
        <ErrorText
            v-if="error"
            :error="error" />
    </div>
</template>
