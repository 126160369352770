import { useMakeRequest } from '@/Composables/useMakeRequest.js'

export function usePost (options) {
    useMakeRequest(options.url, options.method ? options.method : 'POST', options.data ? options.data : {}).then(response => {
        if (response.error) {
            if (response.error === 403 && typeof options.onUnauthorized !== 'undefined') {
                options.onUnauthorized(response)
            }

            if (typeof options.onError !== 'undefined') {
                options.onError(response)
            }
        } else {
            if (typeof options.onSuccess !== 'undefined') {
                options.onSuccess(response)
            }
        }
    })
}
