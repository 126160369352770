<script setup lang="ts">
defineProps({
    title: String,
    number: Number,
    subtitle: [String, null]
})

function formatNumber (number: number) {
    return new Intl.NumberFormat().format(number)
}
</script>

<template>
    <div class="relative flex w-1/2 flex-col border-white py-4 md:w-full md:py-0">
        <div class="z-10 flex w-full flex-col">
            <h3 class="text-sm text-slate-900/75 dark:text-white">
                {{ title }}
            </h3>
            <h2 class="mt-2 text-xl font-bold font-heading md:text-4xl">
                {{ formatNumber(number) }}
            </h2>
            <h3
                v-if="subtitle"
                class="text-xs text-slate-900/50">
                {{ subtitle }}
            </h3>
        </div>
    </div>
</template>
