<script setup>

import ErrorText from '@/Components/UI/ErrorText.vue'
import InputLabel from '@/Components/Input/InputLabel.vue'
import { computed, onMounted, ref } from 'vue'

const props = defineProps({
    modelValue: String,
    label: String,
    error: {
        type: [String, null],
        default: null
    },
    autoheight: {
        type: Boolean,
        default: false
    },
    hasAutofocus: {
        type: Boolean,
        default: false
    },
    placeholder: {
        type: String,
        default: ''
    },
    max: {
        type: [Number, null],
        default: null
    },
    inputClass: {
        type: String,
        default: ''
    },
    inputType: {
        type: String,
        default: 'text'
    },
    clearable: {
        type: Boolean,
        default: true
    }
})
const emit = defineEmits(['update:modelValue', 'enter', 'focus', 'blur', 'paste'])

const textareaHeight = ref(100)
const input = ref(null)
const id = ref('')

// function clear () {
//     input.value.value = null
//     input.value.focus()
//     emit('update:modelValue', '')
// }

function updateValue (event) {
    emit('update:modelValue', event.target.value)

    if (props.autoheight) {
        input.value.style.height = textareaHeight.value + 2 + 'px'
        input.value.style.height = input.value.scrollHeight + 'px'
    }
}

function getPercentage (number, percentage) {
    return ((percentage / 100) * number).toFixed(0)
}

const counterColour = computed(() => {
    if (props.max) {
        const currentLength = props.modelValue.length
        const max = props.max

        const eightyPercent = getPercentage(max, 80)
        const ninetyPercent = getPercentage(max, 90)

        if (currentLength >= eightyPercent && currentLength < ninetyPercent) {
            return 'text-orange-400/75'
        }

        if (currentLength >= ninetyPercent) {
            return 'text-red-500/75'
        }
        return 'text-slate-900/75'
    }
    return 'text-slate-900/75'
})

onMounted(() => {
    id.value = 'input' + (Math.random() + 1).toString(36).substring(7)
    if (props.hasAutofocus) {
        input.value.focus()
    }
})
</script>

<template>
    <div>
        <InputLabel
            v-if="label"
            :for-input="id">
            {{ label }}
        </InputLabel>
        <textarea
            :id="id"
            ref="input"
            :class="[inputClass, autoheight ? '' : 'h-full']"
            :maxlength="max"
            :placeholder="placeholder"
            :style="autoheight ? `height:${textareaHeight}px;` : null"
            :value="modelValue"
            class="w-full resize-none rounded-md border px-3 py-2 text-sm transition-colors border-secondary/10 focus:border-primary focus:outline-none dark:bg-slate-800"
            @blur="emit('blur')"
            @focus="emit('focus')"
            @input="updateValue"
            @paste="emit('paste', $event)"
            @keydown.enter.meta="$emit('enter', $event.target.value)" />
        <div
            v-if="max"
            class="flex justify-end mr-0.5">
            <p
                :class="counterColour"
                class="text-xs font-semibold dark:text-white">
                {{ modelValue.length }}/{{ max }}
            </p>
        </div>
        <ErrorText
            v-if="error"
            :error="error" />
    </div>
</template>
