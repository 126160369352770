<script setup lang="ts">
import { type PropType } from 'vue'
import { Link } from '@inertiajs/vue3'
import type { Genre } from '@/Types/Models/Genre'
import { useRoute } from '@/Composables/useRoute.ts'

defineProps({
    genres: Array as PropType<Genre[]>
})

function randomInt (min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min
}
</script>

<template>
    <div class="flex w-full gap-2 text-sm">
        <ul
            v-if="!genres"
            class="flex w-full flex-wrap gap-2 pt-1 skeleton-animation min-w-18">
            <li
                v-for="i in 2"
                :key="i"
                :style="`width:${randomInt(30, 65)}px`"
                class="flex w-full items-center">
                <div
                    class="h-3 w-full rounded-full bg-slate-200 dark:bg-slate-800"
                />
            </li>
        </ul>
        <ul
            v-if="genres && genres.length"
            class="flex flex-wrap gap-2 text-sm">
            <li
                v-for="genre in genres"
                :key="genre.identifier">
                <Link
                    class="hover:underline"
                    :href="useRoute('genres.show', genre.slug)">
                    {{ genre.name }}
                </Link>
            </li>
        </ul>
        <span
            v-if="genres && !genres.length"
            class="italic text-slate-900/75">
            None
        </span>
    </div>
</template>
