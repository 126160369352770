<script setup>
import AccordionTransition from '@/Components/Transition/AccordionTransition.vue'
import { ref } from 'vue'
import { Link } from '@inertiajs/vue3'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'

const emit = defineEmits(['clicked'])

defineProps({
    item: Object
})

const open = ref(false)

function handleClick () {
    emit('clicked')
}
</script>

<template>
    <li
        v-if="(typeof item.permission === 'undefined' || item.permission === true)"
        class="text-2xl text-slate-900 dark:text-white">
        <component
            :is="item.url ? Link : 'button'"
            :href="item.url"
            class="flex flex-col"
            @click="item.url ? handleClick() : open = !open">
            <div class="flex items-center">
                <component
                    :is="item.icon"
                    v-if="item.icon && ['object', 'function'].includes(typeof item.icon)"
                    class="mr-2 w-5" />
                {{ item.title }}
                <ChevronRightIcon
                    v-if="item.children"
                    :class="open ? 'rotate-90' : 'rotate-0'"
                    class="ml-1 w-6 transition-all" />
            </div>
        </component>
        <AccordionTransition>
            <div
                v-if="open && item.children"
                class="flex flex-col pl-8">
                <template v-for="(child, i) in item.children">
                    <li
                        v-if="typeof child.permission === 'undefined' || child.permission === true"
                        :key="i"
                        :style="{'--i': i}"
                        class="mt-2">
                        <Link
                            :as="['delete', 'post', 'path'].includes(child.method) ? 'button' : 'a'"
                            :href="child.url"
                            :method="child.method"
                            class="flex w-full items-center text-lg"
                            @click="handleClick">
                            <component
                                :is="child.icon"
                                v-if="child.icon && ['object', 'function'].includes(typeof child.icon)"
                                class="mr-2 w-5" />
                            {{ child.title }}
                            <component
                                :is="child.icon"
                                v-if="child.icon && typeof child.icon === 'object'"
                                class="ml-1 w-4" />
                        </Link>
                    </li>
                </template>
            </div>
        </AccordionTransition>
    </li>
</template>
