<script lang="ts" setup>
import wink from '~/novus/wink.svg'
import happy from '~/novus/happy.svg'
import tired from '~/novus/tired.svg'
import love1 from '~/novus/in-love-1.svg'
import love2 from '~/novus/in-love-2.svg'
import bashful from '~/novus/bashful.svg'
import excited from '~/novus/excited.svg'
import facepalm from '~/novus/facepalm.svg'
import confused1 from '~/novus/confused-1.svg'
import confused2 from '~/novus/confused-2.svg'
import type { PropType } from 'vue'
import { useImageOptimise } from '@/Composables/useImageOptimise'

const emotions = {
    wink,
    happy,
    tired,
    love1,
    love2,
    bashful,
    excited,
    facepalm,
    confused1,
    confused2
}

defineProps({
    emotion: {
        type: String as PropType<keyof typeof emotions>,
        default: 'happy'
    }
})
</script>

<template>
    <img
        class="drop-shadow"
        :alt="`Novus: ${emotion}`"
        :src="useImageOptimise(emotions[emotion])">
</template>
