/* eslint-disable no-unused-vars */
export enum NotificationType {
    SUCCESS = 'success',
    FAIL = 'fail',
    WARNING = 'warning',
    COMMENT = 'comment',
    ARTICLE = 'article',
    LIKE = 'like',
    POST = 'post',
    ACHIEVEMENT = 'achievement',
}
/* eslint-disable no-unused-vars */
