<script setup lang="ts">
import Icon from '@/Components/Icon/index.js'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import { useSlots } from 'vue'

defineProps({
    icon: {
        type: [Object, String],
        default: Icon.EmptyBox
    },
    iconWidth: Number,
    iconHeight: Number,
    title: {
        type: String,
        default: 'There\'s nothing here.'
    },
    description: {
        type: String,
        default: 'There are no published articles here.'
    }
})

const slots = useSlots()
</script>

<template>
    <div class="flex flex-col items-center justify-center py-12">
        <div class="text-black/50 dark:text-white/50">
            <component
                :is="icon"
                v-if="typeof icon !== 'string'"
                secondary-color="text-primary"
                class="w-48 text-slate-300" />
            <img
                v-else
                :src="icon"
                :alt="title"
                class="w-24">
        </div>
        <SectionTitle
            v-if="title"
            class="mt-4">
            {{ title }}
        </SectionTitle>
        <div
            v-if="description"
            class="mx-auto max-w-sm text-center">
            <SectionDescription v-html="description" />
        </div>
        <div
            v-if="slots.button"
            class="mt-4">
            <slot name="button" />
        </div>
    </div>
</template>
