<script setup>
import Logo from '@/Components/Logo'
import BaseLayout from '@/Layouts/BaseLayout.vue'
import { ref } from 'vue'
import { Link } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { useCurrentUrl } from '../Composables/useCurrentUrl.js'

const nav = ref([
    {
        url: useRoute('login'),
        title: 'Login'
    },
    {
        url: useRoute('register'),
        title: 'Create an account'
    }
])
</script>

<template>
    <BaseLayout
        :navbar="false"
        :footer="false">
        <div class="flex grow flex-col justify-stretch">
            <div class="flex h-full grow justify-start p-4 md:flex-row md:justify-center">
                <div
                    class="relative mt-8 hidden w-full overflow-hidden rounded-xl bg-gradient-to-r bg-cover bg-center p-8 bg-primary from-primary to-pink dark:from-slate-700 dark:to-slate-900 md:mt-0 md:flex md:w-1/3">
                    <div class="w-full">
                        <nav class="flex w-full items-center justify-between">
                            <Link :href="useRoute('home')">
                                <span class="sr-only">Link to home</span>
                                <Logo.FullWhite
                                    class="flex w-44 sm:w-40 lg:w-56"
                                />
                            </Link>
                            <!--                            <div class="flex gap-4">-->
                            <!--                                <Link-->
                            <!--                                    v-for="(item, i) in nav"-->
                            <!--                                    :key="i"-->
                            <!--                                    class="relative font-semibold text-white"-->
                            <!--                                    :href="item.url">-->
                            <!--                                    {{ item.title }}-->
                            <!--                                    <span-->
                            <!--                                        :class="useCurrentUrl() === item.url ? 'w-full' : 'w-0'"-->
                            <!--                                        class="absolute bottom-0 left-1/2 -translate-x-1/2 rounded-full bg-white transition-all h-0.5 dark:bg-slate-200" />-->
                            <!--                                </Link>-->
                            <!--                            </div>-->
                        </nav>
                    </div>
                    <!--                    <div class="absolute -bottom-px left-1/2 z-20 flex h-full -translate-x-1/2 flex-col justify-end lg:w-9/12">-->
                    <!--                        <img-->
                    <!--                            :src="useImageOptimise(HeroImage)"-->
                    <!--                            fetchpriority="high"-->
                    <!--                            width="723"-->
                    <!--                            height="615"-->
                    <!--                            class="z-20 hidden w-full max-w-xl lg:flex xl:w-auto xl:max-w-none lg:dark:hidden"-->
                    <!--                            alt="Article editor screenshot (desktop)">-->
                    <!--                    </div>-->
                    <Logo.MarkWhite
                        class="absolute right-0 -bottom-56 translate-x-1/3 opacity-50 h-128 dark:opacity-5 md:h-[40rem]" />
                    <!--                    <Logo.MarkWhite-->
                    <!--                        class="absolute bottom-0 left-0 hidden w-44 -translate-x-4 translate-y-1/4 opacity-20 dark:opacity-5 md:block" />-->
                </div>
                <div class="flex w-full flex-col items-center md:w-2/3">
                    <nav class="mt-2 flex w-full items-center justify-between pl-6">
                        <Link
                            :href="useRoute('home')"
                            class="flex items-center pt-1">
                            <span class="sr-only">Link to home</span>
                            <Logo.FullColour
                                class="hidden w-44 md:hidden min-[500px]:flex"
                            />
                            <Logo.MarkColour
                                class="flex w-10 min-[500px]:hidden"
                            />
                        </Link>
                        <div class="flex items-center gap-3">
                            <Link
                                v-for="(item, i) in nav"
                                :key="i"
                                class="after:rounded-full rounded-full px-4 button py-1.5"
                                :class="useCurrentUrl() === item.url ? 'button-primary hover:after:opacity-10' : 'button-white hover:after:opacity-5'"
                                :href="item.url">
                                {{ item.title }}
                            </Link>
                        </div>
                    </nav>
                    <div class="w-full flex-grow justify-center md:items-center">
                        <div class="relative mx-auto flex h-full w-full max-w-md items-center px-4 py-4">
                            <slot />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </BaseLayout>
</template>
