<script setup lang="ts">
import NumberStat from '@/Components/UI/NumberStat.vue'
import ViewGraph from '@/Components/Global/ViewGraph.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import DashboardLayout from '@/Layouts/DashboardLayout.vue'
import ArticleCard from '@/Components/Article/ArticleCard.vue'
import DashboardTitle from '@/Components/Dashboard/DashboardTitle.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import CreateArticleLink from '@/Components/Article/CreateArticleLink.vue'
import DashboardWelcomeBanner from '@/Components/Dashboard/DashboardWelcomeBanner.vue'
import type { PropType } from 'vue'
import type { User } from '@/Types/Models/User'
import type { Article } from '@/Types/Models/Article'

defineProps({
    user: Object as PropType<User>,
    topArticles: Array as PropType<Array<Article>>,
    topComments: Array as PropType<Array<Comment>>,
    articlesCount: Number,
    joinedAt: [String, null],
    verifiedAt: [String, null],
    commentsCount: Number,
    weeksViews: Object,
    weeksViewsCount: Number,
    viewsCount: Number,
    followersCount: Number
})

function formatNumber (number: number) {
    return new Intl.NumberFormat().format(number)
}

defineOptions({ layout: DashboardLayout })
</script>
<template>
    <div class="pb-12">
        <DashboardWelcomeBanner />

        <DashboardTitle
            :title="`Hi ${user.name}`"
            description="Welcome to your dashboard." />

        <div class="flex flex-col">
            <div
                class="flex w-full flex-wrap items-start overflow-hidden text-slate-900 dark:text-white md:flex-nowrap">
                <NumberStat
                    :number="articlesCount"
                    title="Published articles" />
                <NumberStat
                    :number="commentsCount"
                    title="Total comments" />
                <NumberStat
                    :number="followersCount"
                    title="Total followers" />
                <NumberStat
                    :number="viewsCount"
                    :subtitle="weeksViewsCount === 0 ? null : `${formatNumber(weeksViewsCount)} this week`"
                    title="Article views" />
            </div>

            <div class="mt-12">
                <SectionTitle>
                    Top articles
                </SectionTitle>
                <SectionDescription>
                    Your most popular articles based on views, likes, and engagement.
                </SectionDescription>
                <div class="flex w-full flex-wrap">
                    <div
                        class="-mx-4 flex w-full flex-wrap">
                        <div
                            v-for="article in topArticles"
                            :key="article.uuid"
                            class="flex w-full p-4 sm:w-1/2 lg:w-1/4">
                            <ArticleCard
                                :article="article"
                                class="w-full" />
                        </div>
                        <div
                            class="flex w-full p-4 sm:w-1/2 lg:w-1/4">
                            <CreateArticleLink :text="topArticles.length > 0 ? 'Get started on a new article' : 'Create your first article today'" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-12">
                <div class="mt-4 flex flex-col gap-4 md:flex-row md:gap-4">
                    <!--                    <div class="w-full md:w-1/3">-->
                    <!--                        <SectionTitle>-->
                    <!--                            Stats-->
                    <!--                        </SectionTitle>-->
                    <!--                        <SectionDescription>-->
                    <!--                            Daily, monthly, and yearly stats for your articles.-->
                    <!--                        </SectionDescription>-->
                    <!--                        <div class="mt-4 w-full rounded-2xl border bg-white py-8 text-center font-heading border-primary/10 dark:bg-slate-800">-->
                    <!--                            Coming soon-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                    <div class="w-full md:w-3/3">
                        <SectionTitle>
                            Views
                        </SectionTitle>
                        <SectionDescription>
                            Your article views for the past 7 days
                        </SectionDescription>
                        <div class="h-80">
                            <ViewGraph
                                :show-labels="true"
                                :view-data="weeksViews"
                                class="h-full" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
