<script setup>

import Button from '@/Components/UI/Button.vue'
import InputText from '@/Components/Input/InputText.vue'
import { ref, watch } from 'vue'
import { GlobeAltIcon, TagIcon, TrashIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
    modelValue: Array,
    errors: Object,
    limit: Number
})

const profileLinks = ref(props.modelValue)
const emit = defineEmits(['update:modelValue'])

function addProfileLink () {
    profileLinks.value.push({
        url: null,
        label: null
    })
}

function removeProfileLink (index) {
    profileLinks.value.splice(index, 1)
}

watch(profileLinks, async (newValue, oldValue) => {
    emit('update:modelValue', newValue)
})
</script>

<template>
    <div class="flex flex-col">
        <div
            class="hidden items-center"
            :class="profileLinks.length === 0 ? 'sm:hidden' : 'sm:flex'">
            <div class="mr-2 w-1/2 select-none text-sm text-slate-900/75 dark:text-white/75">
                URL
            </div>
            <div class="w-1/2 select-none text-sm text-slate-900/75 dark:text-white/75">
                Label
            </div>
            <div class="flex w-8" />
        </div>
        <div class="mt-2 flex flex-col gap-4">
            <div
                v-for="(profile_link, i) in profileLinks"
                :key="i"
                class="flex flex-col items-center gap-y-2 sm:flex-row sm:gap-y-0">
                <div class="w-full sm:mr-2 sm:w-1/2">
                    <InputText
                        v-model="profileLinks[i].url"
                        :clearable="true"
                        :error="errors['metadata.profile_links.'+i+'.url']"
                        icon-classes="w-5 text-slate-900/75 dark:text-white/75 dark:group-focus-within:text-white group-focus-within:text-slate-900">
                        <template #icon>
                            <GlobeAltIcon />
                        </template>
                    </InputText>
                </div>
                <div class="w-full sm:mx-2 sm:w-1/2">
                    <InputText
                        v-model="profileLinks[i].label"
                        :clearable="true"
                        :error="errors['metadata.profile_links.'+i+'.label']"
                        icon-classes="w-5 text-slate-900/50 group-focus-within:text-slate-900 dark:text-white/50 dark:group-focus-within:text-white">
                        <template #icon>
                            <TagIcon />
                        </template>
                    </InputText>
                </div>
                <div class="flex w-full items-center justify-center sm:w-8">
                    <Button
                        v-tooltip="`Remove`"
                        class="block w-full py-0 sm:hidden"
                        type="button"
                        variant="danger-light"
                        @click="removeProfileLink(i)">
                        <TrashIcon class="w-4" />
                    </Button>
                    <button
                        v-tooltip="`Remove`"
                        class="hidden hover:text-red-600 focus:text-red-500 focus:outline-none sm:flex"
                        type="button"
                        @click="removeProfileLink(i)">
                        <TrashIcon class="w-4" />
                    </button>
                </div>
            </div>
            <Button
                v-if="profileLinks.length < limit"
                :disabled="profileLinks.length >= limit"
                type="button"
                variant="primary-light"
                @click="addProfileLink">
                + Link
            </Button>
        </div>
    </div>
</template>
