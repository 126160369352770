<script setup lang="ts">
import HeaderSection from '@/Pages/About/HeaderSection.vue'
import WritingSection from '@/Pages/About/WritingSection.vue'
import HistorySection from '@/Pages/About/HistorySection.vue'
import MetricsSection from '@/Pages/About/MetricsSection.vue'
import FeaturesSection from '@/Pages/About/FeaturesSection.vue'
import ContactUsSection from '@/Pages/About/ContactUsSection.vue'
import ScreenshotsSection from '@/Pages/About/ScreenshotsSection.vue'

defineProps({
    header: Object,
    contactEmail: [String, null],
    content: [String, null],
    stats: Object
})

const sections = [
    {
        component: HistorySection
    },
    {
        component: FeaturesSection
    },
    {
        component: ScreenshotsSection
    },
    {
        component: MetricsSection
    },
    {
        component: ContactUsSection
    },
    {
        component: WritingSection
    }
]

defineOptions({
    inheritAttrs: false
})
</script>

<template>
    <article class="overflow-hidden bg-white dark:bg-slate-900">
        <HeaderSection />

        <div
            v-for="(section, index) in sections"
            :key="index"
            class="odd:bg-background even:bg-white dark:odd:bg-slate-800 dark:even:bg-slate-900">
            <section
                class="relative w-full"
                :class="{'py-12 md:py-24 lg:py-32': section.component !== WritingSection}">
                <component
                    :is="section.component"
                    :stats="section.component === MetricsSection ? stats : null"
                    v-bind="section" />
            </section>
        </div>
    </article>
</template>
