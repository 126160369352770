<script setup>
import Icon from '@/Components/Icon'
import { ref } from 'vue'
import { useContrast } from '@/Composables/useContrast'
import { useHexToRgb } from '@/Composables/useHexToRgb.js'

const props = defineProps({
    modelValue: Object,
    error: {
        type: [String, null],
        default: null
    },
    categories: Array
})
const emit = defineEmits(['update:modelValue', 'enter', 'focus', 'blur', 'paste'])

const selected = ref(props.modelValue)

function updateValue (category) {
    selected.value = category
    emit('update:modelValue', category)
}

function colours (category) {
    const colour = category.metadata?.colour ?? 'var(--colors-primary)'
    return {
        text: useContrast(colour, 'text-slate-900', 'text-white'),
        background: `background-color:rgba(${useHexToRgb(colour)}, var(--tw-bg-opacity));`
    }
}

function isSelected (category) {
    return category.id === (selected.value !== null && selected.value.id)
}

// function clear () {
//     selected.value = null
//     emit('update:modelValue', null)
// }
</script>

<template>
    <div class="flex flex-col gap-4 md:flex-row md:items-center">
        <label class="block text-left text-sm text-slate-900/75 dark:text-white">
            Category
        </label>
        <div class="flex gap-4">
            <button
                v-for="category in categories"
                :key="category.id"
                :class="[isSelected(category) ? ('opacity-100 bg-opacity-100 ' + colours(category).text) : 'bg-secondary/30 dark:bg-white/30 text-white']"
                :style="isSelected(category) ? colours(category).background : ''"
                class="flex select-none items-center rounded-full py-1 text-xs px-2.5 space-x-2"
                type="button"
                @click="updateValue(category)">
                <img
                    v-if="category.icon"
                    :alt="`Icon for ${category.singular_title}`"
                    :src="useContrast(category.metadata.colour ?? 'var(--colors-tertiary)', category.icon_dark, category.icon)"
                    class="hidden xs:flex"
                    height="14"
                    width="14">
                <span>{{ category.title }}</span>
                <Icon.Checkmark
                    v-if="isSelected(category)"
                    class="ml-2 w-4" />
            </button>
        </div>
    </div>
</template>
