<script setup>
import Logo from '@/Components/Logo'
import AdContainer from '@/Components/Global/AdContainer.vue'
import NewsletterForm from '@/Components/Global/NewsletterForm.vue'
import { Link } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { useCurrentUrl } from '@/Composables/useCurrentUrl.js'

const columns = [
    {
        title: 'Categories',
        children: usePageProps().app.categories.map(category => {
            return {
                title: category.title,
                url: useRoute('article.categorised', category)
            }
        })
    },
    {
        title: 'Discover',
        children: [
            {
                url: useRoute('games.index'),
                title: 'Games'
            },
            {
                url: useRoute('search.index'),
                title: 'Search'
            },
            {
                url: useRoute('rss.index'),
                title: 'RSS Feeds'
            },
            // {
            //     url: useRoute('users.top'),
            //     title: 'Top users'
            // },
            {
                url: useRoute('article.index'),
                title: 'Latest gaming articles'
            }
            // {
            //     url: useRoute('article.trending'),
            //     title: 'Trending articles',
            //     if: usePageProps().totalArticleCount > 12
            // }
        ]
    },
    {
        title: 'Follow us',
        children: [
            {
                url: usePageProps().config.social_media.facebook.url,
                title: 'Facebook',
                target: '_blank'
            },
            {
                url: usePageProps().config.social_media.instagram.url,
                title: 'Instagram',
                target: '_blank'
            },
            {
                url: usePageProps().config.social_media.twitter.url,
                title: 'Twitter',
                target: '_blank'
            },
            {
                url: usePageProps().config.social_media.discord.url,
                title: 'Discord',
                target: '_blank'
            }
        ]
    },
    {
        title: 'Company',
        children: [
            {
                url: 'https://status.' + usePageProps().app.domain,
                title: 'Status',
                target: '_blank'
            },
            {
                url: useRoute('privacy'),
                title: 'Privacy'
            },
            {
                url: useRoute('about-us'),
                title: 'About us'
            },
            {
                url: useRoute('contact'),
                title: 'Contact us'
            },
            {
                url: useRoute('terms'),
                title: 'Terms & Conditions'
            }
        ]
    }
]

const disabledAds = [
    useRoute('about-us')
]
</script>

<template>
    <div>
        <AdContainer
            v-if="!disabledAds.includes(useCurrentUrl())"
            ad-slot="aboveFooter"
            class="mb-4 px-4" />
        <footer class="mt-auto pt-24 text-white bg-secondary dark:bg-slate-950">
            <div class="container">
                <h1 class="flex w-full">
                    <span class="sr-only">{{ usePageProps().app.name }} logo</span>
                    <Link :href="useRoute('home')">
                        <span class="sr-only">Link to homepage</span>
                        <Logo.FullLight
                            class="w-full" />
                    </Link>
                </h1>
                <div class="mt-8 flex w-full flex-col md:flex-row">
                    <div class="flex w-full flex-col pr-8 md:w-2/3 lg:w-1/3 lg:pr-24">
                        <NewsletterForm class="mt-0" />
                    </div>
                    <div class="mt-20 flex w-full flex-wrap items-start md:mt-0 md:w-1/2 lg:w-2/3">
                        <div
                            v-for="column in columns"
                            :key="column.title"
                            class="mb-8 flex w-1/2 flex-col md:mb-16 lg:mb-0 lg:w-1/4">
                            <h2 class="text-xl font-heading">
                                {{ column.title }}
                            </h2>
                            <ul class="mt-2 flex flex-col space-y-2.5">
                                <li
                                    v-for="item in column.children.filter(item => typeof item.if === 'undefined' || item.if === true)"
                                    :key="item.title"
                                    class="font-light">
                                    <component
                                        :is="item.target ? 'a' : Link"
                                        :href="item.url"
                                        :target="item.target"
                                        :rel="item.target ? 'nofollow' : null"
                                        class="hover:underline">
                                        {{ item.title }}
                                    </component>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="pb-8 text-sm text-white/75 md:pt-20">
                    &copy; {{ new Date().getFullYear() }} {{ usePageProps().app.name }}. All Rights Reserved.
                </div>
            </div>
        </footer>
    </div>
</template>
