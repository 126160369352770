<script setup lang="ts">
import Icon from '@/Components/Icon/index.js'
import { computed } from 'vue'

const emit = defineEmits(['update:modelValue'])

const isChecked = computed(() => {
    return (typeof proxyChecked.value === 'object' ? proxyChecked.value.includes(props.value) : proxyChecked.value === props.value)
})

const props = defineProps({
    modelValue: {
        type: [Array, Boolean],
        default: false
    },
    value: { type: [Boolean, Object, String, Number] },
    label: {
        type: String,
        default: null
    },
    disabled: { type: [Boolean], default: false }
})

const proxyChecked = computed({
    get () {
        return props.modelValue
    },

    set (value) {
        emit('update:modelValue', value)
    }
})
</script>

<template>
    <div>
        <div
            class="flex text-left md:items-center"
            :class="disabled ? 'pointer-events-none opacity-50 dark:opacity-75' : ''">
            <label
                :class="isChecked ? 'bg-slate-900 dark:bg-white dark:text-slate-900 text-white dark:hover:bg-slate-200' : 'dark:hover:bg-slate-800 text-slate-900/75 dark:text-white/75 dark:bg-white/10 bg-slate-900/5 hover:bg-slate-900/10'"
                class="flex cursor-pointer items-center gap-2 rounded-full pl-2 group py-1.5 pr-2.5">
                <input
                    v-model="proxyChecked"
                    :value="value"
                    class="hidden"
                    type="checkbox">
                <span
                    :class="isChecked ? 'bg-white ring-white dark:ring-slate-900/25' : 'dark:ring-white/25 ring-slate-900/25'"
                    class="relative flex aspect-square h-4 w-4 shrink-0 items-center justify-center rounded-full ring-1">
                    <Icon.Checkmark
                        class="absolute top-1/2 left-1/2 w-3 -translate-x-1/2 -translate-y-1/2 text-slate-900"
                        :class="isChecked ? 'group-hover:opacity-0' : 'opacity-0 group-hover:opacity-50'"
                    />
                    <Icon.ThickX
                        v-if="isChecked"
                        class="absolute top-1/2 left-1/2 w-3 -translate-x-1/2 -translate-y-1/2 text-slate-900"
                        :class="!isChecked ? '' : 'opacity-0 group-hover:opacity-50'" />
                </span>
                <span
                    v-if="label"
                    class="mt-px select-none text-xs">{{ label }}</span>
            </label>
        </div>
    </div>
</template>
