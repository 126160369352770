<script setup>

import ErrorText from '@/Components/UI/ErrorText.vue'
import { useForm } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { useToast } from '@/Composables/useToast.ts'
import { ArrowRightIcon } from '@heroicons/vue/24/solid'

const form = useForm({
    email: ''
})

function submit () {
    form.submit('post', useRoute('newsletter.subscribe'), {
        preserveScroll: true,
        onSuccess: () => {
            useToast('Successfully subscribed')
            form.reset()
        }
    })
}
</script>

<template>
    <section>
        <h1 class="font-semibold">
            Sign up to our newsletter
        </h1>
        <form
            class="relative mt-2"
            @submit.prevent="submit">
            <input
                v-model="form.email"
                :class="form.errors.email ? 'border-red-500' : 'border-white/20 '"
                class="w-full select-none border-b bg-transparent py-2 pr-12 font-light transition-colors border-secondary/25 px-2.5 focus:border-white focus:outline-none dark:border-white/25"
                placeholder="Enter your email here"
                type="email">
            <button
                class="absolute top-1/2 right-0 -translate-y-1/2 p-2 text-white/75 hover:text-white"
                type="submit">
                <span class="sr-only">Submit form</span>
                <ArrowRightIcon class="w-5" />
            </button>
        </form>
        <ErrorText
            v-if="form.errors.email"
            :error="form.errors.email" />
    </section>
</template>
