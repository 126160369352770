import { usePost } from '@/Composables/usePost.js'
import { useRoute } from '@/Composables/useRoute.ts'
import { useToast } from '@/Composables/useToast.ts'
import { useCurrentUser } from '@/Composables/useCurrentUser.js'

export function useUpdateSetting () {
    return (key, value, successCallback = null, errorCallback = null) => {
        const settings = {}
        settings[key] = value

        usePost({
            url: useRoute('api.users.settings.single', { user: useCurrentUser().username }),
            method: 'PATCH',
            data: {
                settings,
                flash: false,
                single: true
            },
            onUnauthorized: () => useToast('Unauthorized action', 'fail'),
            onSuccess: response => successCallback ? successCallback(response) : null,
            onError: response => errorCallback ? errorCallback(response) : null
        })
    }
}
