<script setup lang="ts">
import * as UserPermission from '@/Enums/UserPermission'
import Button from '@/Components/UI/Button.vue'
import LikeButton from '@/Components/Global/LikeButton.vue'
import ShareModal from '@/Components/Global/ShareModal.vue'
import ArticleBookmarkButton from '@/Components/Article/ArticleBookmarkButton.vue'
import { onMounted, type PropType, ref } from 'vue'
import { useRoute } from '@/Composables/useRoute.ts'
import type { Article } from '@/Types/Models/Article'
import { usePermissions } from '@/Composables/usePermissions'
import { useIsAuthedUser } from '@/Composables/useIsAuthedUser.js'
import { ChatBubbleOvalLeftIcon } from '@heroicons/vue/24/outline'
import { useCountArticleLikes } from '@/Composables/useCountArticleLikes.js'

const props = defineProps({
    article: Object as PropType<Article>,
    light: {
        type: Boolean,
        default: false
    },
    commentsEnabled: {
        type: Boolean,
        default: true
    },
    initialLikesCount: [Number, null],
    initialCommentsCount: [Number, null]
})

function scrollToComments () {
    const commentSection = document.querySelector('#comments')
    commentSection.scrollIntoView({ behavior: 'smooth' })
}

const likesCount = ref<Number>(0)

function getLikesCount () {
    useCountArticleLikes(props.article).then(function (result) {
        likesCount.value = result
    })
}

onMounted(() => {
    if (props.initialLikesCount === null || props.initialLikesCount === undefined) {
        getLikesCount()
    } else {
        likesCount.value = props.initialLikesCount
    }
})
</script>

<template>
    <div class="flex items-center">
        <div class="flex items-center gap-2 xxs:gap-2.5">
            <LikeButton
                :key="likesCount"
                :light="light"
                :can="usePermissions(UserPermission.LIKE_ARTICLE)"
                :count="likesCount"
                :is-me="useIsAuthedUser(article.user)"
                :liked="article.liked"
                :url="useRoute('article.like.toggle', {article: article})"
                like-tooltip="Like article"
                unlike-tooltip="Unlike article" />

            <div
                class="flex items-center">
                <Button
                    v-tooltip="commentsEnabled ? `Scroll to comments` : `Comments are disabled`"
                    variant="white"
                    :href="null"
                    :disabled="!commentsEnabled"
                    padding="px-2 py-1.5"
                    @click="scrollToComments">
                    <div class="flex items-center gap-1.5">
                        <ChatBubbleOvalLeftIcon class="h-5 text-gray-500" />
                        <div
                            class="min-w-4">
                            {{ initialCommentsCount }}
                        </div>
                    </div>
                </Button>
            </div>

            <div>
                <ArticleBookmarkButton
                    small
                    :article="article"
                    :bookmarked="article.bookmarked" />
            </div>
            <ShareModal
                :title="article.title"
                :excerpt="article.excerpt"
                :url="article.permalink" />
        </div>
    </div>
</template>
