<template>
    <svg
        color="transparent"
        fill="transparent"
        stroke="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.2098 19.5V8.37599C2.2098 8.31896 2.25603 8.27273 2.31306 8.27273H4.90432C4.96135 8.27273 5.00758 8.31896 5.00758 8.37599V8.575M5.00758 19.1049V18.2306M2.28985 8.575H5.00758M5.00758 8.575V8.86395M5.00758 8.86395H2.37462C2.31825 8.86395 2.27442 8.913 2.28073 8.96902C2.28597 9.01555 2.3245 9.05122 2.37129 9.05287L5.00758 9.14589M5.00758 8.86395V9.14589M5.00758 9.14589V16.869M5.00758 16.869L15.7826 16.65M5.00758 16.869V17.1518M15.7826 16.65L16.9067 16.9847M15.7826 16.65L5.00758 17.1518M5.00758 18.2306L17.2029 18.0753C17.2196 18.0751 17.2359 18.0709 17.2506 18.063L17.8227 17.7548M5.00758 18.2306V17.8396M17.8227 17.7548L5.00758 17.8396M17.8227 17.7548L17.9785 17.6708M5.00758 17.8396V17.6371M5.00758 17.4345L18.0307 17.3195M5.00758 17.4345V17.1518M5.00758 17.4345V17.6371M18.0307 17.3195L18.1775 17.3632C18.2658 17.3895 18.2781 17.5094 18.197 17.5531L17.9785 17.6708M18.0307 17.3195L16.9067 16.9847M16.9067 16.9847L5.00758 17.1518M17.9785 17.6708L5.00758 17.6371M2.2098 0.657025V1.26038C2.2098 1.3174 2.25603 1.36364 2.31306 1.36364H4.90432C4.96135 1.36364 5.00758 1.3174 5.00758 1.26038V0.657025C5.00758 0.570295 4.89197 0.5 4.74932 0.5H2.46806C2.32541 0.5 2.2098 0.570295 2.2098 0.657025ZM1.61201 7.42805L1.32732 2.27137C1.30007 1.77772 1.72494 1.36364 2.2587 1.36364H15.2724C15.5452 1.36364 15.8043 1.47425 15.9815 1.66635L18.4504 4.34301C18.7607 4.67941 18.7463 5.17857 18.4171 5.49914L16.244 7.61489C16.0747 7.77973 15.8419 7.87605 15.5964 7.88292L2.57154 8.2472C2.0643 8.26138 1.63792 7.89738 1.61201 7.42805Z"
            stroke-linecap="round" />
    </svg>
</template>
