import { router } from '@inertiajs/vue3'
import { nextTick, onBeforeMount, onBeforeUnmount, onMounted } from 'vue'

export function useRestoreScroll () {
    onMounted(async () => {
        await nextTick()
        const savedPosition = sessionStorage.getItem('savedScrollPosition')
        if (savedPosition) {
            window.scrollTo(0, parseInt(savedPosition))
            sessionStorage.removeItem('savedScrollPosition') // Clear after setting
        }
    })

    const handleBeforeUnload = () => {
        sessionStorage.setItem('savedScrollPosition', window.scrollY)
    }

    onBeforeMount(() => {
        window.addEventListener('beforeunload', handleBeforeUnload)
    })

    onBeforeUnmount(() => {
        window.removeEventListener('beforeunload', handleBeforeUnload)
    })

    router.on('navigate', (event) => {
        sessionStorage.removeItem('savedScrollPosition')
    })
}
