export function useContrast (
    hex = '',
    tooBright = 'text-secondary',
    justRight = '',
    bound = 150
) {
    if (hex.includes('var')) {
        const hexString = hex.replaceAll('var(', '').replaceAll(')', '')
        hex = getComputedStyle(document.documentElement)
            .getPropertyValue(hexString)
    }
    // Determine if colour picker text will be white or dark depending on the chosen color
    const hexColor = hex.replace('#', '')
    const r = parseInt(hexColor.substr(0, 2), 16)
    const g = parseInt(hexColor.substr(2, 2), 16)
    const b = parseInt(hexColor.substr(4, 2), 16)
    const yiq = (r * 299 + g * 587 + b * 114) / 1000
    return yiq >= bound ? tooBright : justRight
}
