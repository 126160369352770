import { useContrast } from '@/Composables/useContrast.js'

export function useHeader (type, props) {
    const defaultColour = 'var(--colors-primary)'
    const colour = props?.colour ?? defaultColour
    return {
        background: colour,
        text: useContrast(props?.colour ?? defaultColour, 'text-black/75', 'text-white'),
        icon: props?.icon === null || typeof props?.icon === 'undefined' || (typeof props?.icon === 'string' && props?.icon.startsWith('component:'))
            ? null
            : (typeof props?.icon === 'string' ? props?.icon : useContrast(props?.colour ?? 'var(--colors-tertiary)', props?.icon?.dark, props?.icon?.default)),
        iconDark: props?.icon?.dark ?? props?.icon?.default
    }[type]
}
