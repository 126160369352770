<script setup>
import * as UserPermission from '@/Enums/UserPermission.js'
import Modal from '@/Components/UI/Modal.vue'
import Button from '@/Components/UI/Button.vue'
import Dropdown from '@/Components/UI/Dropdown.vue'
import CommentReportModal from '@/Components/Comment/CommentReportModal.vue'
import { ref } from 'vue'
import { usePost } from '@/Composables/usePost.js'
import { useRoute } from '@/Composables/useRoute.ts'
import { useToast } from '@/Composables/useToast.ts'
import { usePermissions } from '@/Composables/usePermissions.js'
import { EllipsisVerticalIcon } from '@heroicons/vue/24/outline'
import { useIsAuthedUser } from '@/Composables/useIsAuthedUser.js'

const props = defineProps({
    commentable: Object,
    comment: Object
})

const showDeletionConfirmation = ref(false)
const showReportConfirmation = ref(false)

const canDelete = usePermissions(UserPermission.DELETE_ANY_COMMENT) ||
    ((useIsAuthedUser(props.commentable.user) && usePermissions(UserPermission.DELETE_OWN_MODEL_COMMENT)) ||
        (useIsAuthedUser(props.comment.user) && usePermissions(UserPermission.DELETE_OWN_COMMENT)))

function confirmDelete () {
    showDeletionConfirmation.value = true
}

function confirmReport () {
    showReportConfirmation.value = true
}

const emit = defineEmits(['deleted', 'reported'])

function deleteComment () {
    usePost({
        url: useRoute('comment.destroy', { comment: props.comment }),
        method: 'DELETE',
        onUnauthorized: () => useToast('Unauthorized action', 'fail'),
        onSuccess: response => {
            emit('deleted')
            // useEmitter.emit('comment:deleted')
            showDeletionConfirmation.value = false
        }
    })
}

const items = [
    {
        title: 'Delete',
        if: canDelete,
        component: 'button',
        action: confirmDelete
    },
    {
        title: 'Report',
        component: 'button',
        action: confirmReport
    }
]
</script>

<template>
    <div>
        <div class="absolute top-0 right-0">
            <Dropdown :items="items">
                <template #toggle>
                    <button
                        class="text-slate-900/75 opacity-0 group-hover:opacity-100 hover:text-slate-900 focus:text-slate-900 dark:text-white/75 dark:hover:text-white">
                        <span class="sr-only">Open options for comment: {{ comment.id }}</span>
                        <EllipsisVerticalIcon class="w-5" />
                    </button>
                </template>
            </Dropdown>
        </div>
        <Modal
            :show="showDeletionConfirmation"
            max-width="md"
            @close="showDeletionConfirmation = false">
            <template #title>
                Delete Comment
            </template>

            <template #content>
                Are you sure you want to delete this comment?
            </template>

            <template #footer>
                <div class="flex w-full flex-row items-center justify-end gap-4">
                    <Button
                        variant="white"
                        @click="showDeletionConfirmation = false">
                        Cancel
                    </Button>

                    <Button
                        variant="danger"
                        @click="deleteComment">
                        Delete
                    </Button>
                </div>
            </template>
        </Modal>

        <CommentReportModal
            :comment="comment"
            :show="showReportConfirmation"
            @close="showReportConfirmation = false" />
    </div>
</template>
