<script lang="ts" setup>
import Novus from '@/Components/Global/Novus.vue'
import { usePlural } from '@/Composables/usePlural'

defineProps({
    stats: Object
})

function formatNumber (number: number) {
    return new Intl.NumberFormat('en', {
        notation: 'compact'
    }).format(number)
}
</script>

<template>
    <div>
        <div class="container px-4 md:px-6">
            <div class="relative z-10 flex w-full flex-col items-center justify-center text-center">
                <div class="gap-2">
                    <h2 class="text-3xl tracking-tighter font-heading sm:text-5xl">
                        Metrics
                    </h2>
                    <p class="text-slate-500 max-w-[900px] dark:text-slate-400 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                        Check out our latest stats and see how we're doing!
                    </p>
                </div>
                <div
                    class="mt-8 flex w-full flex-wrap items-center bg-gradient-to-r bg-clip-text text-transparent bg-gradient-app dark:text-white lg:flex-nowrap">
                    <div
                        v-for="(stat, title) in stats"
                        :key="title"
                        class="mb-8 flex w-1/2 flex-col text-center lg:mb-0">
                        <div class="text-6xl font-heading sm:text-6xl">
                            {{ formatNumber(stat) }}
                        </div>
                        <div class="mt-2 text-slate-950/75 dark:text-white">
                            {{ usePlural(title, stat) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="pointer-events-none absolute right-0 bottom-0 z-0 flex aspect-square w-96 items-end justify-end overflow-hidden opacity-25 lg:opacity-100">
            <div
                data-aos="slide-up-left-diagonal"
                data-aos-delay="500"
                class="flex w-72 items-end justify-end size-full">
                <Novus
                    emotion="wink"
                    class="w-full translate-x-20 translate-y-12 -rotate-[35deg]" />
            </div>
        </div>
    </div>
</template>
