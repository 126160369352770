<script setup>
defineProps({
    show: Boolean
})

const emit = defineEmits(['click'])
</script>

<template>
    <transition
        enter-active-class="duration-200 ease-out"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div
            v-show="show"
            class="fixed inset-0 transition-all sm:backdrop-blur-sm"
            @click="emit('click')">
            <div class="absolute inset-0 bg-black/75 dark:bg-white/10" />
        </div>
    </transition>
</template>
