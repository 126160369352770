<script setup>
import * as Status from '@/Enums/Status'
import Card from '@/Components/UI/Card.vue'
import Button from '@/Components/UI/Button.vue'
import ErrorText from '@/Components/UI/ErrorText.vue'
import InputTags from '@/Components/Input/InputTags.vue'
import InputRadio from '@/Components/Input/InputRadio.vue'
import InputLabel from '@/Components/Input/InputLabel.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import InputSelect from '@/Components/Input/InputSelect.vue'
import InputToggle from '@/Components/Input/InputToggle.vue'
import GameSelector from '@/Components/Game/GameSelector.vue'
import InputPassword from '@/Components/Input/InputPassword.vue'
import InputDatePicker from '@/Components/Input/InputDatePicker.vue'
import CategorySelector from '@/Components/Category/CategorySelector.vue'
import ArticleTitleInput from '@/Components/Article/ArticleTitleInput.vue'
import InputWYSIWYGTinyMCE from '@/Components/Input/InputWYSIWYGTinyMCE.vue'
import ArticleSubtitleInput from '@/Components/Article/ArticleSubtitleInput.vue'
import ArticleThumbnailInput from '@/Components/Article/ArticleThumbnailInput.vue'
import { onMounted, ref, watch } from 'vue'
import { router, useForm } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { useToast } from '@/Composables/useToast.ts'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { ArticleAccessEnum } from '@/Types/ArticleAccess.ts'
import { XMarkIcon, ChevronUpIcon } from '@heroicons/vue/24/outline'
import { useUpdateSetting } from '@/Composables/useUpdateSetting.js'
import {
    useDateFormat,
    useMagicKeys,
    useNow,
    useTimeAgo,
    useTimeoutPoll,
    useUrlSearchParams
} from '@vueuse/core'

const props = defineProps({
    endpoint: String,
    method: String,
    recentArticles: Array,
    rejectionReasons: Array || null,
    autosave: {
        type: Boolean,
        default: false
    },
    article: Object || null,
    tag: Object || null,
    categories: Array
})

const updateSetting = useUpdateSetting()
const params = useUrlSearchParams('history')

const addFeaturedImage = (file) => {
    form.featured_image = file
    form.featured_image_changed = true

    saveDraft()
}

const removeFeaturedImage = () => {
    form.featured_image = null
    form.featured_image_changed = true
}

const form = useForm({
    uuid: props.article?.uuid || null,
    title: props.article?.title || null,
    content: props.article?.content || null,
    category: props.article?.category || null,
    featured_image: props.article?.featured_image?.original || null,
    tags: props.article?.tags?.length ? props.article?.tags.map(tag => tag.name) : (props.tag ? [props.tag] : []),
    publish_at: props.article?.publish_at || null,
    featured_image_changed: false,
    metadata: {
        rating: props.article?.metadata?.rating || null,
        subtitle: props.article?.metadata?.subtitle || '',
        conclusion: props.article?.metadata?.conclusion || '',
        game_identifier: props.article?.metadata?.game_identifier || null,
        allow_comments: props.article?.metadata?.allow_comments,
        public: props.article?.metadata?.public,
        accessible_by: props.article?.metadata?.accessible_by,
        password: props.article?.metadata?.password
    }
})

const articleAccessOptions = [
    {
        value: ArticleAccessEnum.ALL,
        label: 'Just me'
    },
    {
        value: ArticleAccessEnum.SUBSCRIBERS,
        label: 'Subscribers only'
    },
    {
        value: ArticleAccessEnum.LINKED,
        label: 'Anyone with a link'
    },
    {
        value: ArticleAccessEnum.PASSWORD,
        label: 'Anyone with a password'
    }
]

const schedulePublish = ref(form.publish_at !== null)
const includeRating = ref(false)

watch(schedulePublish, () => {
    form.publish_at = schedulePublish.value ? useDateFormat(useNow(), 'YYYY-MM-DD hh:mma', { locales: usePageProps().app.locale }).value : null
})

watch(includeRating, () => {
    form.metadata.rating = includeRating.value ? 5 : null
})

const autosaved = ref(false)
const manuallySaved = ref(false)
const showHelper = ref(!usePageProps().auth.settings.article_helper_closed)
const submitting = ref(false)

function saveDraft (auto = false) {
    if (form.metadata.public === true) {
        form.metadata.accessible_by = ArticleAccessEnum.ALL
        form.metadata.password = null
    }

    if (form.metadata.accessible_by !== ArticleAccessEnum.PASSWORD) {
        form.metadata.password = null
    }

    form.submit('patch', useRoute('article.update', { article: props.article }), {
        preserveScroll: true,
        onSuccess: () => {
            if (form.featured_image_changed) {
                // location.reload()
                router.get(useRoute('article.edit', props.article), {}, {
                    preserveScroll: true,
                    preserveState: false
                })
            }

            form.featured_image_changed = false

            if (auto) {
                autosaved.value = true
            } else {
                manuallySaved.value = true
            }
            setTimeout(() => {
                manuallySaved.value = false
                autosaved.value = false
            }, 1000)
        }
    })
}

function submit () {
    submitting.value = true

    if (form.metadata.public === true) {
        form.metadata.accessible_by = ArticleAccessEnum.ALL
        form.metadata.password = null
    }

    if (form.metadata.accessible_by !== ArticleAccessEnum.PASSWORD) {
        form.metadata.password = null
    }

    form.submit('post', useRoute('article.submit'), {
        preserveScroll: true,
        onSuccess: () => {
            form.featured_image_changed = false
            submitting.value = false
            // useToast('Article submitted', 'success')
        },
        onError: response => {
            useToast('Failed to submit article', 'fail')

            submitting.value = false
        }
    })
}

function closeHelper () {
    showHelper.value = false

    updateSetting('article_helper_closed', true, () => {
        router.reload({
            preserveScroll: true,
            preserveState: true,
            replace: false,
            only: ['auth']
        })
    })
}

const showRejections = ref(props.article.status.name === Status.REJECTED)

// 30 seconds
const { resume } = useTimeoutPoll(() => {
    saveDraft(true)
}, 30000)

useMagicKeys({
    passive: false,
    onEventFired (e) {
        if ((e.ctrlKey || e.metaKey) && e.key === 's' && e.type === 'keydown') {
            e.preventDefault()
            saveDraft(true)
        }
    }
})

onMounted(() => {
    params.game = null
    if (props.autosave) {
        resume()
    }
})
</script>

<template>
    <form
        class="flex flex-col pb-12"
        @submit.prevent="submit">
        <div class="mx-auto w-full max-w-7xl px-0 md:px-5">
            <div class="relative flex flex-col items-stretch gap-4 md:flex-row md:gap-8">
                <div class="w-full md:w-8/12">
                    <div class="mb-2 flex flex-col md:mb-4">
                        <CategorySelector
                            v-model="form.category"
                            class="hidden md:flex"
                            :categories="categories" />
                        <InputSelect
                            v-model="form.category"
                            :error="form.errors.category"
                            :options="categories"
                            class="block w-full md:hidden"
                            display-label="title"
                            placeholder="Category..." />

                        <div class="mt-2">
                            <ErrorText :error="form.errors.category" />
                        </div>
                    </div>
                    <ArticleTitleInput
                        v-model="form.title"
                        :error="form.errors.title"
                        label="Title..." />

                    <ArticleThumbnailInput
                        :default-thumbnail="form.featured_image"
                        :error="form.errors.featured_image"
                        @removed="removeFeaturedImage"
                        @selected="addFeaturedImage" />

                    <InputTags
                        v-model="form.tags"
                        class="mt-4"
                        label="Tags (optional)" />

                    <ArticleSubtitleInput
                        v-model="form.metadata.subtitle"
                        :error="form.errors['metadata.subtitle']"
                        class="mt-4 md:mt-8"
                        label="Subtitle (optional)" />

                    <div class="mt-4 md:mt-8">
                        <InputWYSIWYGTinyMCE
                            v-model="form.content"
                            :error="form.errors.content" />

                        <ErrorText
                            v-if="form.errors.content"
                            class="mt-2"
                            :error="form.errors.content" />
                    </div>

                    <!--                    <div class="flex justify-end">-->
                    <!--                        <Button-->
                    <!--                            type="button"-->
                    <!--                            variant="white"-->
                    <!--                            class="mt-8"-->
                    <!--                            @click="addConclusion = !addConclusion">-->
                    <!--                            {{ addConclusion ? 'Remove' : 'Add' }} conclusion-->
                    <!--                        </Button>-->
                    <!--                    </div>-->

                    <!--                    <div-->
                    <!--                        v-if="addConclusion"-->
                    <!--                        class="mt-2 flex w-full flex-col-reverse items-center overflow-hidden rounded-2xl border bg-white px-4 py-10 border-primary/10 dark:bg-slate-800 dark:text-white md:space-x-10 md:flex-row md:px-10">-->
                    <!--                        <div class="mt-8 flex w-full flex-col md:mt-0 md:w-3/4">-->
                    <!--                            <ArticleConclusionInput-->
                    <!--                                v-model="form.metadata.conclusion"-->
                    <!--                                :error="form.errors['metadata.conclusion']"-->
                    <!--                                label="Conclusion (optional)" />-->
                    <!--                        </div>-->
                    <!--                        <div class="flex w-full flex-col md:w-1/4 md:items-end md:justify-end">-->
                    <!--                            <div>-->
                    <!--                                <InputToggle-->
                    <!--                                    v-model="includeRating"-->
                    <!--                                    label="Include rating" />-->
                    <!--                            </div>-->
                    <!--                            <div-->
                    <!--                                v-if="includeRating"-->
                    <!--                                class="mt-8 flex w-full flex-col items-center">-->
                    <!--                                <RatingCircle-->
                    <!--                                    :key="form.metadata.rating"-->
                    <!--                                    :score="form.metadata.rating"-->
                    <!--                                    :width="100" />-->
                    <!--                                <div class="mt-2 w-full">-->
                    <!--                                    <InputText-->
                    <!--                                        v-model="form.metadata.rating"-->
                    <!--                                        :error="form.errors['metadata.rating']"-->
                    <!--                                        class="w-full"-->
                    <!--                                        input-type="number"-->
                    <!--                                        max="10"-->
                    <!--                                        min="0"-->
                    <!--                                        step="0.1" />-->
                    <!--                                </div>-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                </div>
                <div class="relative flex w-full flex-col md:w-4/12">
                    <aside class="sticky top-0 mt-8">
                        <div
                            v-if="showHelper"
                            class="mb-2 flex w-full justify-end">
                            <Button
                                variant="white"
                                inner-class="flex items-center gap-2 text-xs"
                                padding="py-1 px-2"
                                @click="closeHelper">
                                <XMarkIcon class="w-4" />
                                Close
                            </Button>
                        </div>
                        <div class="flex flex-col gap-4">
                            <Card v-if="showHelper">
                                <SectionTitle font-size="text-xl">
                                    <div class="flex items-center">
                                        Keys to a good article
                                    </div>
                                </SectionTitle>
                                <div class="prose">
                                    <ul>
                                        <li>Engaging Title and Introduction</li>
                                        <li>Eye-catching visuals and images</li>
                                        <li>Concise and meaningful tags</li>
                                        <li>Well-thought out conclusion</li>
                                    </ul>
                                </div>
                            </Card>

                            <Card v-if="rejectionReasons.length > 0">
                                <SectionTitle font-size="text-xl">
                                    <button
                                        type="button"
                                        class="flex w-full items-center justify-between"
                                        @click="showRejections = !showRejections">
                                        Previous rejections ({{ rejectionReasons.length }})
                                        <div>
                                            <ChevronUpIcon
                                                class="w-4 transition-transform"
                                                :class="!showRejections ? 'transform rotate-180' : 'transform rotate-0'"
                                            />
                                        </div>
                                    </button>
                                </SectionTitle>
                                <ul
                                    v-show="showRejections"
                                    class="my-4 flex flex-col gap-4 text-sm text-red-500">
                                    <li
                                        v-for="(rejectionReason, i) in rejectionReasons"
                                        :key="i"
                                        class="flex flex-col gap-0">
                                        <strong class="font-mono">
                                            {{ useDateFormat(rejectionReason.date, 'YYYY-MM-DD hh:mma', { locales: usePageProps().app.locale }).value }}
                                        </strong>
                                        <p>{{ rejectionReason.reason }}</p>
                                    </li>
                                </ul>
                            </Card>

                            <!--                            <ArticleAuthorCard-->
                            <!--                                :actions="false"-->
                            <!--                                :user="usePageProps().auth.user" />-->

                            <Card>
                                <SectionTitle font-size="text-xl">
                                    Options
                                </SectionTitle>
                                <div class="mt-4 flex flex-col gap-4">
                                    <InputToggle
                                        v-model="form.metadata.allow_comments"
                                        :text-first="true"
                                        label="Allow comments" />
                                    <InputToggle
                                        v-model="form.metadata.public"
                                        :text-first="true"
                                        label="Public" />

                                    <div v-if="!form.metadata.public">
                                        <InputLabel>Who can access?</InputLabel>
                                        <InputRadio
                                            v-model="form.metadata.accessible_by"
                                            class="mt-2"
                                            spacing="gap-2"
                                            :options="articleAccessOptions"
                                            label="Rating (optional)" />

                                        <InputPassword
                                            v-if="form.metadata.accessible_by === ArticleAccessEnum.PASSWORD"
                                            v-model="form.metadata.password"
                                            autocomplete="new-password"
                                            :error="form.errors['metadata.password']"
                                            class="mt-2"
                                            label="Password" />
                                    </div>

                                    <div class="flex w-full flex-col gap-2">
                                        <div>
                                            <InputToggle
                                                v-model="schedulePublish"
                                                :text-first="true"
                                                label="Schedule publish" />
                                        </div>
                                        <div class="mt-2">
                                            <div>
                                                <InputDatePicker
                                                    v-if="schedulePublish"
                                                    v-model="form.publish_at"
                                                    :future-only="true"
                                                    class="w-full"
                                                    placeholder="Publish at..." />
                                            </div>
                                            <div
                                                v-if="schedulePublish"
                                                class="mt-1 flex justify-end text-xs text-gray-900/50">
                                                {{ useTimeAgo(form.publish_at).value }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>

                            <div :class="form.metadata.game_identifier ? 'pb-2' : ''">
                                <GameSelector
                                    v-model="form.metadata.game_identifier"
                                    :small="true"
                                    :white="true" />
                            </div>

                            <!--                            <Card>-->
                            <div class="flex gap-4">
                                <Button
                                    :disabled="form.processing"
                                    type="button"
                                    variant="white"
                                    class="w-full"
                                    padding="px-4 py-2"
                                    @click="saveDraft">
                                    {{ manuallySaved ? 'Saved' : 'Save draft' }}
                                </Button>
                                <Button
                                    :disabled="form.processing"
                                    type="button"
                                    class="w-full"
                                    padding="px-4 py-2"
                                    @click="submit">
                                    <span v-if="schedulePublish">
                                        {{ submitting ? 'Scheduling...' : 'Schedule' }}
                                    </span>
                                    <span v-else>
                                        {{ submitting ? 'Submitting...' : 'Submit' }}
                                    </span>
                                </Button>
                            </div>
                            <!--                            </Card>-->
                            <div class="-mt-3 flex justify-end pr-1 text-xs">
                                <a
                                    v-tooltip="`Learn more about the submission process`"
                                    :href="useRoute('submission-process')"
                                    class="hover:underline"
                                    target="_blank">
                                    learn more
                                    <!--                                    <QuestionMarkCircleIcon-->
                                    <!--                                        class="w-5 text-slate-950/75 hover:text-slate-950" />-->
                                </a>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    </form>
</template>
