<script setup lang="ts">
import { Link } from '@inertiajs/vue3'
import { computed, type PropType } from 'vue'
import { useRoute } from '@/Composables/useRoute.ts'
import type { Platform } from '@/Types/Models/Platform'

const emit = defineEmits(['openModal'])

const props = defineProps({
    platforms: Array as PropType<Platform[]>
})

const displayedPlatforms = computed(() => {
    const platforms = props.platforms
    return platforms.slice(0, 3)
})

function randomInt (min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min
}
</script>

<template>
    <div class="flex w-full gap-2 text-sm">
        <ul
            v-if="!platforms"
            class="flex w-full flex-wrap pt-1 skeleton-animation gap-2.5 min-w-18">
            <li
                v-for="i in 3"
                :key="i"
                :style="`width:${randomInt(30, 65)}px`"
                class="flex w-full items-center">
                <div
                    class="h-3 w-full rounded-full bg-slate-200 dark:bg-slate-800"
                />
            </li>
        </ul>
        <ul
            v-if="platforms && platforms.length"
            class="flex flex-wrap text-sm gap-x-2.5 gap-y-0.5">
            <li
                v-for="platform in displayedPlatforms"
                :key="platform.identifier">
                <Link
                    class="hover:underline"
                    :href="useRoute('platforms.show', platform.slug)">
                    {{ platform.name }}
                </Link>
            </li>
            <li
                v-if="platforms.length > 3"
                @click="emit('openModal')">
                <button class="font-semibold hover:underline">
                    See more...
                </button>
            </li>
        </ul>
        <span
            v-if="platforms && !platforms.length"
            class="italic text-slate-900/75">
            None
        </span>
    </div>
</template>
