import useEmitter from '@/Composables/useEmitter'
import { onMounted, ref } from 'vue'
import type { Ref } from 'vue'
import type { User } from '@/Types/Models/User'
import { useCountFollowers } from '@/Composables/useCountFollowers'

export function useFollowerCount (user: User, initialCount: number | null = null): Ref<number | null> {
    const followerCount = ref<number | null>(initialCount)

    useEmitter.on('followersUpdated', (event: { user: number; data: { count: number } }) => {
        if (event.user === user.id) {
            followerCount.value = event.data.count
        }
    })

    onMounted(() => {
        useCountFollowers(user).then((result: number) => {
            followerCount.value = result
        })
    })

    return followerCount
}
