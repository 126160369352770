<script setup>
import * as Status from '@/Enums/Status'
import patternImage from '~/app/patterns/logo.svg'
import ArticleManageActions from '@/Components/Article/ArticleManageActions.vue'
import { computed } from 'vue'
import { Link } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { usePlural } from '@/Composables/usePlural.js'
import { useDateFormat, useTimeAgo } from '@vueuse/core'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { useImageOptimise } from '@/Composables/useImageOptimise.js'
import { ChatBubbleOvalLeftIcon, EyeIcon, HeartIcon } from '@heroicons/vue/24/solid'

const props = defineProps({
    article: Object,
    index: Number
    // viewData: Object
})

const colours = {
    published: 'bg-primary-lighter text-primary border-current',
    approved: 'bg-green-100 text-green-800 border-current',
    rejected: 'bg-red-100 text-red-800 border-current',
    'in review': 'bg-orange-100 text-orange-800 border-current',
    submitted: 'bg-gray-200 text-slate-900 border-current',
    drafts: 'bg-blue-100 text-blue-800 border-current',
    trashed: 'bg-gray-100 text-gray-800 border-current'
}

const baseRoute = 'dashboard.articles'
const editRoute = 'article.edit'

const urlMap = {
    published: props.article.permalink,
    approved: useRoute(baseRoute, { status: Status.APPROVED }),
    rejected: useRoute(editRoute, props.article),
    'in review': null,
    submitted: useRoute(baseRoute, { status: Status.SUBMITTED }),
    drafts: useRoute(editRoute, props.article),
    trashed: useRoute(baseRoute, { status: Status.TRASHED })
}

const isPublished = computed(() => props.article.status.name === Status.PUBLISHED)
const isDraft = computed(() => props.article.status.name === Status.DRAFTS)

const stats = [
    {
        label: 'View',
        value: props.article.views,
        component: EyeIcon
    },
    {
        label: 'Like',
        value: props.article.likes_count,
        component: HeartIcon
    },
    {
        label: 'Comment',
        value: props.article.comments_count,
        component: ChatBubbleOvalLeftIcon
    }
]

const dateFormat = 'YYYY-MM-DD hh:mma'

function formatNumber (number) {
    return new Intl.NumberFormat('en', {
        notation: 'compact'
    }).format(number)
}
</script>

<template>
    <div class="flex w-full flex-col items-center pb-4 group md:flex-row md:pb-0">
        <div class="w-full shrink-0 px-4 py-4 md:w-28 xl:w-36">
            <div
                :style="`background-image:url('${patternImage}')`"
                class="relative w-full overflow-hidden rounded-lg bg-center aspect-article-thumb md:bg-xs">
                <div class="absolute top-0 left-0 h-full w-full">
                    <Link
                        :class="colours[article.status.name]"
                        :href="useRoute(baseRoute, {status: article.status.name})"
                        class="absolute top-1 left-1 rounded px-2 font-semibold uppercase tracking-wide py-0.5 text-xxs">
                        {{ article.status.name }}
                    </Link>
                    <img
                        v-if="article.featured_image"
                        v-lazy="useImageOptimise(article.featured_image?.small)"
                        :alt="`Thumbnail image for ${article.title}`"
                        class="h-full w-full object-cover object-center"
                        loading="lazy">
                </div>
            </div>
        </div>
        <div class="w-full px-4 pr-12 md:w-6/12 md:py-4 xl:mr-20">
            <div class="flex w-full flex-col">
                <component
                    :is="urlMap[article.status.name] ? Link : 'h2'"
                    :href="urlMap[article.status.name]"
                    :class="urlMap[article.status.name] ? 'hover:text-primary dark:hover:text-primary-light' : ''"
                    class="text-xl font-heading line-clamp-2 md:text-base/6">
                    {{ article.title ?? '[Untitled]' }}
                </component>
                <div class="flex flex-col text-sm text-slate-900/75 mt-0.5 gap-0.5 dark:text-white/75 md:text-xs">
                    <div v-if="isDraft">
                        Updated <span
                            v-tooltip="useDateFormat(article.updated_at, dateFormat, { locales: usePageProps().app.locale }).value">
                            {{ useTimeAgo(article.updated_at).value }}
                        </span>
                    </div>
                    <div
                        v-if="!isDraft"
                        class="capitalize">
                        {{ article.status.name.toLowerCase() }}
                        <span
                            v-tooltip="useDateFormat(article.status.date, dateFormat, { locales: usePageProps().app.locale }).value">
                            {{ useTimeAgo(article.status.date).value }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-4 flex w-full flex-col items-center gap-2 md:mt-0 md:ml-auto md:w-5/12 md:flex-row md:justify-end md:gap-12">
            <div class="w-full md:w-5/12 md:py-4">
                <div class="flex w-full justify-between gap-4 px-4 text-xs">
                    <div
                        v-for="stat in stats"
                        :key="stat.label"
                        v-tooltip="`${stat.value} ${usePlural(stat.label, stat.value) }`"
                        class="flex cursor-default flex-col text-center hover:text-primary dark:hover:text-white"
                        :class="[isPublished ? 'text-primary/50 dark:text-white/50' : 'text-primary dark:text-white opacity-50 pointer-events-none']">
                        <span><component
                            :is="stat.component"
                            class="w-5" /></span>
                        <span
                            class="text-lg font-heading"
                            v-html="isPublished ? formatNumber(stat.value) : '&mdash;'" />
                    </div>
                </div>
            </div>

            <div
                class="flex w-full justify-center py-2 pr-4 pl-4 transition-opacity group-hover:opacity-100 md:w-32 md:justify-end md:pl-0 lg:opacity-50">
                <ArticleManageActions :article="article" />
            </div>
        </div>
    </div>
</template>
