import { usePageProps } from '@/Composables/usePageProps.ts'

export async function useUploadFile (url = '', data = {}) {
    data._token = usePageProps().csrf_token
    let response

    const formData = new FormData()
    for (const key in data) {
        formData.append(key, data[key])
    }

    const headers = {}

    try {
        response = await fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers,
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: formData
        })
    } catch (error) {
        console.log('There was an error', error)
    }

    if (response?.ok) {
        return response.json()
    } else {
        return response.json().then(error => {
            return { errors: error }
        })
    }
}
