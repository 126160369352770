<script setup>

import Button from '@/Components/UI/Button.vue'
import InputText from '@/Components/Input/InputText.vue'
import { useForm } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'

defineProps({
    user: Object,
    oauth: Boolean
})

const form = useForm({
    password: null,
    password_confirmation: null,
    current_password: null
})

function submit () {
    form.patch(useRoute('user.settings.password.update'), {
        preserveScroll: true,
        errorBag: 'updatePassword',
        onSuccess: () => {
            resetForm()
        }
    })
}

function resetForm () {
    form.password = null
    form.password_confirmation = null
    form.current_password = null
}

function buttonEnabled () {
    return form.password !== '' && form.password !== null &&
        form.password_confirmation !== '' && form.password_confirmation !== null &&
        form.current_password !== '' && form.current_password !== null
}
</script>

<template>
    <div>
        <form @submit.prevent="submit">
            <div class="flex w-full flex-col gap-4 sm:flex-row">
                <InputText
                    v-model="form.password"
                    :error="form.errors.password"
                    class="w-full"
                    label="New password" />
                <InputText
                    v-model="form.password_confirmation"
                    :error="form.errors.password_confirmation"
                    class="w-full"
                    label="Confirm new password" />
            </div>
            <div class="mt-4">
                <InputText
                    v-if="!oauth"
                    v-model="form.current_password"
                    :error="form.errors.current_password"
                    label="Current password" />
            </div>
            <div class="mt-8 flex w-full justify-end gap-4">
                <Button
                    :disabled="!form.isDirty"
                    class="px-8"
                    type="button"
                    variant="white"
                    @click="resetForm">
                    {{ form.processing ? 'Resetting' : 'Reset' }}
                </Button>
                <Button
                    :disabled="!buttonEnabled()"
                    class="px-8"
                    type="submit">
                    {{ form.processing ? 'Saving' : 'Save' }}
                </Button>
            </div>
        </form>
    </div>
</template>
