<script setup lang="ts">
import Button from '@/Components/UI/Button.vue'
import { ref } from 'vue'
import { router } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { LockClosedIcon } from '@heroicons/vue/24/outline'
import { usePageProps } from '@/Composables/usePageProps.ts'

const sending = ref(false)
const sent = ref(false)

function resendVerification () {
    router.post(useRoute('verification.send'), null, {
        preserveScroll: true,
        onStart: () => {
            sending.value = true
        },
        onFinish: () => {
            sending.value = false
            sent.value = true
        }
    })
}
</script>

<template>
    <div class="relative bg-secondary">
        <div class="container mx-auto px-4 py-3 sm:px-6 lg:px-8">
            <div class="flex flex-wrap items-center justify-center lg:justify-between">
                <div class="flex items-center text-center lg:text-left">
                    <span class="hidden rounded-lg bg-white/10 p-2 md:flex">
                        <LockClosedIcon
                            class="w-5 text-white" />
                    </span>
                    <p class="text-sm text-white text-balance md:ml-2">
                        <template v-if="!sent">
                            Some features may be locked until you verify your email address
                        </template>
                        <template v-else>
                            Email verification email sent to <strong>{{ usePageProps().auth.user.email }}</strong>
                        </template>
                    </p>
                </div>
                <div class="order-3 mt-2 flex w-full flex-shrink-0 justify-center px-2 sm:order-2 md:mt-0 md:w-auto">
                    <Button
                        variant="white"
                        @click="resendVerification">
                        <span v-if="!sending"> Resend verification </span>
                        <span v-else> Sending... </span>
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>
