<script setup lang="ts">
import useEmitter from '@/Composables/useEmitter'
import ToastSingle from '@/Components/UI/ToastSingle.vue'
import { computed } from 'vue'
import type { Toast } from '@/Types/Toast'
import { ToastNotificationService } from '@/Services/ToastNotificationService.ts'

const toastService = new ToastNotificationService()

const toasts = computed(() => toastService.toasts.value)

useEmitter.on('toast', function (toast: Toast) {
    toastService.addToast(toast)
})
</script>

<template>
    <div
        aria-live="assertive"
        class="pointer-events-none overflow-auto max-h-[calc(100dvh-4rem)] z-50 fixed right-0 w-full bottom-0 flex items-end px-2 py-2 sm:items-start sm:p-6">
        <div class="flex w-full flex-col items-center space-y-2 sm:items-end md:space-y-4">
            <slot />
            <transition
                v-for="toast in toasts"
                :key="toast.id"
                enter-active-class="transform transition duration-300 ease-out"
                enter-from-class="translate-y-2 opacity-0 sm:translate-x-2 sm:translate-y-0"
                enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                leave-active-class="transition duration-100 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0">
                <ToastSingle
                    v-if="toast.show"
                    :toast="toast"
                    @close="toastService.removeToast(toast.id)" />
            </transition>
        </div>
    </div>
</template>
