<script setup lang="ts">
import Icon from '@/Components/Icon/index.js'
import TagList from '@/Components/Tag/TagList.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import TagFollowButton from '@/Components/Tag/TagFollowButton.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import type { Tag } from '@/Types/Models/Tag'
import { computed, type PropType } from 'vue'
import type { PageHeader } from '@/Types/PageHeader'
import { useHeader } from '@/Composables/useHeader.js'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import {
    TagIcon as TagIconSolid,
    ArrowTrendingUpIcon,
    SparklesIcon,
    FireIcon,
    BookmarkIcon,
    UserGroupIcon,
    RssIcon
} from '@heroicons/vue/24/solid'

const props = defineProps({
    header: Object as PropType<PageHeader>,
    hasRelatedTags: Boolean,
    fullWidth: {
        type: Boolean,
        default: false
    },
    center: {
        type: Boolean,
        default: false
    },
    relatedTags: [Array, null] as PropType<Tag[]>
})

const breakpoints = useBreakpoints(breakpointsTailwind)

const smAndLarger = breakpoints.greaterOrEqual('sm') // sm and larger

const iconMap = {
    ArrowTrendingUpIcon,
    FireIcon,
    BookmarkIcon,
    UserGroupIcon,
    RssIcon,
    TagIconSolid,
    GamePadFilled: Icon.GamePadFilled,
    SparklesIcon,
    GamePad: Icon.GamePad
}

let iconComponent = null
if (props.header?.icon && typeof props.header?.icon === 'string' && props.header?.icon.startsWith('component:')) {
    const componentName = props.header?.icon.replace('component:', '')
    iconComponent = iconMap[componentName]
}

const backgroundColour = useHeader('background', props.header)
const gradient = computed(() => backgroundColour && backgroundColour === 'var(--colors-primary)')
</script>

<template>
    <div>
        <div
            v-if="header?.title"
            :class="center ? 'md:text-center' : ''"
            class="relative my-12">
            <div class="container relative mx-auto">
                <div class="flex flex-col items-start gap-2 xs:flex-row xs:items-center xs:justify-between xs:gap-0">
                    <div>
                        <SectionTitle
                            font-size="text-lg xs:text-2xl md:text-4xl"
                            :class="center ? 'md:justify-center' : ''"
                            class="mx-auto flex items-center tracking-tight">
                            <div
                                v-if="useHeader('icon', header) || iconComponent"
                                class="mr-2 flex aspect-square w-8 items-center justify-center rounded-full p-1"
                                :class="[
                                    useHeader('text', header),
                                    gradient ? 'bg-gradient-to-r from-primary to-pink' : ''
                                ]"
                                :style="{ backgroundColor: `${useHeader('background', header)}` }"
                            >
                                <span v-if="useHeader('icon', header)">
                                    <img
                                        class="mx-auto w-10/12"
                                        :alt="`Icon for page`"
                                        :src="useHeader('icon', header)"
                                        width="36">
                                </span>
                                <component
                                    :is="iconComponent"
                                    v-if="iconComponent"
                                    class="w-5" />
                            </div>
                            <div class="break-words hyphens-auto">
                                {{ header?.title }}
                            </div>
                        </SectionTitle>
                        <SectionDescription
                            v-if="header?.description"
                            :class="center ? 'mx-auto' : ''"
                            class="mt-2 flex xs:hidden">
                            {{ header.description }}
                        </SectionDescription>
                    </div>
                    <div
                        v-if="header.tag"
                        class="shrink-0">
                        <TagFollowButton
                            :padding="!smAndLarger ? 'px-2 py-1' : 'px-4 py-2'"
                            :tag="header.tag" />
                    </div>
                </div>

                <SectionDescription
                    v-if="header?.description"
                    :class="center ? 'mx-auto' : ''"
                    class="mt-2 hidden max-w-lg text-pretty xs:flex">
                    {{ header.description }}
                </SectionDescription>

                <div
                    v-if="hasRelatedTags"
                    class="mt-2">
                    <TagList
                        :small="true"
                        :center="false"
                        :tags="relatedTags" />
                </div>
            </div>
        </div>
    </div>
</template>
