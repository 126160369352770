<script setup lang="ts">
import Button from '@/Components/UI/Button.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import InputPassword from '@/Components/Input/InputPassword.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import ArticleThumbnail from '@/Components/Article/ArticleThumbnail.vue'
import { type PropType } from 'vue'
import { useForm } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import type { Article } from '@/Types/Models/Article'
import { LockClosedIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
    article: Object as PropType<Article>
})

const form = useForm({
    password: ''
})

function submit () {
    form.post(useRoute('article.password-protected.check', props.article.path))
}
</script>
<template>
    <div class="container mx-auto py-20">
        <div class="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
            <ArticleThumbnail
                :aspect="'aspect-[16/9]'"
                :article
                :actions="false" />
        </div>

        <div class="mt-4 sm:mx-auto sm:w-full sm:max-w-sm">
            <div class="text-center">
                <SectionTitle>
                    <div class="mx-auto flex w-full items-center justify-center gap-2">
                        <LockClosedIcon class="h-6 w-6" />
                        Password Protected
                    </div>
                </SectionTitle>
                <SectionDescription class="mt-2">
                    This article has been password protected. Please enter the password to view the content.
                </SectionDescription>
            </div>
            <form
                class="mt-4 flex flex-col gap-4"
                @submit.prevent="submit">
                <InputPassword
                    v-model="form.password"
                    :error="form.errors.password"
                    autocomplete="new-password" />
                <Button
                    class="w-full"
                    :disabled="form.processing || !form.password.length"
                    type="submit">
                    Submit
                </Button>
            </form>
        </div>
    </div>
</template>
