<script setup>
import ErrorText from '@/Components/UI/ErrorText.vue'
import InputLabel from '@/Components/Input/InputLabel.vue'
import InputDescription from '@/Components/Input/InputDescription.vue'
import { computed } from 'vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    modelValue: {
        type: [String, null],
        default: null
    },
    options: { type: Array },
    value: { type: [Boolean, Object] },
    error: {
        type: [String, null],
        default: null
    },
    label: {
        type: String,
        default: null
    },
    disabled: {
        type: Boolean,
        default: false
    },
    textFirst: {
        type: Boolean,
        default: false
    },
    description: {
        type: String,
        default: null
    }
})

const name = Math.random().toString(36).slice(2)

const proxyChecked = computed({
    get () {
        return props.modelValue
    },

    set (value) {
        emit('update:modelValue', value)
    }
})
</script>

<template>
    <div
        :class="textFirst ? 'md:flex-row-reverse justify-between' : 'md:flex-row'"
        class="flex w-full flex-col-reverse gap-1 group md:gap-0">
        <span class="relative inline-flex items-center gap-2">
            <label
                v-for="option in options"
                :key="option.value"
                :class="proxyChecked === option.value ? 'bg-primary text-white' : ' hover:bg-slate-50 bg-white text-slate-900 dark:text-white dark:bg-slate-800'"
                class="flex cursor-pointer items-center rounded-lg border px-4 py-1 border-slate-800/15 dark:border-white/15">
                <input
                    v-model="proxyChecked"
                    :name="name"
                    :value="option.value"
                    class="hidden h-4 w-4 rounded-full border-gray-300 bg-white text-white checked:border-primary checked:border-4"
                    type="radio">
                <span
                    v-if="option.label"
                    class="select-none text-xs">
                    {{ option.label }}
                </span>
            </label>
        </span>
        <span
            :class="textFirst ? 'pl-0' : 'pl-3'"
            class="flex flex-col">
            <InputLabel
                v-if="label"
                :class="disabled ? 'cursor-default' : ''">
                {{ label }}
            </InputLabel>
            <InputDescription :description="description" />
        </span>
    </div>
    <ErrorText
        v-if="error"
        :error="error" />
</template>
