import { usePageProps } from '@/Composables/usePageProps.ts'

export function usePermissions (permission = '') {
    if (!usePageProps().auth?.check) {
        return false
    }

    if (usePageProps().auth?.user?.permissions.length) {
        const permissions = usePageProps().auth.user.permissions
        return permissions.includes(permission)
    }

    return false
}
