<script setup>

import Button from '@/Components/UI/Button.vue'
import MinimalAuthLayout from '@/Layouts/MinimalAuthLayout.vue'
import InputPassword from '@/Components/Input/InputPassword.vue'
import { useForm } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'

const form = useForm({
    email: null,
    password: null,
    remember: false
})

const emit = defineEmits(['confirmed'])

function submit () {
    form.post(useRoute('password.confirm'), {
        onFinish: () => {
            form.reset()
            emit('confirmed')
        }
    })
}

defineOptions({ layout: MinimalAuthLayout })
</script>

<template>
    <div>
        <div class="mb-4 text-sm text-gray-600">
            This is a secure area of the application. Please confirm your password before continuing.
        </div>
        <form @submit.prevent="submit">
            <InputPassword
                v-model="form.password"
                :error="form.errors.password"
                label="Password"
                placeholder="Password" />
            <div class="mt-4">
                <Button
                    :disabled="form.processing"
                    class="w-full"
                    variant="primary">
                    Confirm
                </Button>
            </div>
        </form>
    </div>
</template>
