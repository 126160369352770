<script setup>
import Button from '@/Components/UI/Button.vue'
import InputText from '@/Components/Input/InputText.vue'
import ConfirmPasswordModal from '@/Components/Auth/ConfirmPasswordModal.vue'
import { computed, ref, watch } from 'vue'
import { router, useForm } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { useMakeRequest } from '@/Composables/useMakeRequest.js'

const props = defineProps({
    requiresConfirmation: Boolean
})

const enabling = ref(false)
const confirming = ref(false)
const disabling = ref(false)
const qrCode = ref(null)
const setupKey = ref(null)
const recoveryCodes = ref([])

const confirmationForm = useForm({
    code: ''
})

const twoFactorEnabled = computed(
    () => !enabling.value && usePageProps().auth?.two_factor_enabled
)

watch(twoFactorEnabled, () => {
    if (!twoFactorEnabled.value) {
        confirmationForm.reset()
        confirmationForm.clearErrors()
    }
})

const enableTwoFactorAuthentication = () => {
    enabling.value = true

    router.post(useRoute('two-factor.enable'), {}, {
        preserveScroll: true,
        onSuccess: () => Promise.all([
            showQrCode(),
            showSetupKey(),
            showRecoveryCodes()
        ]),
        onFinish: () => {
            enabling.value = false
            confirming.value = props.requiresConfirmation
        }
    })
}

const showQrCode = () => {
    return fetch(useRoute('two-factor.qr-code')).then(async response => {
        const results = await response.json()
        qrCode.value = results.svg
    })
}

const showSetupKey = () => {
    return fetch(useRoute('two-factor.secret-key')).then(async response => {
        const results = await response.json()
        setupKey.value = results.secretKey
    })
}

const showRecoveryCodes = () => {
    return fetch(useRoute('two-factor.recovery-codes')).then(async response => {
        recoveryCodes.value = await response.json()
    })
}

const confirmTwoFactorAuthentication = () => {
    confirmationForm.post(useRoute('two-factor.confirm'), {
        errorBag: 'confirmTwoFactorAuthentication',
        preserveScroll: true,
        preserveState: true,
        onSuccess: () => {
            confirming.value = false
            qrCode.value = null
            setupKey.value = null
        }
    })
}

const regenerateRecoveryCodes = () => {
    useMakeRequest(useRoute('two-factor.recovery-codes'), 'POST', {})
        .then(results => {
            if (results.confirmed === true) {
                showRecoveryCodes()
            }
        })

    // axios.post(useRoute('two-factor.recovery-codes'))
    //     .then(() => showRecoveryCodes());
}

const disableTwoFactorAuthentication = () => {
    disabling.value = true

    router.delete(useRoute('two-factor.disable'), {
        preserveScroll: true,
        onSuccess: () => {
            disabling.value = false
            confirming.value = false
        }
    })
}
</script>

<template>
    <div>
        <div class="top-6 right-4 flex md:pt-0.5 md:absolute">
            <span
                v-if="twoFactorEnabled && !confirming"
                class="rounded-full bg-green-200 px-3 py-1 text-xs text-green-900">
                Enabled
            </span>

            <span
                v-else-if="twoFactorEnabled && confirming"
                class="rounded-full bg-orange-200 px-3 py-1 text-xs text-orange-900">
                Please confirm to enable
            </span>

            <span
                v-else
                class="rounded-full bg-red-200 px-3 py-1 text-xs text-red-900">
                Not enabled
            </span>
        </div>

        <div class="mt-4 max-w-none prose">
            <p class="text-sm">
                When two factor authentication is enabled, you will be prompted for a secure, random token during
                authentication. You may retrieve this token from your phone's Authenticator application.
            </p>

            <div v-if="twoFactorEnabled">
                <div v-if="qrCode">
                    <div>
                        <p v-if="confirming">
                            To finish enabling two factor authentication, scan the following QR code using your phone's
                            authenticator application or enter the setup key and provide the generated OTP code.
                        </p>

                        <p v-else>
                            Two factor authentication is now enabled. Scan the following QR code using your phone's
                            authenticator application or enter the setup key.
                        </p>
                    </div>

                    <div
                        class="mt-4 inline-block bg-white p-2"
                        v-html="qrCode" />

                    <div
                        v-if="setupKey"
                        class="-mt-4">
                        <p class="text-sm font-semibold">
                            Setup Key: <span v-html="setupKey" />
                        </p>
                    </div>

                    <div v-if="confirming">
                        <InputText
                            v-model="confirmationForm.code"
                            :error="confirmationForm.errors.code"
                            autocomplete="one-time-code"
                            autofocus
                            inputmode="numeric"
                            label="Generated code"
                            name="code"
                            type="text"
                            @keyup.enter="confirmTwoFactorAuthentication"
                        />
                    </div>
                </div>

                <div v-if="recoveryCodes.length > 0 && !confirming">
                    <div>
                        <p>
                            Store these recovery codes in a secure password manager. They can be used to recover access
                            to your account if your two factor authentication device is lost.
                        </p>
                    </div>

                    <div class="mt-4 mb-8 grid gap-1 rounded-lg p-4 font-mono bg-secondary/10">
                        <div
                            v-for="code in recoveryCodes"
                            :key="code">
                            {{ code }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-8 flex w-full justify-end">
                <div v-if="! twoFactorEnabled">
                    <ConfirmPasswordModal @confirmed="enableTwoFactorAuthentication">
                        <Button
                            :disabled="enabling"
                            type="button"
                            class="px-8"
                            variant="primary">
                            Enable 2FA
                        </Button>
                    </ConfirmPasswordModal>
                </div>

                <div
                    v-else
                    class="flex gap-4">
                    <div v-if="confirming">
                        <Button
                            :disabled="disabling"
                            type="button"
                            variant="white"
                            @click="disableTwoFactorAuthentication">
                            Cancel
                        </Button>
                    </div>

                    <div v-if="!confirming">
                        <ConfirmPasswordModal @confirmed="disableTwoFactorAuthentication">
                            <Button
                                :disabled="disabling"
                                type="button">
                                Disable
                            </Button>
                        </ConfirmPasswordModal>
                    </div>

                    <div v-if="confirming">
                        <ConfirmPasswordModal @confirmed="confirmTwoFactorAuthentication">
                            <Button
                                :disabled="enabling"
                                type="button"
                                variant="primary">
                                Confirm
                            </Button>
                        </ConfirmPasswordModal>
                    </div>

                    <div v-if="recoveryCodes.length > 0 && !confirming">
                        <ConfirmPasswordModal @confirmed="regenerateRecoveryCodes">
                            <Button type="button">
                                Regenerate Recovery Codes
                            </Button>
                        </ConfirmPasswordModal>
                    </div>

                    <div v-if="recoveryCodes.length === 0 && !confirming">
                        <ConfirmPasswordModal @confirmed="showRecoveryCodes">
                            <Button type="button">
                                Show Recovery Codes
                            </Button>
                        </ConfirmPasswordModal>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
