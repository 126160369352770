<script setup>
import VueApexCharts from 'vue3-apexcharts'
import { onMounted, ref } from 'vue'

const props = defineProps({
    viewData: Object,
    showLabels: { type: Boolean, default: true },
    showLabelInTooltip: { type: Boolean, default: false }
})

let dates = Object.keys(props.viewData)
dates = dates.map((date) => {
    const dateObject = new Date(date)
    const month = ('0' + (dateObject.getMonth() + 1)).slice(-2)
    return dateObject.getFullYear() + '-' + month + '-' + dateObject.getDate()
    // return dateObject.getDate() + '/' + dateObject.getMonth() + '/' + dateObject.getFullYear().toString().slice(2, 4)
    // return dateObject.getDate() + '/' + dateObject.getMonth()
})

const options = ref({
    chart: {
        zoom: false,
        dataLabels: {
            enabled: false
        },
        height: '100%',
        maxWidth: '100%',
        width: '100%',
        type: 'area',
        fontFamily: 'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, sans-serif',
        dropShadow: {
            enabled: false
        },
        toolbar: {
            show: false
        }
    },
    zoom: {
        enabled: false
    },
    tooltip: {
        enabled: true,
        x: {
            show: props.showLabelInTooltip
        },
        style: {
            fontSize: '14px',
            fontFamily: 'Poppins, Arial, sans-serif'
        }
    },
    fill: {
        type: 'gradient',
        gradient: {
            shade: 'dark',
            type: 'vertical',
            shadeIntensity: 1,
            gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 100],
            colorStops: [
                {
                    offset: 0,
                    color: 'var(--colors-primary)',
                    opacity: 1
                },
                {
                    offset: 50,
                    color: 'var(--colors-primary)',
                    opacity: 0.5
                },
                {
                    offset: 100,
                    color: 'var(--colors-primary)',
                    opacity: 0
                }
            ]
        }
        // gradient: {
        //     opacityFrom: 1,
        //     opacityTo: 0,
        //     gradientToColors: ['#000']
        // }
    },
    colors: ['var(--colors-primary)'],
    dataLabels: {
        enabled: false
    },
    stroke: {
        width: 4
    },
    grid: {
        show: false,
        strokeDashArray: 4,
        padding: {
            left: 2,
            right: 2,
            top: 0
        }
    },
    yaxis: {
        show: props.showLabels,
        labels: {
            formatter: function (val) {
                return val.toFixed(0)
            },
            style: {
                fontSize: '12px',
                fontFamily: 'Poppins, Arial, sans-serif',
                fontWeight: 500
            }
        }
    },
    xaxis: {
        show: props.showLabels,
        type: 'text',
        categories: dates,
        labels: {
            rotate: 0,
            rotateAlways: false,
            trim: false,
            show: props.showLabels,
            style: {
                fontSize: '12px',
                fontFamily: 'Poppins, Arial, sans-serif',
                fontWeight: 500
            }
        },
        axisBorder: {
            show: false
        },
        axisTicks: {
            show: true
        },
        tooltip: {
            enabled: false,
            offsetY: 0,
            style: {
                fontSize: '20px',
                fontFamily: 0
            }
        }
    }
})

const series = ref([{
    name: 'Views',
    data: Object.values(props.viewData)
}])

const renderChart = ref(false)

onMounted(() => {
    setTimeout(() => {
        renderChart.value = true
    }, 100)
})
</script>

<template>
    <div class="dark:text-black">
        <div v-if="!renderChart">
            Loading...
        </div>
        <VueApexCharts
            v-else
            :options="options"
            :series="series"
            height="100%"
            type="area" />
    </div>
</template>
