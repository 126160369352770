export function useHexToRgb (hex) {
    if (hex.includes('var')) {
        const hexString = hex.replaceAll('var(', '').replaceAll(')', '')
        hex = getComputedStyle(document.documentElement)
            .getPropertyValue(hexString)
    }
    // Determine if colour picker text will be white or dark depending on the chosen color
    const hexcolor = hex.replace('#', '')
    const r = parseInt(hexcolor.substr(0, 2), 16)
    const g = parseInt(hexcolor.substr(2, 2), 16)
    const b = parseInt(hexcolor.substr(4, 2), 16)
    return `${r}, ${g}, ${b}`
}
