<script setup>

import VueSelect from 'vue3-select'
import ErrorText from '@/Components/UI/ErrorText.vue'
import InputLabel from '@/Components/Input/InputLabel.vue'
import { computed, onMounted, ref } from 'vue'

const props = defineProps({
    modelValue: {
        type: Object ?? null,
        default: null
    },
    options: Array,
    displayLabel: {
        type: String,
        default: 'title'
    },
    error: String,
    label: String,
    clearable: {
        type: Boolean,
        default: true
    },
    searchable: {
        type: Boolean,
        default: true
    },
    placeholder: {
        type: String,
        default: 'Select an option...'
    }
})

const emit = defineEmits(['update:modelValue'])

const proxyValue = computed({
    get () {
        return props.modelValue
    },

    set (value) {
        emit('update:modelValue', value)
    }
})

const countClasses = 'font-mono number px-2 text-xs bg-background dark:bg-white/10 dark:text-white text-primary flex items-center justify-center rounded-full'

const id = ref('')
onMounted(() => {
    id.value = 'input' + (Math.random() + 1).toString(36).substring(7)
})
</script>

<template>
    <div>
        <div class="relative">
            <InputLabel
                v-if="label"
                :for-input="id">
                {{ label }}
            </InputLabel>
            <VueSelect
                v-model="proxyValue"
                :class="error ? 'error' : ''"
                :label="displayLabel"
                :searchable="searchable"
                :options="options"
                :clearable="clearable"
                :placeholder="placeholder">
                <template #option="option">
                    <div
                        v-if="option['count'] === undefined"
                        class="py-0.5"
                        v-html="option[displayLabel]" />
                    <div
                        v-else
                        class="flex w-full items-center justify-between group">
                        <span>{{ option[displayLabel] }}</span>
                        <span
                            class="transition-all group-hover:bg-white dark:group-hover:bg-primary"
                            :class="countClasses">
                            {{ option.count }}
                        </span>
                    </div>
                </template>
                <template #selected-option="option">
                    <span
                        v-if="option['count'] === undefined"
                        v-html="option[displayLabel]" />
                    <div
                        v-else
                        class="flex w-full items-center justify-between pr-2 group">
                        <span>{{ option[displayLabel] }}</span>
                        <span
                            :class="countClasses">
                            {{ option.count }}
                        </span>
                    </div>
                </template>
            </VueSelect>
            <ErrorText
                v-if="error"
                :error="error" />
        </div>
    </div>
</template>
