import { ArticleAccessEnum } from '@/Types/ArticleAccess.ts'
import { GlobeAltIcon, LockClosedIcon, UserGroupIcon, UserIcon } from '@heroicons/vue/24/outline'
import {
    GlobeAltIcon as GlobeAltIconSolid,
    LockClosedIcon as LockClosedIconSolid,
    UserGroupIcon as UserGroupIconSolid,
    UserIcon as UserIconSolid
} from '@heroicons/vue/24/solid'

export function useArticleAccessInfo (key: string = ArticleAccessEnum.ALL, solid: boolean = false) {
    const map = [
        {
            type: ArticleAccessEnum.ALL,
            icon: null,
            tooltip: ''
        },
        {
            type: ArticleAccessEnum.PASSWORD,
            icon: solid ? LockClosedIconSolid : LockClosedIcon,
            tooltip: 'This article is password protected.'
        },
        {
            type: ArticleAccessEnum.SUBSCRIBERS,
            icon: solid ? UserGroupIconSolid : UserGroupIcon,
            tooltip: 'This article is only available to subscribers.'
        },
        {
            type: ArticleAccessEnum.AUTHOR,
            icon: solid ? UserIconSolid : UserIcon,
            tooltip: 'This article is only available to you.'
        },
        {
            type: ArticleAccessEnum.LINKED,
            icon: solid ? GlobeAltIconSolid : GlobeAltIcon,
            tooltip: 'This article is not publicly available.'
        }
    ]

    return map.find(item => item.type === key) || map[0]
}
