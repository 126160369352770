<script setup>
import Modal from '@/Components/UI/Modal.vue'
import Button from '@/Components/UI/Button.vue'
import InputText from '@/Components/Input/InputText.vue'
import InputRadio from '@/Components/Input/InputRadio.vue'
import { onMounted, ref } from 'vue'
import { useForm } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'

const props = defineProps({
    comment: Object,
    show: {
        type: Boolean,
        default: false
    }
})

const form = useForm({
    reason: null,
    reason_text: null
})

const open = ref(false)

function close () {
    open.value = false
    emit('close')
    setTimeout(() => {
        reported.value = false
    }, 200)
}

onMounted(() => {
    if (props.show) {
        open.value = true
    }
})

const emit = defineEmits(['reported', 'close'])

const reported = ref(false)

function submit () {
    form.post(useRoute('comment.report', props.comment), {
        preserveState: true,
        preserveScroll: true,
        onSuccess: response => {
            reported.value = true
            form.reset()
        }
    })
}

const reasonOptions = [
    {
        label: 'Spam',
        value: 'spam'
    },
    {
        label: 'Abusive content',
        value: 'abuse'
    },
    {
        label: 'Pornographic content',
        value: 'porn'
    },
    {
        label: 'Other',
        value: 'other'
    }
]
</script>

<template>
    <Modal
        :show="open || show"
        max-width="lg"
        @close="close">
        <template #title>
            Report Comment
        </template>

        <template #content>
            <div class="my-8">
                <div v-if="!reported">
                    <InputRadio
                        v-model="form.reason"
                        :error="form.errors.reason"
                        :options="reasonOptions" />
                    <InputText
                        v-if="form.reason === 'other'"
                        v-model="form.reason_text"
                        :error="form.errors.reason_text"
                        class="mt-4"
                        label="Reason" />
                </div>
                <div v-if="reported">
                    Comment has been reported, we'll let you know the outcome
                </div>
            </div>
        </template>

        <template #footer>
            <div
                :class="reported ? 'justify-end' : 'justify-between'"
                class="flex w-full flex-col-reverse items-center xs:flex-row">
                <Button
                    v-if="!reported"
                    variant="white"
                    @click="close">
                    Cancel
                </Button>

                <Button
                    v-if="!reported"
                    variant="danger"
                    @click="submit">
                    Report
                </Button>
                <Button
                    v-if="reported"
                    variant="primary"
                    @click="close">
                    Okay
                </Button>
            </div>
        </template>
    </Modal>
</template>
