<script setup lang="ts">
import GenresList from '@/Components/Game/GenresList.vue'
import PlatformsList from '@/Components/Game/PlatformsList.vue'
import { Link } from '@inertiajs/vue3'
import { computed, type PropType } from 'vue'
import type { Game } from '@/Types/Models/Game'
import type { Genre } from '@/Types/Models/Genre'
import { useRoute } from '@/Composables/useRoute.ts'
import type { Platform } from '@/Types/Models/Platform'
import { useDateFormat, useTimeAgo } from '@vueuse/core'

const props = defineProps({
    game: Object as PropType<Game>,
    platforms: Array as PropType<Platform[]>,
    genres: Array as PropType<Genre[]>,
    alternativeNames: Array as PropType<string[]>
})

const emit = defineEmits(['openModal'])

const displayedCompanies = computed(() => props.game.companies.slice(0, 1))
</script>

<template>
    <div>
        <table class="table-fixed text-left text-sm">
            <tr
                v-if="game.type"
                class="items-start align-top">
                <th
                    scope="col"
                    class="w-24 font-semibold py-1.5">
                    Type
                </th>
                <td class="flex w-full items-center capitalize py-1.5">
                    <Link
                        :href="useRoute('games.index', {types: game.type.replaceAll('_', ' ')})"
                        class="hover:underline">
                        {{ game.type.replaceAll('_', ' ') }}
                    </Link>
                </td>
            </tr>
            <tr class="items-start align-top">
                <th
                    scope="col"
                    class="w-24 font-semibold py-1.5">
                    Released
                </th>
                <td class="flex w-full items-center py-1.5">
                    <span
                        v-if="game.released"
                        v-tooltip="`${useDateFormat(game.release_date, 'DD MMM YYYY').value} (${useTimeAgo(game.release_date).value})`">
                        {{ game.release_date_nice }}
                    </span>
                    <span v-else>
                        TBD
                    </span>
                </td>
            </tr>
            <tr class="items-start align-top">
                <th
                    scope="col"
                    class="w-24 font-semibold py-1.5">
                    Genres
                </th>
                <td class="flex w-full items-center py-1.5">
                    <GenresList :genres />
                </td>
            </tr>
            <tr class="items-start align-top">
                <th
                    scope="col"
                    class="w-24 font-semibold py-1.5">
                    Platforms
                </th>
                <td class="flex w-full items-center py-1.5">
                    <PlatformsList
                        :platforms
                        @open-modal="emit('openModal')" />
                </td>
            </tr>
            <tr
                v-if="game.companies"
                class="items-start align-top">
                <th
                    scope="col"
                    class="w-24 font-semibold py-1.5">
                    Companies
                </th>
                <td class="flex w-full items-center py-1.5">
                    <ul class="flex flex-col gap-1">
                        <li
                            v-for="company in displayedCompanies"
                            :key="company.name">
                            <component
                                :is="company.url ? 'a' : 'span'"
                                :href="company.url"
                                target="_blank"
                                rel="nofollow"
                                :class="company.url ? 'hover:underline' : ''">
                                {{ company.name }}
                            </component>
                        </li>
                        <li
                            v-if="game.companies.length > 1"
                            @click="emit('openModal')">
                            <button class="font-semibold hover:underline">
                                See more...
                            </button>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr
                v-if="game.modes && game.modes.length"
                class="items-start align-top">
                <th
                    scope="col"
                    class="w-24 font-semibold py-1.5">
                    Modes
                </th>
                <td class="flex w-full items-center capitalize py-1.5">
                    <ul class="flex flex-wrap gap-2">
                        <li
                            v-for="mode in game.modes"
                            :key="mode">
                            <Link
                                :href="useRoute('games.index', {modes: mode})"
                                class="hover:underline">
                                {{ mode }}
                            </Link>
                        </li>
                    </ul>
                </td>
            </tr>
        </table>
    </div>
</template>
