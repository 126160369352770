<script setup lang="ts">
import Modal from '@/Components/UI/Modal.vue'
import Button from '@/Components/UI/Button.vue'
import ConfirmPasswordModal from '@/Components/Auth/ConfirmPasswordModal.vue'
import { ref } from 'vue'
import { router } from '@inertiajs/vue3'
import type { User } from '@/Types/Models/User'
import { useRoute } from '@/Composables/useRoute.ts'
import { usePageProps } from '@/Composables/usePageProps.ts'

const props = defineProps<{
    user: User
}>()

const confirmDelete = ref(false)

function deleteAccount () {
    router.delete(useRoute('user.delete', props.user))
}
</script>

<template>
    <div>
        <Modal
            :show="confirmDelete"
            max-width="lg"
            @close="confirmDelete = false">
            <template #title>
                Delete {{ usePageProps().app.name }} account?
            </template>

            <template #content>
                <div class="prose prose-sm">
                    <p>This will delete all articles and comments, and cannot be undone</p>
                </div>
            </template>

            <template #footer>
                <div class="flex w-full flex-row items-center justify-end gap-4">
                    <Button
                        variant="white"
                        @click="confirmDelete = false">
                        Cancel
                    </Button>

                    <ConfirmPasswordModal @confirmed="deleteAccount">
                        <Button
                            variant="danger"
                            type="button">
                            Confirm delete
                        </Button>
                    </ConfirmPasswordModal>
                </div>
            </template>
        </Modal>
        <div class="mt-4 flex justify-end">
            <Button
                class="px-8"
                type="button"
                variant="danger"
                @click="confirmDelete = true">
                Delete
            </Button>
        </div>
    </div>
</template>
