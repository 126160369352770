import { ref } from 'vue'

export function useHasFileInput (fileInput, form, selectedImage, allowedTypes = ['image/png', 'image/jpg', 'image/gif', 'image/jpeg']) {
    const isDragover = ref(false)

    // User has dragged image into element
    function dragover (event) {
        event.preventDefault()
        isDragover.value = true
    }

    // User has stopped dragging image into element
    function dragleave (event) {
        isDragover.value = false
    }

    // User has dropped image into input
    function drop (event) {
        event.preventDefault()

        // Add image to selectedImage, which is a reactive ref
        selectImage(event)

        isDragover.value = false
    }

    // User has pasted an image OR manually selected file OR dropped image
    // Add file object to selectedImage, which is a reactive ref in the component parent
    function selectImage (e) {
        if (e instanceof DragEvent) {
            let files = e.dataTransfer.files
            files = [...files].filter(file => allowedTypes.includes(file.type))
            selectedImage.value = {
                event: e,
                file: files
            }
        } else if (e instanceof ClipboardEvent) {
            if (e.clipboardData.files.length) {
                let files = e.clipboardData.files
                files = [...files].filter(file => allowedTypes.includes(file.type))
                selectedImage.value = {
                    event: e,
                    file: files
                }
            }
        } else {
            let files = fileInput.value.files
            files = [...files].filter(file => allowedTypes.includes(file.type))

            selectedImage.value = {
                event: e,
                file: files
            }
        }

        // Unset the parent component file input
        fileInput.value = null
    }

    // Add image to the reactive form variable
    function selected (file) {
        form.image = file
    }

    // Remove specific image from the reactive form variable
    function removed (index) {
        if (typeof form.image === 'string') {
            form.image = null
            selectedImage.value = {
                file: null
            }
        } else {
            if (form.image && form.image.length > 0) {
                form.image.splice(index, 1)
                selectedImage.value = {
                    file: selectedImage.value.file.splice(index, 1)
                }
            }
        }
    }

    return {
        isDragover,
        dragover,
        drop,
        dragleave,
        selected,
        removed,
        selectImage
    }
}
