<template>
    <transition
        enter-active-class="duration-200 ease-out"
        enter-from-class="translate-y-8 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="duration-100 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-8 opacity-0 sm:translate-y-0">
        <slot />
    </transition>
</template>
