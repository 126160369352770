import FadeUp from './FadeUp.vue'
import ScaleLeft from './ScaleLeft.vue'
import ScaleRight from './ScaleRight.vue'
import DropdownRight from './DropdownRight.vue'

export default {
    ScaleLeft,
    ScaleRight,
    DropdownRight,
    FadeUp
}
