<script setup>
import ErrorText from '@/Components/UI/ErrorText.vue'
import InputLabel from '@/Components/Input/InputLabel.vue'
import InputDescription from '@/Components/Input/InputDescription.vue'
import { computed, getCurrentInstance, ref } from 'vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    modelValue: {
        type: [Array, Boolean],
        default: false
    },
    value: { type: [Boolean, Object] },
    disabled: { type: Boolean },
    textFirst: {
        type: Boolean,
        default: false
    },
    label: {
        type: String,
        default: null
    },
    description: {
        type: String,
        default: null
    },
    error: {
        type: String,
        default: null
    }
})

const proxyChecked = computed({
    get () {
        return props.modelValue
    },

    set (value) {
        emit('update:modelValue', value)
    }
})

const instance = getCurrentInstance()
const uid = ref(instance.uid)
</script>

<template>
    <label
        :class="textFirst ? 'flex-row-reverse justify-between' : null"
        class="flex w-full group">
        <span class="relative inline-flex">
            <input
                :id="`toggle-${uid}`"
                v-model="proxyChecked"
                :disabled="disabled"
                :value="value"
                class="sr-only peer"
                type="checkbox">
            <span
                :class="disabled ? 'opacity-50 cursor-not-allowed' : 'group-hover:bg-slate-300 dark:group-hover:bg-slate-700'"
                class="input-toggle-bg peer h-5 w-9 rounded-full border border-slate-300 dark:border-slate-800 bg-slate-200 dark:bg-slate-700 ring-offset-1 transition-all duration-100 after:absolute after:left-[2px] after:top-[2px] after:h-4 after:w-4 after:rounded-full after:border after:border-slate-300 after:bg-white after:transition-all after:duration-100 after:content-[''] peer-checked:border-primary peer-checked:bg-primary peer-checked:after:translate-x-full peer-checked:after:border-white group-hover:peer-checked:bg-primary-light peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-primary/50" />
        </span>
        <span
            :class="textFirst ? 'pl-0' : 'pl-3'"
            class="flex flex-col">
            <InputLabel
                v-if="label"
                :is-label="false"
                :class="disabled ? 'cursor-default' : ''">
                {{ label }}
            </InputLabel>
            <InputDescription :description="description" />
        </span>
    </label>
    <ErrorText
        v-if="error"
        :error="error" />
</template>
