<script setup>
import Icon from '@/Components/Icon'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import RegisterForm from '@/Components/Auth/RegisterForm.vue'
import MinimalAuthLayout from '@/Layouts/MinimalAuthLayout.vue'

const props = defineProps({
    data: Object
})

const icons = [
    {
        name: 'facebook',
        icon: Icon.Facebook,
        colour: 'text-white bg-[#1778F2]',
        size: 'w-6'
    },
    {
        name: 'google',
        icon: Icon.Google,
        colour: 'bg-[#f8f9fa]',
        size: 'w-5'
    },
    {
        name: 'steam',
        icon: Icon.Steam,
        colour: 'text-white bg-[#171a21]',
        size: 'w-5'
    },
    {
        name: 'discord',
        icon: Icon.Discord,
        colour: 'bg-[#7289DA] text-white',
        size: 'w-5'
    }
]

function getIcon () {
    return icons.filter(icon => icon.name === props.data.provider)[0]
}

defineOptions({ layout: MinimalAuthLayout })
</script>

<template>
    <div class="relative">
        <div class="relative mx-auto mb-3 aspect-square w-28 rounded-full bg-primary/20">
            <img
                v-if="data.avatar"
                :src="data.avatar"
                alt="Temporary avatar"
                class="h-full w-full rounded-full border border-secondary/10">
            <div
                :class="getIcon().colour"
                class="absolute right-0 bottom-0 flex h-10 w-10 translate-x-2 translate-y-2 items-center justify-center rounded-full border-4 border-white">
                <component
                    :is="getIcon().icon"
                    :class="[getIcon().size, 'text-inherit']" />
            </div>
        </div>
        <SectionTitle class="mt-4 text-center !text-2xl">
            Hi there, {{ data.name }}!
        </SectionTitle>
        <p class="text-center text-sm">
            Fill in your details below to continue signing up with {{ data.provider }}
        </p>
        <RegisterForm
            :data="data"
            :display-socials="false"
            class="mt-8" />
    </div>
</template>
