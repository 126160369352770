<script setup lang="ts">

import ErrorText from '@/Components/UI/ErrorText.vue'
import { onMounted, ref } from 'vue'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

defineProps({
    modelValue: String,
    label: String,
    error: {
        type: [String, null],
        default: null
    },
    hasAutofocus: {
        type: Boolean,
        default: false
    },
    placeholder: {
        type: String,
        default: ''
    },
    inputClass: {
        type: String,
        default: ''
    },
    inputType: {
        type: String,
        default: 'text'
    },
    clearable: {
        type: Boolean,
        default: true
    }
})
const emit = defineEmits(['update:modelValue', 'enter', 'focus', 'blur', 'paste'])

const breakpoints = useBreakpoints(breakpointsTailwind)
const mdBreakpoint = ref<keyof typeof breakpointsTailwind>('md')
const isDesktop = breakpoints.greaterOrEqual(mdBreakpoint.value)

const id = ref('article-title')
const textarea = ref(null)
const textareaHeight = ref(isDesktop.value ? 64 : 12)

function calculateHeight () {
    textarea.value.style.height = textareaHeight.value + 12 + 'px'
    textarea.value.style.height = textarea.value.scrollHeight + 12 + 'px'
}

function updateValue (event) {
    emit('update:modelValue', event.target.value)
    calculateHeight()
}

onMounted(() => {
    calculateHeight()
})

window.addEventListener('resize', calculateHeight)
</script>

<template>
    <div class="relative">
        <label
            v-if="!modelValue"
            :for="id"
            class="pointer-events-none absolute top-0 left-0 mt-2 select-none text-2xl opacity-50 font-heading text-pretty text-secondary dark:text-white sm:text-4xl lg:leading-[1.2] lg:text-5xl">
            {{ label }}
        </label>
        <textarea
            :id="id"
            ref="textarea"
            :class="inputClass"
            :placeholder="placeholder"
            :style="`height:${textareaHeight}px;`"
            :value="modelValue"
            class="mt-2 w-full resize-none bg-transparent p-0 text-2xl font-heading text-pretty text-secondary focus:outline-0 dark:text-white sm:text-4xl lg:leading-[1.2] lg:text-5xl"
            @blur="emit('blur')"
            @focus="emit('focus')"
            @input="updateValue"
            @paste="emit('paste', $event)" />
        <ErrorText
            v-if="error"
            :error="error" />
    </div>
</template>
