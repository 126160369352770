<script setup>
import flatPickr from 'vue-flatpickr-component'
import InputLabel from '@/Components/Input/InputLabel.vue'
import { onMounted, ref } from 'vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    modelValue: [String, Date],
    label: String,
    placeholder: String,
    time: {
        type: Boolean,
        default: true
    },
    futureOnly: {
        type: Boolean,
        default: true
    },
    error: {
        type: [String, null],
        default: null
    }
})

const options = ref({
    enableTime: props.time,
    disableMobile: true,
    minuteIncrement: 1,
    dateFormat: 'Y-m-d H:i',
    altInput: true,
    altFormat: 'F J Y h:i K',
    minDate: props.futureOnly ? 'today' : null
})

const valueProxy = ref(null)
const id = ref('')

function change (e) {
    const date = new Date(e.target.value)
    emit('update:modelValue', date)
}

onMounted(() => {
    id.value = 'dateinput' + (Math.random() + 1).toString(36).substring(7)
    valueProxy.value = new Date(props.modelValue)
})
</script>

<template>
    <div>
        <div class="relative w-full">
            <InputLabel
                v-if="label"
                :for-input="id">
                {{ label }}
            </InputLabel>
            <flat-pickr
                v-model="valueProxy"
                :config="options"
                :placeholder="placeholder"
                class="w-full rounded-lg border px-3 py-2 text-sm transition-colors border-primary/20 focus:border-primary focus:outline-none dark:bg-slate-800 dark:text-white dark:disabled:text-white/50"
                @change="change" />
        </div>
        <div v-if="error">
            {{ error }}
        </div>
    </div>
</template>
