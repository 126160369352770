<script setup>
import PushNotificationService from '@/Services/PwaNotificationsService.ts'
import { onMounted, ref } from 'vue'
import { Link } from '@inertiajs/vue3'
import { useTitle } from '@vueuse/core'
import { useRoute } from '@/Composables/useRoute.ts'
import { BellIcon } from '@heroicons/vue/24/outline'
import { useIsAuthed } from '@/Composables/useIsAuthed.js'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { useMakeRequest } from '@/Composables/useMakeRequest.js'
import { BellIcon as SolidBellIcon } from '@heroicons/vue/24/solid'
import { useBroadcastNotifications } from '@/Composables/useBroadcastNotifications.js'

const count = ref(usePageProps().auth.notifications)

function getNotificationsCount () {
    useMakeRequest(useRoute('api.notifications.count'), 'GET').then(response => {
        const number = response.unreadCount
        count.value = number

        setTimeout(() => {
            const originalTitle = usePageProps()?.meta?.title
            if (number > 0) {
                useTitle(`(${number}) ${originalTitle}`)
                if ('setAppBadge' in navigator) {
                    navigator.setAppBadge(number)
                }
            } else {
                useTitle(originalTitle)
                if ('setAppBadge' in navigator) {
                    navigator.clearAppBadge()
                }
            }
        }, 500)
    })
}

// When pusher receives a new notification
useBroadcastNotifications(() => getNotificationsCount())

// When pusher receives a notifications update
if (useIsAuthed() && window.Echo !== null) {
    window.Echo.private(`App.Models.User.${usePageProps().auth.user.id}`)
        .listen('Users.NotificationsUpdatedEvent', getNotificationsCount)
}

function requestPermission () {
    const pushService = new PushNotificationService()
    pushService.subscribeToPushNotifications()
}

onMounted(() => {
    getNotificationsCount()

    // 10 minutes
    setInterval(getNotificationsCount, 600000)
})
</script>

<template>
    <Link
        v-tooltip="`${(count ? `${count} ` : '')}Notifications`"
        :href="useRoute('dashboard.user.notifications')"
        class="relative group"
        @click="requestPermission">
        <span class="sr-only">{{ count }} notifications</span>
        <div
            v-if="count > 0"
            class="absolute flex aspect-square w-3 items-center justify-center rounded-full border-2 border-white text-xs text-white -left-0.5 -top-0.5 bg-primary" />
        <BellIcon
            v-if="count === 0"
            class="w-5 text-slate-900/75 group-hover:text-primary dark:text-white md:w-[22px] dark:group-hover:text-primary-lighter" />
        <SolidBellIcon
            v-if="count > 0"
            class="w-5 text-primary dark:text-white md:w-[22px]" />
    </Link>
</template>
