<script setup>
import Card from '@/Components/UI/Card.vue'
import Button from '@/Components/UI/Button.vue'
import InputText from '@/Components/Input/InputText.vue'
import InputLabel from '@/Components/Input/InputLabel.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import InputSelect from '@/Components/Input/InputSelect.vue'
import SocialLinks from '@/Components/Global/SocialLinks.vue'
import InputTextarea from '@/Components/Input/InputTextarea.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import { useForm } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { useToast } from '@/Composables/useToast.ts'

const props = defineProps({
    email: [String, null],
    name: [String, null],
    subjects: Array
})

const form = useForm({
    email: props.email,
    name: props.name,
    attachments: {},
    subject: null,
    message: ''
})

function submit () {
    form.submit('post', useRoute('api.contact-us.submit'), {
        preserveScroll: true,
        onSuccess: () => {
            useToast('Message sent')
            form.reset()
        }
    })
}
</script>

<template>
    <div
        class="mb-32 px-4"
        itemscope
        itemtype="http://schema.org/Organization">
        <div
            class="mx-auto mt-16 w-full max-w-2xl px-4">
            <SectionTitle
                font-size="text-xl md:text-4xl"
                class="mb-2 text-center">
                Contact us
            </SectionTitle>
            <SectionDescription class="text-center text-balance">
                We are here to help you. Please fill out the form below and we will get back to you as soon as possible.
            </SectionDescription>
        </div>

        <Card
            class="mx-auto mt-12 max-w-2xl">
            <form
                class="mx-auto flex w-full max-w-2xl flex-col space-y-4"
                @submit.prevent="submit">
                <div class="flex space-x-4">
                    <div class="w-1/2">
                        <InputText
                            v-model="form.name"
                            input-id="name"
                            :error="form.errors.name"
                            label="Name" />
                    </div>
                    <div class="w-1/2">
                        <InputText
                            v-model="form.email"
                            :error="form.errors.email"
                            label="Email"
                            type="email" />
                    </div>
                </div>
                <div>
                    <InputLabel for-input="select">
                        Subject
                    </InputLabel>
                    <InputSelect
                        id="select"
                        v-model="form.subject"
                        :error="form.errors.subject"
                        :options="subjects"
                        display-label="title"
                        placeholder="Select one..." />
                </div>

                <InputTextarea
                    v-model="form.message"
                    :autoheight="true"
                    :error="form.errors.message"
                    input-class="h-40"
                    label="Message" />
                <div class="flex justify-end">
                    <Button
                        :loading="form.processing"
                        class="px-4"
                        variant="primary">
                        Submit
                    </Button>
                </div>
            </form>
        </Card>
    </div>
</template>
