<script setup>
import ErrorText from '@/Components/UI/ErrorText.vue'
import InputLabel from '@/Components/Input/InputLabel.vue'
import { onMounted, ref } from 'vue'
import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
    modelValue: String,
    label: String,
    error: {
        type: [String, null],
        default: null
    },
    hasAutofocus: {
        type: Boolean,
        default: true
    },
    placeholder: {
        type: String,
        default: ''
    },
    autocomplete: {
        type: [String, null],
        default: null
    },
    allowToggle: {
        type: Boolean,
        default: true
    },
    clearable: {
        type: Boolean,
        default: true
    }
})

const emit = defineEmits(['update:modelValue'])

const input = ref(null)
const id = ref('')

const type = ref('password')

// function clear () {
//     input.value.value = null
//     input.value.focus()
//     emit('update:modelValue', '')
// }

function swapType () {
    type.value = type.value === 'password' ? 'text' : 'password'
}

onMounted(() => {
    id.value = 'input' + (Math.random() + 1).toString(36).substring(7)
    if (props.hasAutofocus) {
        setTimeout(() => input.value.focus(), 250)
    }
})
</script>

<template>
    <div>
        <div class="relative">
            <InputLabel
                v-if="label"
                :for-input="id">
                {{ label }}
            </InputLabel>
            <input
                :id="id"
                ref="input"
                :placeholder="placeholder"
                :value="modelValue"
                :autocomplete="autocomplete"
                class="w-full rounded-lg border px-3 py-2 text-sm transition-colors border-primary/20 focus:border-primary focus:outline-none dark:bg-slate-800 dark:text-white dark:disabled:text-white/50"
                :type="type"
                @input="emit('update:modelValue', $event.target.value)">
            <button
                v-if="allowToggle"
                type="button"
                class="absolute text-slate-900/50 bottom-2.5 right-2.5 hover:text-slate-900 focus:outline-none dark:text-white dark:hover:text-white/50"
                @click="
                    swapType">
                <component
                    :is="type === 'password' ? EyeIcon : EyeSlashIcon"
                    class="w-5" />
            </button>
        </div>
        <ErrorText
            v-if="error"
            :error="error" />
    </div>
</template>
