<script setup>
import Modal from '@/Components/UI/Modal.vue'
import useEmitter from '@/Composables/useEmitter.js'
import LoginForm from '@/Components/Auth/LoginForm.vue'
import RegisterForm from '@/Components/Auth/RegisterForm.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'

import { computed, onMounted, ref } from 'vue'
import { usePageProps } from '@/Composables/usePageProps.ts'

const props = defineProps({
    show: {
        type: Boolean,
        default: false
    },
    loginTitle: {
        type: [String, null],
        default: null
    },
    registerTitle: {
        type: [String, null],
        default: null
    },
    defaultForm: {
        type: String,
        default: 'login'
    }
})

function toggleForm () {
    form.value = form.value === 'login' ? 'register' : 'login'
}

const isLogin = computed(() => form.value === 'login')
const isRegister = computed(() => form.value === 'register')

const loginEnabled = computed(() => usePageProps().config.auth.login_enabled)
const registerEnabled = computed(() => usePageProps().config.auth.register_enabled)

const displayLoginTitle = ref(props.loginTitle)
const displayRegisterTitle = ref(props.registerTitle)

const open = ref(false)
const form = ref(props.defaultForm)

const emit = defineEmits(['close'])

function close () {
    open.value = false
    emit('close')
}

onMounted(() => {
    if (props.show) {
        open.value = true
    }

    useEmitter.on('openAuthModal', (event) => {
        open.value = true
        displayLoginTitle.value = event.loginTitle
        displayRegisterTitle.value = event.registerTitle
        form.value = event.defaultForm
    })
})
</script>

<template>
    <div>
        <div @click="open = !open">
            <slot name="trigger" />
        </div>
        <Modal
            :closeable="true"
            :max-width="isLogin ? 'md' : 'md'"
            :show="open || show"
            @close="close">
            <template #content>
                <div
                    class="pb-6 text-center text-base text-slate-900 dark:text-white">
                    <h1 class="mb-2 text-2xl font-semibold font-heading">
                        <span v-if="isLogin">
                            {{ displayLoginTitle ? displayLoginTitle : `Log in to ${usePageProps().app.name}` }}
                        </span>
                        <span v-if="isRegister">
                            {{ displayRegisterTitle ? displayRegisterTitle : `Join ${usePageProps().app.name} for free`
                            }}
                        </span>
                    </h1>
                    <p
                        v-if="isRegister && registerEnabled"
                        class="mt-0 text-sm">
                        Get started by creating an account today!
                    </p>

                    <div class="mt-8">
                        <SectionDescription v-if="(isLogin && !loginEnabled) || (isRegister && !registerEnabled)">
                            This feature has been disabled.
                        </SectionDescription>

                        <LoginForm
                            v-if="isLogin && loginEnabled"
                            @success="close" />
                        <RegisterForm
                            v-if="isRegister && registerEnabled"
                            @success="close" />
                    </div>
                </div>
            </template>
            <template #footer>
                <div
                    v-if="(isLogin && registerEnabled || isRegister && loginEnabled)"
                    class="flex w-full flex-row items-center justify-end gap-4">
                    <div class="w-full xs:justify-end">
                        <div
                            v-if="isLogin && registerEnabled"
                            class="flex items-end justify-center">
                            <button
                                class="my-2 items-center justify-center rounded-lg px-4 py-2 text-sm transition-colors text-primary hover:bg-primary/10 dark:text-white"
                                @click="toggleForm">
                                Don't have an account?
                                <span class="ml-1 font-semibold">
                                    Sign up for free
                                </span>
                            </button>
                        </div>

                        <div
                            v-if="isRegister && loginEnabled"
                            class="flex items-end justify-center">
                            <button
                                class="my-2 items-center justify-center rounded-lg px-4 py-2 text-sm transition-colors text-primary hover:bg-primary/10 dark:text-white"
                                @click="toggleForm">
                                Already have an account?
                                <span class="ml-1 font-semibold">
                                    Log in
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>
