import { usePageProps } from '@/Composables/usePageProps.ts'

export function useImageOptimise (url) {
    const app = usePageProps().app

    // Early return if image optimization is not enabled
    if (!app.imageOptimise.enabled) {
        return url
    }

    if (url === null || url === '') {
        return url
    }

    const originalUrl = app.url
    const type = app.imageOptimise.type
    const performanceUrl = app.imageOptimise.url

    const cleanPerformanceUrl = performanceUrl.replace(/\/$/, '')

    const notEmpty = (value) => {
        return value !== undefined && value !== null && value !== ''
    }

    if (notEmpty(url)) {
        switch (type) {
        case 'replace':
            return url.replaceAll(originalUrl, cleanPerformanceUrl)
        case 'prefix':
            return `${cleanPerformanceUrl}/${url}`
        default:
            return url
        }
    }

    return url
}
