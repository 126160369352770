import { useIsAuthed } from '@/Composables/useIsAuthed.js'
import { usePageProps } from '@/Composables/usePageProps.ts'

export function useBroadcastNotifications (successCallback) {
    if (useIsAuthed() && window.Echo !== null) {
        window.Echo.private(`App.Models.User.${usePageProps().auth.user.id}`)
            .notification(async (notification) => {
                successCallback(notification)
            })
    }
}
