<script setup>
import * as Status from '@/Enums/Status'
import * as UserPermission from '@/Enums/UserPermission'
import Button from '@/Components/UI/Button.vue'
import useEmitter from '@/Composables/useEmitter'
import { router } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { usePermissions } from '@/Composables/usePermissions'
import {
    EyeSlashIcon,
    BackspaceIcon,
    PencilIcon,
    TrashIcon,
    ArrowUturnLeftIcon,
    ArrowPathRoundedSquareIcon
} from '@heroicons/vue/24/outline'

const props = defineProps({
    article: Object,
    viewData: Object
})

function restoreArticle () {
    router.patch(useRoute('article.restore', props.article))
}

const canDelete = props.article.status.name !== Status.TRASHED && (usePermissions(UserPermission.DELETE_ANY_ARTICLE) ||
    (props.article.user.id === usePageProps().auth.user.id && usePermissions(UserPermission.DELETE_OWN_ARTICLE)))

const canPermanentlyDelete = props.article.status.name === Status.TRASHED && (usePermissions(UserPermission.DELETE_ANY_ARTICLE) ||
    (props.article.user.id === usePageProps().auth.user.id && usePermissions(UserPermission.DELETE_OWN_ARTICLE)))

const canRestore = props.article.status.name === Status.TRASHED && (usePermissions(UserPermission.RESTORE_ANY_ARTICLE) ||
    (props.article.user.id === usePageProps().auth.user.id && usePermissions(UserPermission.RESTORE_OWN_ARTICLE)))

const canEdit = [Status.DRAFTS, Status.REJECTED].includes(props.article.status.name) && (usePermissions(UserPermission.EDIT_ANY_ARTICLE) ||
    (props.article.user.id === usePageProps().auth.user.id && usePermissions(UserPermission.EDIT_OWN_ARTICLE)))

const canUnpublish = [Status.PUBLISHED].includes(props.article.status.name) && (usePermissions(UserPermission.UNPUBLISH_ANY_ARTICLE) ||
    (props.article.user.id === usePageProps().auth.user.id && usePermissions(UserPermission.UNPUBLISH_OWN_ARTICLE)))

const canWithdraw = [Status.APPROVED, Status.SUBMITTED].includes(props.article.status.name) && (props.article.user.id === usePageProps().auth.user.id)

const actionButtons = [
    // {
    //     href: props.article.permalink,
    //     icon: ArrowTopRightOnSquareIcon,
    //     text: 'View',
    //     variant: 'white',
    //     if: props.article.status.name === Status.PUBLISHED
    // },
    {
        href: useRoute('article.edit', props.article),
        icon: PencilIcon,
        text: 'Edit',
        variant: 'primary-light',
        if: canEdit
    },
    {
        action: () => useEmitter.emit('article:withdraw', props.article),
        icon: ArrowUturnLeftIcon,
        text: 'Withdraw',
        variant: 'danger-light',
        if: canWithdraw
    },
    {
        action: () => useEmitter.emit('article:unpublish', props.article),
        icon: EyeSlashIcon,
        text: 'Unpublish',
        variant: 'danger-light',
        if: canUnpublish
    },
    {
        action: () => useEmitter.emit('article:delete', props.article),
        icon: BackspaceIcon,
        text: 'Trash',
        variant: 'danger',
        if: canDelete
    },
    {
        action: () => restoreArticle(),
        icon: ArrowPathRoundedSquareIcon,
        text: 'Restore',
        variant: 'primary-light',
        if: canRestore
    },
    {
        action: () => useEmitter.emit('article:delete:permanent', props.article),
        icon: TrashIcon,
        text: 'Delete',
        variant: 'danger',
        if: canPermanentlyDelete
    }
]

function handleClick (text) {
    const action = actionButtons.find(button => button.text === text)

    if (!action) return
    if (action.href) return

    action.action()
}
</script>

<template>
    <div class="flex h-full w-full flex-wrap gap-4 md:justify-end md:gap-2 md:text-sm">
        <template
            v-for="(button, index) in actionButtons"
            :key="index">
            <Button
                v-if="button.if"
                :href="button.href ?? null"
                class="inline-flex text-xs"
                padding="px-2 py-1"
                :variant="button.variant ?? 'primary'"
                :target="button.text.toLowerCase() === 'view' ? '_blank' : null"
                @click="handleClick(button.text)">
                <div class="flex">
                    <component
                        :is="button.icon"
                        class="mr-2 w-4" />
                    <span v-html="button.text" />
                </div>
            </Button>
        </template>
    </div>
</template>
