<script setup>
import DropdownItem from '@/Components/UI/DropdownItem.vue'
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'

defineProps({
    items: {
        type: Array,
        default: () => []
    },
    shortcut: {
        type: [String, null]
    },
    menuWidth: {
        type: String,
        default: 'w-56'
    },
    isOpen: {
        type: [Boolean, null],
        default: false
    }
})

const notEmpty = (value) => {
    return value !== undefined && value !== null && value !== ''
}

function shouldShow (item) {
    let canShow = (notEmpty(item.if) ? item.if : true)

    if (typeof item.permission !== 'undefined') {
        canShow = item.permission === true
    }

    return canShow
}
</script>

<template>
    <Menu as="div">
        <MenuButton class="flex items-center rounded-full">
            <slot name="toggle" />
        </MenuButton>
        <Transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="scale-95 transform opacity-0"
            enter-to-class="scale-100 transform opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="scale-100 transform opacity-100"
            leave-to-class="scale-95 transform opacity-0"
        >
            <MenuItems
                :class="[menuWidth]"
                class="dropdown-container right-4 border border-transparent dark:border-white/10 origin-top-right max-h-[calc(100dvh-4rem)] overflow-auto bg-white dark:bg-slate-800 absolute mt-2"
            >
                <slot name="items" />
                <div
                    v-for="(item, index) in items"
                    :key="index">
                    <div
                        v-if="item.url === null && item.title === 'sep' && shouldShow(item)"
                        class="my-2 w-full">
                        <div class="h-px w-full bg-tertiary dark:bg-slate-950" />
                    </div>
                    <MenuItem
                        v-else-if="shouldShow(item)"
                        v-slot="{ active }"
                        :item="item">
                        <DropdownItem
                            :active="active"
                            :item="item" />
                    </MenuItem>
                </div>
            </MenuItems>
        </Transition>
    </Menu>
</template>
