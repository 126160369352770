<script setup>
import Modal from '@/Components/UI/Modal.vue'
import Button from '@/Components/UI/Button.vue'
import InputPassword from '@/Components/Input/InputPassword.vue'

import { ref, reactive, nextTick } from 'vue'
import { useRoute } from '@/Composables/useRoute.ts'
import { usePageProps } from '@/Composables/usePageProps.ts'

const emit = defineEmits(['confirmed'])

defineProps({
    title: {
        type: String,
        default: 'Confirm Password'
    },
    content: {
        type: String,
        default: 'For your security, please confirm your password to continue.'
    },
    button: {
        type: String,
        default: 'Confirm'
    }
})

const confirmingPassword = ref(false)

const form = reactive({
    password: '',
    error: '',
    processing: false
})

const passwordInputField = ref(null)

const startConfirmingPassword = () => {
    fetch(useRoute('password.confirmation')).then(async response => {
        const results = await response.json()
        if (results.confirmed) {
            emit('confirmed')
        } else {
            confirmingPassword.value = true
        }
    })
}

const confirmPassword = async () => {
    form.processing = true

    await fetch(useRoute('password.confirm'), {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
            password: form.password,
            _token: usePageProps().csrf_token
        })
    }).then(() => {
        form.processing = false
        closeModal()
        nextTick().then(() => emit('confirmed'))
    }).catch(error => {
        form.processing = false
        form.error = error.response.data.errors.password[0]
    })
}

const closeModal = () => {
    confirmingPassword.value = false
    form.password = ''
    form.error = ''
}
</script>

<template>
    <span>
        <span @click="startConfirmingPassword">
            <slot />
        </span>

        <Modal
            :show="confirmingPassword"
            max-width="md"
            @close="closeModal">
            <template #title>
                {{ title }}
            </template>

            <template #content>
                {{ content }}

                <div class="mt-4">
                    <InputPassword
                        ref="passwordInputField"
                        v-model="form.password"
                        :error="form.error"
                        :has-autofocus="true"
                        autocomplete="current-password"
                        placeholder="Password"
                        @keyup.enter="confirmPassword"
                    />
                </div>
            </template>

            <template #footer>
                <div class="flex w-full items-center justify-between">
                    <Button
                        type="button"
                        variant="white"
                        @click="closeModal">
                        Cancel
                    </Button>

                    <Button
                        :disabled="form.processing"
                        type="button"
                        @click="confirmPassword">
                        {{ button }}
                    </Button>
                </div>
            </template>
        </Modal>
    </span>
</template>
