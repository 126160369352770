<script setup lang="ts">
import Header from '@/Components/UI/Header.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import ArticleGrid from '@/Components/Article/ArticleGrid.vue'
import EmptyStateAlt from '@/Components/Global/EmptyStateAlt.vue'
import ArticleFilter from '@/Components/Article/ArticleFilter.vue'
import ArticleCreateButton from '@/Components/Article/ArticleCreateButton.vue'
import { router } from '@inertiajs/vue3'
import type { Tag } from '@/Types/Models/Tag'
import { computed, type PropType, ref } from 'vue'
import type { PageHeader } from '@/Types/PageHeader'
import { useSingular } from '@/Composables/useSingular.js'

const props = defineProps({
    articles: [Array, Object] as PropType<any>,
    header: Object as PropType<PageHeader>,
    minCount: {
        type: Number,
        default: 4
    },
    relatedTags: [Array, null] as PropType<Tag[]>,
    hasRelatedTags: {
        type: Boolean,
        default: false
    },
    lazy: {
        type: Boolean,
        default: false
    },
    paginated: {
        type: Boolean,
        default: true
    }
})

const isPaginated = props.paginated
const allArticles = ref(props.articles)
const allRelatedTags = ref(props.relatedTags)

const loadingSortingOptions = ref(props.header?.orderable)
const displaySortingOptions = computed(() => {
    return (isPaginated ? allArticles.value?.data?.length : allArticles.value?.length) > 0
})

function lazyload () {
    const load = []
    if (props.hasRelatedTags) {
        load.push('relatedTags')
    }
    if (props.lazy) {
        load.push('articles')
    }

    if (load.length) {
        router.reload({
            only: load,
            onSuccess: () => {
                allArticles.value = props.articles
                allRelatedTags.value = props.relatedTags
                loadingSortingOptions.value = false
            }
        })
    }
}

router.on('navigate', lazyload)

defineOptions({
    inheritAttrs: false
})

const articleDisplayCount = computed(() => {
    const displayed = isPaginated ? allArticles.value?.data?.length : allArticles.value?.length
    const total = isPaginated ? allArticles.value?.meta?.total : allArticles.value?.length
    if (displayed === total) {
        return total
    }
    return `${displayed} of ${total}`
})
</script>

<template>
    <div class="pb-12">
        <Header
            :has-related-tags="hasRelatedTags"
            :related-tags="allRelatedTags"
            :header="header" />

        <div
            v-if="header?.orderable"
            class="container mx-auto">
            <div class="flex items-center justify-between">
                <div
                    v-if="loadingSortingOptions"
                    class="flex items-center justify-end gap-2 skeleton-animation">
                    <div class="w-40 rounded-lg bg-primary/10 h-[38px] dark:bg-slate-800" />
                </div>
                <SectionTitle
                    v-else-if="displaySortingOptions"
                    class="mr-8 shrink-0">
                    {{ articleDisplayCount }} Articles
                </SectionTitle>

                <div
                    v-if="loadingSortingOptions"
                    class="flex items-center justify-end gap-2 skeleton-animation">
                    <div class="w-40 rounded-lg bg-primary/10 h-[38px] dark:bg-slate-800" />
                    <div class="rounded-lg bg-primary/10 w-[54px] h-[38px] dark:bg-slate-800" />
                </div>

                <ArticleFilter
                    v-if="displaySortingOptions"
                    :data="header.orderData"
                    :route-name="header.orderRoute" />
            </div>
        </div>

        <ArticleGrid
            :key="paginated ? allArticles?.data?.length : allArticles?.length"
            :pagination="paginated ? allArticles?.meta : null"
            :skeleton-count="minCount"
            :articles="paginated ? allArticles?.data : allArticles"
            class="mt-0">
            <template #empty>
                <EmptyStateAlt
                    :description="`There are no published articles here. Try writing your own <em class='lowercase'>${header?.title ? useSingular(header?.title) : ''}</em> article today!`">
                    <template #button>
                        <ArticleCreateButton
                            :append="header?.tag ? { tag: header?.tag.name } : null"
                            text="Write your own" />
                    </template>
                </EmptyStateAlt>
            </template>
        </ArticleGrid>
    </div>
</template>
