import { usePageProps } from '@/Composables/usePageProps.ts'

// eslint-disable-next-line sonarjs/cognitive-complexity
export async function useMakeRequest (url = '', method = 'POST', data = {}) {
    data._token = usePageProps().csrf_token
    let response

    try {
        response = await fetch(url, {
            method, // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                'X-Socket-ID': window.Echo ? window.Echo.socketId() : null
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: method !== 'GET' ? JSON.stringify(data) : null // body data type must match "Content-Type" header
        })
    } catch (error) {
        console.log('There was an error', error)
    }

    if (response?.ok) {
        const contentType = response.headers.get('content-type')
        if (contentType && contentType.includes('application/json')) {
            try {
                return await response.json()
            } catch (error) {
                return { error: 'Failed to parse JSON.' }
            }
        } else if (contentType && contentType.includes('text/html')) {
            try {
                return await response.text()
            } catch (error) {
                return { error: 'Failed to parse HTML.' }
            }
        } else {
            try {
                return await response.blob()
            } catch (error) {
                return { error: 'Failed to parse binary data.' }
            }
        }
    } else {
        return {
            error: response?.status
        }
    }
}
