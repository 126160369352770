<script setup>
import Button from '@/Components/UI/Button.vue'
import { ref } from 'vue'
import { useGetSetting } from '@/Composables/useGetSetting.js'
import { useUpdateSetting } from '@/Composables/useUpdateSetting.js'
import { ArrowTrendingUpIcon, DocumentTextIcon, PlusIcon, UserIcon } from '@heroicons/vue/24/outline'

const show = ref(true)

const updateSetting = useUpdateSetting()

show.value = !useGetSetting('dashboard_welcome_banner_closed')

function closeBanner () {
    updateSetting('dashboard_welcome_banner_closed', true)
    show.value = false
}

const features = [
    {
        name: 'View your quick stats.',
        description: 'Get quick access to important stats like top articles, total article views, and more.',
        icon: ArrowTrendingUpIcon
    },
    {
        name: 'Manage your articles.',
        description: 'Edit your drafts, check the stats and status of your published articles, and more.',
        icon: DocumentTextIcon
    },
    {
        name: 'Create new articles.',
        description: 'Using our powerful article editor, easily create and publish your articles.',
        icon: PlusIcon
    },
    {
        name: 'Edit your public profile.',
        description: 'Manage your account settings, personal information, and profile appearance.',
        icon: UserIcon
    }
]
</script>

<template>
    <div
        v-if="show"
        class="relative mb-12 rounded-2xl py-8 bg-secondary xl:py-18">
        <div class="absolute top-0 right-8">
            <Button
                class="mt-10"
                variant="white"
                @click="closeBanner">
                <span class="text-xs">
                    Close
                </span>
            </Button>
        </div>
        <div class="mx-auto px-6 lg:px-12">
            <div class="mx-auto max-w-2xl lg:mx-0">
                <h2 class="text-base font-semibold leading-7 font-heading text-primary-light">
                    Get started
                </h2>
                <p class="mt-2 text-3xl font-semibold tracking-tight text-white font-heading sm:text-4xl">
                    Welcome to your dashboard.
                </p>
                <p class="mt-6 text-base text-white/75 md:text-lg md:leading-8">
                    Quick access to your articles, notifications, settings, and more.
                    Explore, create, and enjoy!
                </p>
            </div>
            <dl class="mx-auto mt-8 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 text-white/75 sm:grid-cols-2 md:mt-16 lg:mx-0 lg:max-w-none lg:gap-x-16">
                <div
                    v-for="feature in features"
                    :key="feature.name"
                    class="relative pl-9">
                    <dt class="inline font-semibold text-white">
                        <component
                            :is="feature.icon"
                            class="absolute top-1 left-1 h-5 w-5 text-primary-light"
                            aria-hidden="true" />
                        {{ feature.name }}
                    </dt>
                    {{ ' ' }}
                    <dd class="inline">
                        {{ feature.description }}
                    </dd>
                </div>
            </dl>
        </div>
    </div>
</template>
