import Steam from './Steam.vue'
import Google from './Google.vue'
import Reddit from './Reddit.vue'
import Twitch from './Twitch.vue'
import ThickX from './ThickX.vue'
import Threads from './Threads.vue'
import Discord from './Discord.vue'
import Twitter from './Twitter.vue'
import Horizon from './Horizon.vue'
import GamePad from './GamePad.vue'
import EmptyBox from './EmptyBox.vue'
import Facebook from './Facebook.vue'
import UserPlus from './UserPlus.vue'
import Whatsapp from './Whatsapp.vue'
import TwitterX from './TwitterX.vue'
import Linkedin from './Linkedin.vue'
import UserMinus from './UserMinus.vue'
import Checkmark from './Checkmark.vue'
import Dashboard from './Dashboard.vue'
import FencePost from './FencePost.vue'
import Flipboard from './Flipboard.vue'
import Instagram from './Instagram.vue'
import CookieBite from './CookieBite.vue'
import GamePadFilled from './GamePadFilled.vue'
import DocumentSearch from './DocumentSearch.vue'

export default {
    Checkmark,
    CookieBite,
    ThickX,
    GamePad,
    GamePadFilled,
    Linkedin,
    Dashboard,
    Discord,
    DocumentSearch,
    Facebook,
    FencePost,
    Flipboard,
    Google,
    Instagram,
    Reddit,
    Steam,
    Twitch,
    Twitter,
    Threads,
    UserPlus,
    Whatsapp,
    TwitterX,
    EmptyBox,
    UserMinus,
    Horizon
}
