<script setup lang="ts">
import Icon from '@/Components/Icon/index.js'
import Spinner from '@/Components/UI/Spinner.vue'
import { ref } from 'vue'
import { useRoute } from '@/Composables/useRoute.ts'
import { usePageProps } from '@/Composables/usePageProps.ts'

type oauthObject = {
    icon: Object,
    url: string,
    size: string,
    name: string,
    loading: boolean,
    enabled: boolean,
    colour?: string
}

const buttons = ref<oauthObject[]>([
    {
        icon: Icon.Google,
        url: useRoute('oauth.redirect', { provider: 'google' }),
        size: 'w-5',
        name: 'Google',
        loading: false,
        enabled: usePageProps().config.oauth.enabled.includes('google')
    },
    {
        icon: Icon.Twitch,
        colour: 'text-[#6441a5]',
        url: useRoute('oauth.redirect', { provider: 'twitch' }),
        size: 'w-6',
        name: 'Twitch',
        loading: false,
        enabled: usePageProps().config.oauth.enabled.includes('twitch')
    },
    {
        icon: Icon.Discord,
        colour: 'text-[#7289DA]',
        size: 'w-5',
        name: 'Discord',
        url: useRoute('oauth.redirect', { provider: 'discord' }),
        loading: false,
        enabled: usePageProps().config.oauth.enabled.includes('discord')
    }
])

function handleClick (button: oauthObject) {
    button.loading = true
    button.enabled = false
}
</script>

<template>
    <div class="flex items-center justify-center gap-2">
        <a
            v-for="button in buttons"
            :key="button.url"
            :class="[!button.enabled ? 'cursor-not-allowed pointer-events-none opacity-50' : '']"
            :href="button.url"
            class="flex h-10 w-full items-center justify-center rounded-full border bg-white transition-all border-secondary/10 hover:bg-slate-200 dark:bg-slate-950"
            @click="handleClick(button)">
            <span class="sr-only">Login with {{ button.name }}</span>
            <component
                :is="button.loading ? Spinner : button.icon"
                :class="[
                    button.loading ? 'w-5' : button.size,
                    button.colour ?? '',
                    button.loading ? 'text-white' : ''
                ]" />
        </a>
    </div>
</template>
