<script lang="ts" setup>
import InputTagCheckbox from '@/Components/Input/InputTagCheckbox.vue'
import AccordionTransition from '@/Components/Transition/AccordionTransition.vue'
import { computed, ref } from 'vue'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/20/solid'

defineProps({
    title: String,
    items: Array,
    allValue: {
        type: [String, Number],
        default: 0
    },
    itemKey: {
        type: String,
        default: 'id'
    },
    itemLabel: {
        type: String,
        default: 'name'
    }
})

const breakpoints = useBreakpoints(breakpointsTailwind)
const reactiveBreakpoints = ref<keyof typeof breakpointsTailwind>('md')
const isDesktop = computed(() => breakpoints.greaterOrEqual(reactiveBreakpoints.value).value)

const show = ref((!!isDesktop.value))

const model = defineModel()
</script>

<template>
    <div>
        <button
            class="flex w-full items-center justify-between text-lg font-semibold font-heading"
            @click="show = !show">
            <div class="flex items-center gap-2">
                {{ title }}
                <p
                    v-if="!model.includes('all') && !model.includes('All') && !model.includes(0)"
                    class="text-xs text-slate-900/75 font-body mt-[2px]">
                    ({{ model.length }})
                </p>
            </div>

            <ChevronUpIcon
                v-if="show"
                class="w-6" />
            <ChevronDownIcon
                v-else
                class="w-6" />
        </button>
        <AccordionTransition>
            <div v-show="show">
                <ul class="mt-2 flex flex-wrap gap-2 overflow-auto md:max-h-[500px]">
                    <li>
                        <InputTagCheckbox
                            v-model="model"
                            :value="allValue"
                            :disabled="model.length === 1 && model[0] === allValue"
                            :label="`All`" />
                    </li>
                    <li
                        v-for="item in items"
                        :key="item[itemKey]">
                        <InputTagCheckbox
                            v-model="model"
                            :value="item[itemKey]"
                            :label="item[itemLabel]" />
                    </li>
                </ul>
            </div>
        </AccordionTransition>
        <div
            v-if="!show"
            class="h-px w-full translate-y-2 bg-slate-900/10 md:translate-y-4" />
    </div>
</template>
