<script setup lang="ts">
import Icon from '@/Components/Icon/index.js'
import Button from '@/Components/UI/Button.vue'
import UserCard from '@/Components/User/UserCard.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import DashboardLayout from '@/Layouts/DashboardLayout.vue'
import TagFollowButton from '@/Components/Tag/TagFollowButton.vue'
import DashboardTitle from '@/Components/Dashboard/DashboardTitle.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import GameCardHorizontal from '@/Components/Game/GameCardHorizontal.vue'
import type { Tag } from '@/Types/Models/Tag'
import { router, Link } from '@inertiajs/vue3'
import type { User } from '@/Types/Models/User'
import type { Game } from '@/Types/Models/Game'
import { usePlural } from '@/Composables/usePlural'
import { useRoute } from '@/Composables/useRoute.ts'
import { DocumentTextIcon } from '@heroicons/vue/24/outline'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { computed, onMounted, type PropType, ref } from 'vue'
import { FireIcon, TagIcon, UserIcon } from '@heroicons/vue/24/solid'

const props = defineProps({
    empty: {
        type: Boolean,
        default: false
    },
    followingUsers: Array as PropType<User[]>,
    followingTags: Array as PropType<Tag[]>,
    followingGames: Array as PropType<Game[]>,
    recommended: Array as PropType<User[]>
})

defineOptions({
    layout: (h, page) => h(DashboardLayout, {}, () => [page]),
    inheritAttrs: false
})

function loadRecommendedUsers () {
    router.reload({ only: ['recommended'] })
}

const usersLimit = ref(3)
const tagsLimit = ref(4)
const gamesLimit = ref(2)

const displayFollowingUsers = computed(() => {
    if (usersLimit.value === null) {
        return props.followingUsers
    }

    return props.followingUsers.slice(0, usersLimit.value)
})

const displayFollowingTags = computed(() => {
    if (tagsLimit.value === null) {
        return props.followingTags
    }

    return props.followingTags.slice(0, tagsLimit.value)
})

const displayFollowingGames = computed(() => {
    if (gamesLimit.value === null) {
        return props.followingGames
    }

    return props.followingGames.slice(0, gamesLimit.value)
})

onMounted(() => {
    loadRecommendedUsers()
})
</script>

<template>
    <div class="pb-24">
        <DashboardTitle
            title="Following"
            description="Manage your following" />

        <div
            v-if="empty"
            class="mt-5 flex w-full flex-col gap-4 px-4 md:flex-row md:px-0 lg:h-[300px]">
            <div
                class="flex h-full w-full flex-col items-center justify-center rounded-2xl p-8 text-center lg:p-0">
                <SectionTitle>
                    You aren't following anyone
                </SectionTitle>
                <SectionDescription>
                    Discover more writers and gamers on {{ usePageProps().app.name }} by checking out the latest
                    articles.
                </SectionDescription>
                <div class="mt-6">
                    <Button
                        variant="secondary"
                        class="flex"
                        :href="useRoute('article.index')">
                        <div class="flex">
                            <FireIcon class="mr-2 w-4" />
                            Discover more
                        </div>
                    </Button>
                </div>
            </div>
        </div>

        <div class="flex flex-col gap-20">
            <div
                v-if="followingUsers.length">
                <SectionTitle
                    font-size="text-xl"
                    class="flex items-center gap-2">
                    <UserIcon class="w-5" />
                    Users you follow
                </SectionTitle>
                <SectionDescription>
                    Follow your favourite users to get notified whenever they publish new articles.
                </SectionDescription>
                <div
                    v-if="followingUsers.length"
                    class="-mx-2 mt-4 flex flex-wrap">
                    <div
                        v-for="user in displayFollowingUsers"
                        :key="user.username"
                        class="w-full p-2 md:w-1/2 xl:w-1/3">
                        <UserCard
                            class="w-full"
                            :user="user" />
                    </div>
                </div>
                <button
                    v-if="followingUsers.length > displayFollowingUsers.length"
                    class="text-sm transition-colors font-body text-primary hover:text-primary-dark"
                    @click="usersLimit = null">
                    See more
                </button>
            </div>

            <div v-if="followingTags.length">
                <SectionTitle
                    font-size="text-xl"
                    class="flex items-center gap-2">
                    <TagIcon class="w-5" />
                    Tags you follow
                </SectionTitle>
                <SectionDescription>
                    Keep up to date with specific topics by following tags.
                </SectionDescription>
                <div
                    class="-mx-2 mt-4 flex w-full flex-wrap">
                    <div
                        v-for="tag in displayFollowingTags"
                        :key="tag.slug"
                        class="w-full p-2 md:w-1/2 xl:w-1/4">
                        <div class="rounded-2xl border bg-white px-4 py-3 border-primary/10 dark:bg-slate-800 md:p-5">
                            <div class="mb-4 flex items-start justify-between gap-4">
                                <Link
                                    :href="useRoute('article.tagged', tag.slug)"
                                    class="flex items-center gap-1 pt-1 transition-colors hover:text-primary">
                                    <img
                                        v-if="tag.icon && tag.icon.default !== null"
                                        :alt="`Icon image for ${tag.name}`"
                                        :src="tag.icon"
                                        class="mr-2 h-4">
                                    <div class="flex w-full flex-wrap break-words hyphens-auto">
                                        {{ tag.name }}
                                    </div>
                                </Link>
                                <div class="flex shrink-0">
                                    <TagFollowButton
                                        :icon-only="true"
                                        :tag />
                                </div>
                            </div>
                            <div class="mt-4 flex flex-row items-center justify-between md:flex-col lg:flex-row">
                                <div class="flex items-center gap-4">
                                    <div class="flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400">
                                        <Icon.UserPlus class="h-4 w-4" />
                                        <span>{{ tag.followerCount }} {{ usePlural("follower", tag.followerCount) }}</span>
                                    </div>
                                    <div class="flex items-center text-sm text-gray-500 space-x-1 dark:text-gray-400">
                                        <DocumentTextIcon class="h-4 w-4" />
                                        <span>{{ tag.articleCount }} {{ usePlural("article", tag.articleCount) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button
                    v-if="followingTags.length > displayFollowingTags.length"
                    class="text-sm transition-colors font-body text-primary hover:text-primary-dark"
                    @click="tagsLimit = null">
                    See more
                </button>
            </div>

            <div v-if="followingGames.length">
                <SectionTitle
                    font-size="text-xl"
                    class="flex items-center gap-2">
                    <Icon.GamePadFilled class="w-5" />
                    Games you follow
                </SectionTitle>
                <SectionDescription>
                    Get notified of new articles and updates from your favourite games.
                </SectionDescription>
                <div class="-mx-2 mt-4 flex flex-wrap">
                    <div
                        v-for="game in displayFollowingGames"
                        :key="game.identifier"
                        class="w-full p-2 sm:w-1/2 md:w-1/2">
                        <GameCardHorizontal
                            class="border border-primary/10"
                            :small="true"
                            :game="game" />
                    </div>
                </div>
                <button
                    v-if="followingGames.length > displayFollowingGames.length"
                    class="text-sm transition-colors font-body text-primary hover:text-primary-dark"
                    @click="gamesLimit = null">
                    See more
                </button>
            </div>
        </div>

        <div
            v-if="recommended && recommended.length > 0"
            :class="followingUsers.length > 0 ? 'mt-24' : 'mt-12'">
            <SectionTitle font-size="text-xl">
                Discover more
            </SectionTitle>
            <SectionDescription>
                You might like these writers too!
            </SectionDescription>
            <div class="mt-12 flex flex-wrap">
                <UserCard
                    v-for="user in recommended"
                    :key="user.username"
                    :user="user"
                    class="w-full sm:w-1/2 md:w-2/12" />
            </div>
        </div>
    </div>
</template>
