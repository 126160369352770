<script setup>
import draggable from 'vuedraggable'
import InputTagCheckbox from '@/Components/Input/InputTagCheckbox.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import ArticleCreateButton from '@/Components/Article/ArticleCreateButton.vue'
import { ref, watch } from 'vue'
import { XMarkIcon, Bars2Icon } from '@heroicons/vue/24/outline'

const props = defineProps({
    modelValue: Array,
    availableTags: Array,
    limit: Number
})

const profileTags = ref(props.modelValue)
const emit = defineEmits(['update:modelValue'])

function getTagNameFromId (id) {
    if (!props.availableTags) return

    const tag = props.availableTags.filter(tag => tag.id === id)[0]
    return tag.name
}

function removeTagFromSelected (id) {
    profileTags.value = profileTags.value.filter(function (tag) {
        return tag !== id
    })
}

watch(profileTags, async (newValue, oldValue) => {
    emit('update:modelValue', newValue)
})
</script>

<template>
    <div>
        <div
            v-if="availableTags.length"
            class="mt-4 flex flex-col">
            <draggable
                v-model="profileTags"
                group="tags"
                class="divide-y divide-slate-900/10"
                ghost-class="opacity-50"
                handle=".handle"
                item-key="id">
                <template #item="{element}">
                    <div class="flex items-center justify-between py-2">
                        <div class="flex items-center gap-2">
                            <Bars2Icon
                                v-if="profileTags.length > 1"
                                class="w-4 cursor-ns-resize handle" />
                            <span class="text-sm">
                                {{ getTagNameFromId(element) }}
                            </span>
                        </div>
                        <div>
                            <button
                                type="button"
                                @click="removeTagFromSelected(element)">
                                <XMarkIcon class="w-4" />
                            </button>
                        </div>
                    </div>
                </template>
            </draggable>
        </div>

        <div class="mt-8 flex flex-wrap gap-2">
            <SectionDescription
                v-if="!availableTags || !availableTags.length"
                class="mt-2 w-full text-center">
                Looks like you don't have any tags to show off.
                <div class="mt-auto flex justify-center pt-4">
                    <ArticleCreateButton />
                </div>
            </SectionDescription>
            <div class="flex w-full flex-wrap gap-2 overflow-auto max-h-[500px]">
                <div
                    v-for="tag in availableTags"
                    :key="tag.id">
                    <InputTagCheckbox
                        v-model="profileTags"
                        :label="tag.name"
                        :value="tag.id"
                        :limit="limit" />
                </div>

                <!--                <label-->
                <!--                    :class="[!profileTags.includes(tag.id) && profileTags.length >= limit ? 'cursor-default opacity-50' : 'cursor-pointer']"-->
                <!--                    class="flex cursor-pointer select-none rounded-md border bg-transparent px-4 py-1 font-light border-pink dark:border-slate-800 dark:bg-slate-800">-->
                <!--                    <input-->
                <!--                        v-model="profileTags"-->
                <!--                        :disabled="!profileTags.includes(tag.id) && profileTags.length >= limit"-->
                <!--                        :value="tag.id"-->
                <!--                        class="hidden"-->
                <!--                        type="checkbox">-->
                <!--                    {{ tag.name }}-->
                <!--                </label>-->
            </div>
        </div>
    </div>
</template>
