<script setup>
import Modal from '@/Components/UI/Modal.vue'
import Button from '@/Components/UI/Button.vue'
import { ref } from 'vue'

const emit = defineEmits(['confirmed'])

defineProps({
    title: {
        type: String,
        default: 'Are you sure?'
    },
    content: {
        type: String,
        default: ''
    },
    button: {
        type: String,
        default: 'I\'m sure'
    }
})

const show = ref(false)

function confirm () {
    emit('confirmed')
    show.value = false
}
</script>

<template>
    <span>
        <span @click="show = true">
            <slot />
        </span>

        <Modal
            :show="show"
            max-width="md"
            @close="show = false">
            <template #title>
                {{ title }}
            </template>

            <template #content>
                {{ content }}
            </template>

            <template #footer>
                <div class="flex w-full items-center justify-between">
                    <Button
                        type="button"
                        variant="white"
                        @click="show = false">
                        Cancel
                    </Button>

                    <Button
                        type="button"
                        @click="confirm">
                        {{ button }}
                    </Button>
                </div>
            </template>
        </Modal>
    </span>
</template>
