<script setup>
import * as UserPermission from '@/Enums/UserPermission.js'
import Spinner from '@/Components/UI/Spinner.vue'
import UserAvatar from '@/Components/User/UserAvatar.vue'
import CommentForm from '@/Components/Comment/CommentForm.vue'
import CommentSingle from '@/Components/Comment/CommentSingle.vue'
import AuthModalTrigger from '@/Components/Auth/AuthModalTrigger.vue'
import { ref, useSlots } from 'vue'
import { usePlural } from '@/Composables/usePlural.js'

import { useIsAuthed } from '@/Composables/useIsAuthed.js'
import { usePermissions } from '@/Composables/usePermissions.js'
import { useCurrentUser } from '@/Composables/useCurrentUser.js'

defineProps({
    commentable: Object,
    formData: Object,
    count: Number,
    comments: Array
})

const commentListKey = ref((Math.random() + 1).toString(36).substring(7))

const emit = defineEmits(['created', 'deleted'])
const slots = useSlots()

function loadComments () {
    emit('created')
}
</script>

<template>
    <div
        id="comments"
        :key="commentListKey + '-' + count"
        class="scroll-mt-20">
        <div
            v-if="(count > 0 && (!comments || !comments.length))"
            class="flex w-full items-center justify-center rounded-lg px-4 py-8 text-center text-sm text-slate-900 bg-background dark:bg-primary/10 dark:text-white">
            <Spinner class="mr-4 w-4 text-transparent" />
            Loading comments...
        </div>
        <div v-else>
            <div class="flex justify-between">
                <h3 class="text-2xl leading-tight text-balance font-heading md:text-3xl">
                    {{ count }} {{ usePlural('comment', count) }}
                </h3>
                <div
                    v-if="slots.actions"
                    class="flex items-center space-x-4">
                    <slot name="actions" />
                </div>
            </div>

            <AuthModalTrigger v-if="!useIsAuthed()">
                <template #trigger>
                    <div
                        class="mt-4 flex w-full items-center justify-center rounded-lg px-4 py-8 text-center text-sm text-slate-900 bg-secondary/5 dark:bg-slate-800 dark:text-white">
                        <span
                            class="mr-1 inline-block cursor-pointer text-primary hover:underline dark:text-primary-light">Login</span>
                        to
                        start a discussion
                    </div>
                </template>
            </AuthModalTrigger>

            <div
                v-if="usePermissions(UserPermission.CREATE_COMMENT)"
                class="mt-4 flex w-full items-start space-x-4 md:mt-8">
                <div class="flex aspect-square w-12">
                    <UserAvatar
                        class="w-full rounded-full ring-1 ring-slate-900/10"
                        height="48"
                        width="48"
                        :user="useCurrentUser()" />
                </div>
                <CommentForm
                    v-if="usePermissions(UserPermission.CREATE_COMMENT)"
                    :form-data="formData"
                    class="w-full flex-1"
                    @created="loadComments" />
            </div>

            <div class="mt-8 flex flex-col space-y-8">
                <CommentSingle
                    v-for="comment in comments"
                    :key="comment.id"
                    :commentable="commentable"
                    :comment="comment"
                    @created="loadComments"
                    @deleted="loadComments" />
            </div>
        </div>
    </div>
</template>
