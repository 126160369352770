<script setup>
import Button from '@/Components/UI/Button.vue'
import InputSelect from '@/Components/Input/InputSelect.vue'
import { ref, watch } from 'vue'
import { router } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { BarsArrowDownIcon, BarsArrowUpIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
    routeName: {
        type: String,
        default: 'search.index'
    },
    data: {
        type: Object,
        default () {
            return {
                q: usePageProps().q,
                type: usePageProps().type,
                direction: usePageProps().direction
            }
        }
    }
})

const options = [
    {
        title: 'Relevance',
        key: 'relevance'
    },
    {
        title: 'Title',
        key: 'name'
    },
    {
        title: 'Likes',
        key: 'likes'
    },
    {
        title: 'Age',
        key: 'release_date'
    }
]

const order = ref(options.filter(option => option.key === usePageProps().order)[0])
const direction = ref(usePageProps().direction)

watch([order, direction], function () {
    router.get(useRoute(props.routeName, {
        ...props.data,
        order: order.value?.key ? order.value.key : options[0].key,
        direction: direction.value
    }))
})

defineOptions({
    inheritAttrs: false
})
</script>

<template>
    <div class="relative flex w-full items-center gap-2">
        <InputSelect
            v-model="order"
            class="w-full"
            :searchable="false"
            :clearable="false"
            :options="options"
            display-label="title"
            placeholder="Order..." />
        <Button
            v-tooltip="`Order direction`"
            variant="white-adaptable"
            type="button"
            rounded="rounded-lg after:rounded-lg"
            @click="direction === 'desc' ? direction = 'asc' : direction = 'desc'">
            <span class="sr-only">Change order of list</span>
            <BarsArrowDownIcon
                v-if="direction === 'asc'"
                class="w-5" />
            <BarsArrowUpIcon
                v-if="direction === 'desc'"
                class="w-5" />
        </Button>
    </div>
</template>
