<script setup>
import Modal from '@/Components/UI/Modal.vue'
import Button from '@/Components/UI/Button.vue'
import useEmitter from '@/Composables/useEmitter.js'
import { onMounted, ref } from 'vue'
import { usePlural } from '@/Composables/usePlural.js'
import { useDateFormat, useTimeAgo } from '@vueuse/core'
import { usePageProps } from '@/Composables/usePageProps.ts'

const showModal = ref(false)
const updatesArray = ref([])

function formatDate (date) {
    return useDateFormat(date, 'DD MMM YYYY HH:mm', { locales: usePageProps().app.locale }).value
}

function timeagoDate (date) {
    return useTimeAgo(date).value
}

onMounted(() => {
    useEmitter.on('article-updates-modal:show', ({ updates }) => {
        updatesArray.value = updates
        showModal.value = true
    })
})
</script>

<template>
    <Modal
        :show="showModal"
        max-width="lg"
        :closeable="true"
        @close="showModal = false">
        <template #title>
            Updates
        </template>

        <template #content>
            <div v-if="updatesArray">
                This article has been updated {{ updatesArray.length - 1 }} {{ usePlural('time', updatesArray.length - 1) }} since publishing.<br>
                <ul class="mt-4 divide-y divide-slate-950/10">
                    <li
                        v-for="(update, index) in updatesArray"
                        :key="index"
                        class="flex justify-between py-4">
                        <span>{{ formatDate(update.date) }}</span> <span>{{ timeagoDate(update.date) }}</span>
                    </li>
                </ul>
            </div>
        </template>
        <template #footer>
            <div class="flex w-full items-end justify-end">
                <Button
                    type="button"
                    variant="white"
                    @click="showModal = false">
                    Okay
                </Button>
            </div>
        </template>
    </Modal>
</template>
