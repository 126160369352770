<script setup>
import Button from '@/Components/UI/Button.vue'
import { ref } from 'vue'
import { useRoute } from '@/Composables/useRoute.ts'
import { useToast } from '@/Composables/useToast.ts'
import { useMakeRequest } from '@/Composables/useMakeRequest.js'

const props = defineProps({
    user: Object
})

const clicked = ref(false)

function requestData () {
    useMakeRequest(useRoute('api.users.export.request'), 'POST').then(response => {
        clicked.value = true

        useToast('Data requested, please check your email at ' + props.user.email, 'success')
    })
}
</script>

<template>
    <div>
        <div
            v-if="clicked"
            class="mt-4 flex gap-1 rounded-md bg-green-200 text-sm text-green-900 b-8 p-2.5">
            Data requested, please check your email at <strong>{{ user.email }}</strong>
        </div>
        <div class="mt-4 flex justify-end">
            <Button
                :disabled="clicked"
                class="px-8"
                type="button"
                variant="white"
                @click="requestData()">
                {{ clicked ? 'Data requested' : 'Request data' }}
            </Button>
        </div>
    </div>
</template>
