<script setup lang="ts">
import useEmitter from '@/Composables/useEmitter.js'
import type { PropType } from 'vue'
import { useRoute } from '@/Composables/useRoute.ts'
import type { Article } from '@/Types/Models/Article'
import { usePlural } from '@/Composables/usePlural.js'
import { ArrowPathIcon } from '@heroicons/vue/20/solid'
import { useDateFormat, useTimeAgo } from '@vueuse/core'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { useMakeRequest } from '@/Composables/useMakeRequest.js'

const props = defineProps({
    article: Object as PropType<Article>,
    small: {
        type: Boolean,
        default: false
    }
})

function formatDate (date: string) {
    return useDateFormat(date, 'DD MMM YYYY HH:mm', { locales: usePageProps().app.locale }).value
}

function timeagoDate (date: string) {
    return useTimeAgo(date).value
}

async function showModal () {
    return useMakeRequest(useRoute('api.article.updates', { article: props.article }), 'GET').then(response => {
        useEmitter.emit('article-updates-modal:show', {
            updates: response.updates
        })
    })
}

function formatNumber (number: number) {
    return new Intl.NumberFormat(usePageProps().app.locale, {
        notation: 'compact'
    }).format(number)
}
</script>

<template>
    <div :class="small ? 'mt-[-2px]' : 'mt-0'">
        <div
            v-tooltip="formatDate(article.updated_at)"
            class="capitalize text-inherit"
            itemprop="datePublished">
            {{ timeagoDate(article.updated_at) }}
        </div>
        <button
            v-if="article.hasBeenRepublished"
            v-tooltip="`Published: ${formatDate(article.date)} (${timeagoDate(article.date)})`"
            class="cursor-pointer"
            @click="showModal()">
            <span class="sr-only">Updated</span>
            <ArrowPathIcon class="h-4 w-4" />
        </button>
        <span class="font-thin">&bull;</span>
        <div v-tooltip="article.views">
            {{ formatNumber(article.views) }} {{ usePlural('view', article.views) }}
        </div>
    </div>
</template>
