import FullLight from './FullLight.vue'
import FullWhite from './FullWhite.vue'
import MarkLight from './MarkLight.vue'
import MarkWhite from './MarkWhite.vue'
import FullColour from './FullColour.vue'
import MarkColour from './MarkColour.vue'

export default {
    FullColour,
    FullLight,
    FullWhite,
    MarkColour,
    MarkLight,
    MarkWhite
}
