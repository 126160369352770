export const VIEW_ADMIN_PANEL = "view admin panel"
export const VIEW_HORIZON_PANEL = "view horizon panel"
export const VIEW_PULSE_PANEL = "view pulse panel"
export const VIEW_ANALYTICS = "view analytics"
export const VIEW_DASHBOARD = "view dashboard"
export const CREATE_ARTICLE = "create article"
export const UPDATE_ANY_ARTICLE = "update any article"
export const EDIT_ANY_ARTICLE = "edit any article"
export const DELETE_ANY_ARTICLE = "delete any article"
export const UNPUBLISH_ANY_ARTICLE = "unpublish any article"
export const UNPUBLISH_OWN_ARTICLE = "unpublish own article"
export const RESTORE_ANY_ARTICLE = "restore any article"
export const UPDATE_OWN_ARTICLE = "update own article"
export const EDIT_OWN_ARTICLE = "edit own article"
export const DELETE_OWN_ARTICLE = "delete own article"
export const RESTORE_OWN_ARTICLE = "restore own article"
export const MODERATE_ARTICLE = "moderate article"
export const CREATE_COMMENT = "create comment"
export const UPDATE_ANY_COMMENT = "update any comment"
export const EDIT_ANY_COMMENT = "edit any comment"
export const DELETE_ANY_COMMENT = "delete any comment"
export const UPDATE_OWN_COMMENT = "update own comment"
export const EDIT_OWN_COMMENT = "edit own comment"
export const DELETE_OWN_COMMENT = "delete own comment"
export const DELETE_OWN_MODEL_COMMENT = "delete own model comment"
export const CREATE_USER = "create user"
export const UPDATE_ANY_USER = "update any user"
export const EDIT_ANY_USER = "edit any user"
export const DELETE_ANY_USER = "delete any user"
export const UPDATE_OWN_USER = "update own user"
export const EDIT_OWN_USER = "edit own user"
export const DELETE_OWN_USER = "delete own user"
export const FOLLOW_USER = "follow user"
export const BE_FOLLOWED = "be followed"
export const FOLLOW_TAG = "follow tag"
export const FOLLOW_GAME = "follow game"
export const LIKE_COMMENT = "like comment"
export const LIKE_ARTICLE = "like article"
export const BOOKMARK_ARTICLES = "bookmark articles"
export const LIKE_GAME = "like game"
