<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 533.93 478.36">
        <g>
            <g>
                <path
                    class="fill-pink"
                    d="m128.29,276.5l130.89,74.78-222.51,123.93c-16.44,9.16-36.67-2.74-36.67-21.56V160.54c0-16.41,8.87-31.55,23.22-39.52l99.73-55.53c13.85-7.72,30.68-7.61,44.42.25l107.34,61.33-146.07,81.34c-26.57,14.78-26.79,52.97-.36,68.08Z" />
                <path
                    fill="#FFFFFF"
                    d="m405.28,269.94l-146.1,81.34-130.89-74.78c-26.43-15.11-26.21-53.29.36-68.08l146.07-81.34,130.92,74.82c26.43,15.07,26.21,53.26-.36,68.04Z" />
                <path
                    class="fill-primary"
                    d="m533.93,24.71v293.14c0,16.41-8.87,31.51-23.22,39.48l-99.77,55.53c-13.81,7.72-30.65,7.61-44.39-.22l-107.38-61.37,146.1-81.34c26.57-14.78,26.79-52.97.36-68.04l-130.92-74.82L497.26,3.15c16.44-9.16,36.67,2.74,36.67,21.56Z" />
            </g>
        </g>
    </svg>
</template>
