<script setup>
import Button from '@/Components/UI/Button.vue'
import patternImage from '~/app/patterns/logo.svg'
import useEmitter from '@/Composables/useEmitter.js'
import NotificationIconLevelUp from '@/Components/Notification/NotificationIconLevelUp.vue'
import NotificationIconRejected from '@/Components/Notification/NotificationIconRejected.vue'
import NotificationIconBadgeEarned from '@/Components/Notification/NotificationIconBadgeEarned.vue'
import { ref } from 'vue'
import { Link } from '@inertiajs/vue3'
import { useTimeAgo } from '@vueuse/core'
import { usePost } from '@/Composables/usePost.js'
import { useRoute } from '@/Composables/useRoute.ts'
import { useToast } from '@/Composables/useToast.ts'

const props = defineProps({
    notification: Object
})

const emit = defineEmits(['read', 'unread'])

const timeAgo = useTimeAgo(props.notification.created_at)

const isUnread = ref(!props.notification.read_at)

const iconMap = {
    'App\\Notifications\\ArticleRejectedNotification': NotificationIconRejected,
    'App\\Notifications\\ArticleUnpublishedNotification': NotificationIconRejected,
    'App\\Notifications\\LevelUpNotification': NotificationIconLevelUp,
    'App\\Notifications\\NewBadgeEarnedNotification': NotificationIconBadgeEarned
}

function markAsRead (e) {
    usePost({
        url: useRoute('api.notifications.read.single', { notificationId: props.notification.id }),
        onUnauthorized: () => useToast('Unauthorized action', 'fail'),
        onSuccess: () => {
            isUnread.value = false
            emit('read')
            useEmitter.emit('notification.read')
        }
    })
}

function markAsUnread (e) {
    usePost({
        url: useRoute('api.notifications.unread.single', { notificationId: props.notification.id }),
        onUnauthorized: () => useToast('Unauthorized action', 'fail'),
        onSuccess: (response) => {
            isUnread.value = true
            emit('unread')
            useEmitter.emit('notification.unread')
        }
    })
}
</script>

<template>
    <div
        :class="[isUnread ? 'bg-white dark:bg-slate-900' : 'bg-background dark:bg-slate-800 bg-opacity-50']"
        :href="props.notification.data.url ?? '#'"
        class="relative mb-1 flex w-full items-center rounded-lg p-4 transition-colors">
        <div class="relative flex w-full flex-wrap items-center gap-4 md:flex-nowrap">
            <div class="relative flex w-full items-center gap-4 md:w-10/12">
                <div
                    class="relative flex aspect-square w-16 items-center justify-center overflow-hidden rounded-md min-w-16 bg-slate-950 dark:bg-tertiary">
                    <img
                        v-if="notification.data.image"
                        v-lazy="{src: notification.data.image, error: patternImage}"
                        :alt="`Thumbnail image for single notification: ${notification.id}`"
                        class="h-full object-cover">
                    <component
                        :is="iconMap[notification.type]"
                        v-else />
                </div>
                <div class="flex flex-col text-sm sm:text-base">
                    <span v-html="notification.data.htmlContent" />
                    <div class="top-0 right-0 justify-end text-xs text-gray-400">
                        {{ timeAgo }}
                    </div>
                </div>
                <Link
                    v-if="notification.data.url"
                    :href="notification.data.url"
                    class="absolute -top-4 -left-4 w-[calc(100%+2rem)] h-[calc(100%+2rem)]">
                    <span class="sr-only">Link to notification: {{ notification.data.content }}</span>
                </Link>
            </div>
            <div class="flex w-full justify-end md:w-2/12">
                <Button
                    v-if="isUnread"
                    variant="primary-light"
                    class="w-full md:w-auto"
                    @click="markAsRead">
                    Mark as read
                </Button>
                <Button
                    v-if="!isUnread"
                    variant="primary-light"
                    class="w-full md:w-auto"
                    @click="markAsUnread">
                    Mark as unread
                </Button>
            </div>
        </div>
    </div>
</template>
