import { usePost } from '@/Composables/usePost.js'
import { usePageProps } from '@/Composables/usePageProps.js'
import { Ziggy } from '@/routes'
import { useIsAuthed } from '@/Composables/useIsAuthed'

class PushNotificationService {
    private urlBase64ToUint8Array (base64String: string): Uint8Array {
        const padding = '='.repeat((4 - base64String.length % 4) % 4)
        const base64 = (base64String + padding)
            .replace(/-/g, '+')
            .replace(/_/g, '/')

        const rawData = window.atob(base64)
        const outputArray = new Uint8Array(rawData.length)

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i)
        }
        return outputArray
    }

    private async checkIfAlreadySubscribed (): Promise<boolean> {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready
        const subscription = await serviceWorkerRegistration.pushManager.getSubscription()
        return subscription !== null
    }

    public async requestPermission (): Promise<boolean> {
        if (!('PushManager' in window)) {
            console.log('Push API is not supported')
            return false
        }

        if ('Notification' in window && Notification.permission === 'granted') {
            return true
        }

        if ('Notification' in window && Notification.permission !== 'granted') {
            const permission = await Notification.requestPermission()
            console.log('Permission:', permission)
            return permission === 'granted'
        }

        return false
    }

    public async subscribeToPushNotifications (): Promise<void> {
        if (useIsAuthed() && usePageProps().app.pwaMode === true) {
            if (await this.checkIfAlreadySubscribed()) {
                return
            }

            if (await this.requestPermission()) {
                const registration = await navigator.serviceWorker.ready
                const pushSubscription = await registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: this.urlBase64ToUint8Array(usePageProps().config.notifications.vapid_public_key)
                })

                const data = {
                    endpoint: pushSubscription.endpoint,
                    p256dh: btoa(String.fromCharCode.apply(null, new Uint8Array(pushSubscription.getKey('p256dh')))),
                    auth: btoa(String.fromCharCode.apply(null, new Uint8Array(pushSubscription.getKey('auth'))))
                }

                usePost({
                    url: usePageProps().app.url + '/' + Ziggy.routes['api.notifications.subscribe'].uri,
                    data
                })

                console.log('Subscribed to push notifications')
            } else {
                console.log('Push notifications permission denied')
            }
        }
    }
}

export default PushNotificationService
