<template>
    <transition
        enter-active-class="origin-top-right transition duration-200 ease-out"
        enter-from-class="scale-95 transform opacity-0"
        enter-to-class="scale-100 transform opacity-100"
        leave-active-class="origin-top-right transition duration-75 ease-in"
        leave-from-class="scale-100 transform opacity-100"
        leave-to-class="scale-95 transform opacity-0">
        <slot />
    </transition>
</template>
