<script setup>
import { ref } from 'vue'
import { Link } from '@inertiajs/vue3'
import { useUrlSearchParams } from '@vueuse/core'
import { XMarkIcon } from '@heroicons/vue/20/solid'
import { useRoute } from '@/Composables/useRoute.ts'
import { useIsAuthed } from '@/Composables/useIsAuthed.js'

defineProps({
    title: {
        type: String,
        required: true
    },
    content: {
        type: String,
        required: true
    }
})

const show = ref(false)

const params = useUrlSearchParams()
if (params.register === 'success' && useIsAuthed()) {
    show.value = true
}
</script>

<template>
    <div
        v-if="show"
        class="pointer-events-none w-full">
        <div
            class="pointer-events-auto flex items-center justify-between gap-x-6 bg-slate-900 px-6 py-2.5 dark:bg-slate-800 sm:pr-3.5 sm:py-3 sm:pl-4">
            <p class="text-sm leading-6 text-white">
                <Link :href="useRoute('dashboard.home')">
                    <strong class="font-semibold">
                        {{ title }}
                    </strong>
                    <svg
                        viewBox="0 0 2 2"
                        class="mx-2 inline fill-current h-0.5 w-0.5"
                        aria-hidden="true">
                        <circle
                            cx="1"
                            cy="1"
                            r="1" />
                    </svg>
                    <span v-html="content" />
                    &nbsp;<span aria-hidden="true">&rarr;</span>
                </Link>
            </p>
            <Link
                :href="useRoute('home')"
                type="button"
                class="-m-3 flex-none p-3 focus-visible:outline-offset-[-4px]">
                <span class="sr-only">Dismiss</span>
                <XMarkIcon
                    class="h-5 w-5 text-white"
                    aria-hidden="true" />
            </Link>
        </div>
    </div>
</template>
